import { useCallback, useContext, useMemo } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Deal } from '../../../../gql/dealGql';
import { isGapSelected, isVscSelected } from '../../../../gql/financialInfoGql';

import Tooltip from '../../../shared/Tooltip';
import RequiredDocModal from '../RequiredDocModal';

import { ChryslerCapitalSlug, HondaFinancialSlug } from '../../../../constants/lienholders';
import { PermissionEnum } from '../../../../constants/permissions';
import ROUTES from '../../../../constants/routes';
import { Can } from '../../../../libs/Can';
import { DealContext } from '../../../../libs/DealContext';
import { AbilityContext } from '../../../../libs/contextLib';
import { docStates } from '../../../EstimateForm/payoffDocStates';
import PaperworkTypeModal from '../../../PaperworkOptions/PaperworkTypeModal';
import PayoffReminderModal from '../../../PayoffReminderModal/PayoffReminderModal';

const CloseDealButton = () => {
  const { documentMedia, isRecalculatingPayoff, isPayoffRequested } = useContext(DealContext);
  const { values, errors, isSubmitting } = useFormikContext<Deal>();
  const abilities = useContext(AbilityContext);

  const history = useHistory();
  const { unverifiedRequiredMediaList, missingRequiredMediaList } = documentMedia;

  const requiredDocModal = useDisclosure();
  const reminderModal = useDisclosure();
  const paperworkModal = useDisclosure();

  const tooltipErrors = useMemo(() => {
    const newTooltipErrors = [];

    if (!values.financial_info.option_type) {
      newTooltipErrors.push('Select a Package');
    }

    if (isPayoffRequested) {
      newTooltipErrors.push('Cannot have an active payoff request');
    }

    if (isVscSelected(values.financial_info.option_type)) {
      if (
        (!values.financial_info.pen_vsc_form_id ||
          !values.financial_info.pen_vsc_rate_id ||
          !values.financial_info.pen_vsc_session_id) &&
        !abilities.has(PermissionEnum.SuperUser)
      ) {
        newTooltipErrors.push('Calculate VSC Rates');
      }
      if (errors.financial_info?.vsc_price) {
        newTooltipErrors.push(errors.financial_info?.vsc_price);
      }
      if (errors.financial_info?.vsc_cost) {
        newTooltipErrors.push(errors.financial_info?.vsc_cost);
      }
    }

    if (isGapSelected(values.financial_info.option_type)) {
      if (
        (!values.financial_info.pen_gap_form_id ||
          !values.financial_info.pen_gap_rate_id ||
          !values.financial_info.pen_gap_session_id) &&
        !abilities.has(PermissionEnum.SuperUser)
      ) {
        newTooltipErrors.push('Calculate GAP Rate');
      }
      if (errors.financial_info?.gap_price) {
        newTooltipErrors.push(errors.financial_info?.gap_price);
      }
      if (errors.financial_info?.gap_cost) {
        newTooltipErrors.push(errors.financial_info?.gap_cost);
      }
    }

    if (errors.financial_info?.buy_rate) {
      newTooltipErrors.push(errors.financial_info?.buy_rate);
    }

    if (errors.financial_info?.sell_rate) {
      newTooltipErrors.push(errors.financial_info?.sell_rate);
    }

    return newTooltipErrors;
  }, [values.financial_info, errors, isPayoffRequested]);

  const isDisabled = tooltipErrors.length > 0;

  const handleCloseDeal = useCallback(async () => {
    history.push(ROUTES.DASHBOARD);
  }, [history.push]);

  const shouldShowRequiredDocModal =
    unverifiedRequiredMediaList.length > 0 || missingRequiredMediaList.length > 0;

  const shouldShowPayoffReminderModal =
    values.car.payoff.lienholder_slug === ChryslerCapitalSlug ||
    (values.car.payoff.lienholder_slug === HondaFinancialSlug &&
      docStates.includes(values.customer.address?.state));

  const prepareToCloseDeal = useCallback(() => {
    if (shouldShowRequiredDocModal) {
      requiredDocModal.onOpen();
      return;
    }

    if (shouldShowPayoffReminderModal) {
      reminderModal.onOpen();
      return;
    }

    paperworkModal.onOpen();
  }, [shouldShowRequiredDocModal, shouldShowPayoffReminderModal]);

  return (
    <Can I={PermissionEnum.FinalizePayment}>
      <Tooltip errors={tooltipErrors}>
        <Button
          isLoading={isSubmitting}
          loadingText="Close Deal"
          isDisabled={isDisabled || isRecalculatingPayoff}
          onClick={prepareToCloseDeal}
        >
          Close Deal
        </Button>
      </Tooltip>
      <RequiredDocModal
        {...requiredDocModal}
        missingRequiredMediaList={missingRequiredMediaList}
        onSuccessButtonLabel="Close Deal"
        onSuccess={() => {
          requiredDocModal.onClose();
          // We don't want to open the req doc modal again
          if (shouldShowPayoffReminderModal) {
            reminderModal.onOpen();
          } else {
            paperworkModal.onOpen();
          }
        }}
        unverifiedRequiredMediaList={unverifiedRequiredMediaList}
      />
      <PayoffReminderModal {...reminderModal} onSuccess={() => paperworkModal.onOpen()} />
      <PaperworkTypeModal {...paperworkModal} onCloseDeal={handleCloseDeal} />
    </Can>
  );
};

export default CloseDealButton;
