import { useContext, useState } from 'react';

import { Box, Button, Radio, RadioGroup, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { BiCopyAlt } from 'react-icons/bi';
import { toast } from 'react-toastify';

import { DuplicateTypeEnum, useDuplicateDealMutation } from '../../gql/generated/graphql';

import Modal from '../../components/shared/Modal';

import { DealContext } from '../../libs/DealContext';

const DuplicateDealModalButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [duplicationType, setDuplicationType] = useState<DuplicateTypeEnum>();

  const { deal } = useContext(DealContext);

  const [duplicateDeal, { loading }] = useDuplicateDealMutation();

  const handleDuplicate = async () => {
    if (!deal?.id) {
      toast.error('Deal not found');
      return;
    }
    if (!duplicationType) {
      toast.error('Please select a duplication type');
      return;
    }

    try {
      const { data } = await duplicateDeal({
        variables: {
          dealId: deal.id,
          duplicationType,
        },
      });

      if (data?.duplicateDeal?.id) {
        // Open the new deal in a new tab.
        window.open(`/deals/${data.duplicateDeal.id}`, '_blank');
        toast.success('Deal duplicated successfully');
        onClose();
      }
    } catch (error) {
      toast.error('Error duplicating deal');
    }
  };

  return (
    <>
      <Button variant="secondary" size="lg" p={0} onClick={onOpen}>
        <BiCopyAlt size="25px" />
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        canDismiss={!loading}
        title="Create Duplicate"
        size="md"
        leftButtons={
          <Button variant="warning" onClick={onClose} isDisabled={loading}>
            CANCEL
          </Button>
        }
        rightButtons={
          <Button
            colorScheme="secondary"
            onClick={handleDuplicate}
            isLoading={loading}
            isDisabled={!duplicationType}
            loadingText="CREATE DUPLICATE"
          >
            CREATE DUPLICATE
          </Button>
        }
      >
        <Box>
          <Text mb={4}>
            Please select below what information you would like to copy to the duplicated deal.
          </Text>
          <RadioGroup
            onChange={(value: DuplicateTypeEnum) => {
              setDuplicationType(value);
            }}
            value={duplicationType}
          >
            <Stack spacing={4}>
              <Radio value={DuplicateTypeEnum.Entire}>
                <Text>Duplicate entire deal</Text>
              </Radio>
              <Radio value={DuplicateTypeEnum.CustomerOnly}>
                <Text>Duplicate customer info only</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
      </Modal>
    </>
  );
};

export default DuplicateDealModalButton;
