import { Box, Button, Text } from '@chakra-ui/react';
import { MdWarning } from 'react-icons/md';

import RequestPayoffButton from '../EstimateForm/RequestPayoffButton';
import Modal from '../shared/Modal';

import { ColorsEnum } from '../../libs/colorLib';
import { formatMoney } from '../../libs/utils';

interface PayoffWarningModalProps {
  onMoveDeal: () => void;
  isOpen: boolean;
  onClose: () => void;
  payoffValue?: number;
}

const PayoffWarningModal = ({
  isOpen,
  onClose,
  onMoveDeal,
  payoffValue,
}: PayoffWarningModalProps) => {
  return (
    <Modal
      title="Verify Payoff"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      rightButtons={
        <>
          <Button variant="secondary" onClick={onMoveDeal}>
            MOVE DEAL
          </Button>
          <RequestPayoffButton onClick={onClose} />
        </>
      }
    >
      <Box alignItems="center" display="grid" gridTemplateColumns="48px 1fr" gap={3}>
        <MdWarning color={ColorsEnum.YELLOW} size={48} />
        <Text display="inline" fontWeight="normal">
          The payoff value{' '}
          {payoffValue && (
            <>
              of{' '}
              <Text display="inline" fontWeight="bold">
                {formatMoney(payoffValue)}{' '}
              </Text>
            </>
          )}
          has not been verified. Would you like to send a request to the payoff team?
        </Text>
      </Box>
    </Modal>
  );
};

export default PayoffWarningModal;
