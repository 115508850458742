import { boolean, object } from 'yup';

import { fullAddressValidation, previousAddressValidation } from '../../utils/validation/address';
import { contactPersonValidation } from '../../utils/validation/contact';
import { employmentValidation, prevEmploymentValidation } from '../../utils/validation/employment';
import { originalLesseeOptionalValidation } from '../../utils/validation/originalLessee';
import { personInfoValidation } from '../../utils/validation/personInfo';

const CreditPersonValidation = (person?: string) =>
  object().shape({
    ...personInfoValidation(person),
    address: fullAddressValidation(person, true),
    prev_address: previousAddressValidation(person),
    employment: employmentValidation(person),
    prev_employment: prevEmploymentValidation(person),
  });

export const validationSchema = object().shape({
  addCobuyer: boolean(),
  addContact: boolean(),
  addSecondContact: boolean(),
  addOriginalLessee: boolean(),
  customer: CreditPersonValidation(),
  cobuyer: object()
    .nullable()
    .when('addCobuyer', {
      is: true,
      then: CreditPersonValidation('Cobuyer '),
    }),
  contact: object()
    .nullable()
    .when('addContact', { is: true, then: contactPersonValidation('Contact ') }),
  second_contact: object()
    .nullable()
    .when('addSecondContact', {
      is: true,
      then: contactPersonValidation('Second Contact '),
    }),
  original_lessee: object().nullable().when('addOriginalLessee', {
    is: true,
    then: originalLesseeOptionalValidation, // Don't want to block the rep from submitting the app
  }),
  financial_info: object()
    .shape({
      buyer_not_lessee: boolean().optional().nullable(),
    })
    .optional()
    .nullable(),
});
