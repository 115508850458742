import { HStack, Image, Text } from '@chakra-ui/react';

const ExperianLogo = () => (
  <HStack wrap="nowrap">
    <Image src="/experian-logo.png" alt="Experian" height="60px" />
    <Text fontWeight="bold" fontSize="25px">
      Vantage 3.0
    </Text>
  </HStack>
);

export default ExperianLogo;
