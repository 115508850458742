import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Deal } from '../gql/dealGql';
import { TemporaryData } from '../gql/generated/graphql';
import { lienholderQuery } from '../gql/prs/lienholderGql';
import { LienholderPRSFormType, LienholderPRSType } from '../gql/prs/types';

import {
  getLienholderPhoneNumbers,
  getLienholderQueryVariables,
  getPayoffMethods,
} from '../utils/prs';

interface Props {
  data: Deal | TemporaryData;
  skip?: boolean;
}

export const useLienholder = ({ data, skip }: Props) => {
  const { state, slug } = getLienholderQueryVariables(data);

  const [lienholder, setLienholder] = useState<LienholderPRSType>();
  const [lienholderForm, setLienholderForm] = useState<LienholderPRSFormType>();

  const [doubleTax, setDoubleTax] = useState(false);
  const [directPayState, setDirectPayState] = useState(false);
  const [residualValue, setResidualValue] = useState(false);

  const [getLienholderInfo, { loading }] = useLazyQuery<{ lienholder: LienholderPRSType }>(
    lienholderQuery,
    {
      fetchPolicy: 'cache-first',
      onCompleted: ({ lienholder: fetchedLienholder }) => {
        setLienholder(fetchedLienholder);

        const phone_numbers = getLienholderPhoneNumbers(fetchedLienholder.phone_numbers);
        const payoff_methods = getPayoffMethods(
          fetchedLienholder.requirements_to_get_payoff.get_payoff_methods,
        );
        const payoff_requirements = fetchedLienholder.requirements_to_get_payoff.options;
        setLienholderForm({ phone_numbers, payoff_methods, payoff_requirements });

        setDoubleTax(fetchedLienholder.double_tax);
        setDirectPayState(fetchedLienholder.requirements_to_payoff_lease?.direct_pay?.value);
        setResidualValue(fetchedLienholder.requirements_to_payoff_lease?.residual_value?.value);
      },
    },
  );

  useEffect(() => {
    if (skip) {
      return;
    }

    if (!state || !slug) {
      setLienholder(undefined);
      setLienholderForm(undefined);

      setDoubleTax(false);
      setDirectPayState(false);

      return;
    }

    getLienholderInfo({
      variables: {
        state,
        slug,
      },
    });
  }, [skip, state, slug]);

  return {
    lienholder,
    lienholderForm,
    doubleTax,
    directPayState,
    residualValue,
    loading,
  };
};
