import { ChangeEventHandler, useMemo } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Customer } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';
import { EmploymentStatusDisplay, getPayFrequencyOptions } from '../../../gql/employmentGql';
import { EmploymentStatusType } from '../../../gql/generated/graphql';

import CardSubHeaderV2 from '../../shared/Card/components/CardSubHeaderV2';
import { GridFormColumn, GridFormRow } from '../../shared/GridForm';
import Input from '../../shared/Input';
import NumberInput from '../../shared/NumberInput';
import Select from '../../shared/Select';

import { phoneNumberMask } from '../../../constants/masks';
import { JOB_TITLE_MAX_LENGTH } from '../../../utils/validation/employment';

interface EmploymentFormProps extends BoxProps {
  name: keyof Pick<Deal, 'customer' | 'cobuyer'>;
  objectName: keyof Pick<Customer, 'employment' | 'prev_employment'>;
  isUneditable: boolean;
  isEmploymentInfoRequired: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

export const EmploymentForm = ({
  name,
  objectName,
  isUneditable,
  isEmploymentInfoRequired,
  customHandleChange,
  ...rest
}: EmploymentFormProps) => {
  const { handleChange: formikHandleChange } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const isPrevEmployment = objectName === 'prev_employment';

  const payFrequencyOptions = useMemo(() => getPayFrequencyOptions(isUneditable), [isUneditable]);

  return (
    <Box mb={5} {...rest}>
      <CardSubHeaderV2 title={`${isPrevEmployment ? 'Previous ' : ''}Employment Information`} />
      <GridFormColumn>
        <GridFormRow minChildWidth={180}>
          {!isPrevEmployment ? (
            <Select
              label="Employment Status"
              emptyOption={false}
              name={`${name}.${objectName}.status`}
              onChange={handleChange}
              isDisabled={isUneditable}
              options={Object.values(EmploymentStatusType).map((status: EmploymentStatusType) => ({
                label: EmploymentStatusDisplay[status as EmploymentStatusType],
                value: status,
              }))}
            />
          ) : null}
          <Input
            label="Employer"
            name={`${name}.${objectName}.name`}
            onChange={handleChange}
            isDisabled={isUneditable || !isEmploymentInfoRequired}
          />
          <Input
            label="Job Title"
            name={`${name}.${objectName}.job_title`}
            onChange={handleChange}
            isDisabled={isUneditable || !isEmploymentInfoRequired}
            maxLength={JOB_TITLE_MAX_LENGTH}
          />
          {isPrevEmployment ? <Box /> : null}
        </GridFormRow>

        <GridFormRow minChildWidth={135}>
          {!isPrevEmployment ? (
            <Input
              label="Employer Phone"
              name={`${name}.${objectName}.phone_number`}
              onChange={handleChange}
              mask={phoneNumberMask}
              isDisabled={isUneditable || !isEmploymentInfoRequired}
            />
          ) : null}
          <NumberInput
            label={isEmploymentInfoRequired ? 'Years at Job' : 'How Many Years'}
            name={`${name}.${objectName}.years_at_job`}
            additionalHandleChange={handleChange}
            isDisabled={isUneditable}
          />
          <NumberInput
            label={isEmploymentInfoRequired ? 'Months at Job' : 'How Many Months'}
            name={`${name}.${objectName}.months_at_job`}
            additionalHandleChange={handleChange}
            isDisabled={isUneditable}
          />
          {isPrevEmployment ? <Box /> : null}
          {!isPrevEmployment ? (
            <>
              <NumberInput
                label="Gross Income"
                name={`${name}.${objectName}.gross_income`}
                showThousandSeparator
                isMoney
                isWhole
                additionalHandleChange={handleChange}
                isDisabled={isUneditable}
              />
              <Select
                label="Payment Timeframe"
                emptyOption
                name={`${name}.${objectName}.pay_frequency`}
                onChange={handleChange}
                isDisabled={isUneditable}
                options={payFrequencyOptions}
              />
            </>
          ) : null}
          {isPrevEmployment ? <Box /> : null}
          {isPrevEmployment ? <Box /> : null}
        </GridFormRow>
      </GridFormColumn>
    </Box>
  );
};
