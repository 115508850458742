import { useMemo } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';

import chakraButton from '../../chakra/components/button';
import SimpleNotification from '../NotificationBadge/SimpleNotification';
import DealSuggest from '../Suggest/DealSuggest';
import { UserStatusSwitcher } from '../UserStatusSwitcher/UserStatusSwitcher';

import ActiveMenuItem from './ActiveMenuItem';
import LeaseEndLogo from './LeaseEndLogo';
import UserMenu from './UserMenu';
import { isActive } from './utils';

import { NavBarProps } from '.';
import ROUTES from '../../constants/routes';

const DesktopNavbar = ({
  buttons,
  currentPage,
  currentView,
  defaultView,
  handleNavBarButtonClick,
  isAuthenticated,
}: NavBarProps) => {
  const numberOfButtonsToShowInNavBar = useBreakpointValue({
    base: 0,
    lg: 1,
    xl: 3,
    '2xl': 4,
    '3xl': 5,
    '4xl': 6,
  });

  // eslint-disable-next-line no-underscore-dangle
  const activeNavBarButtonVariant = chakraButton.variants.navBar._active;

  const { navBarButtons, menuButtons } = useMemo(() => {
    const possibleNavBarButtons = buttons.filter(({ menuOnly }) => !menuOnly);

    const onlyMenuButtons = buttons.filter(({ menuOnly }) => menuOnly);

    return {
      navBarButtons: possibleNavBarButtons.slice(0, numberOfButtonsToShowInNavBar),
      menuButtons: [
        ...possibleNavBarButtons.slice(numberOfButtonsToShowInNavBar),
        ...onlyMenuButtons,
      ],
    };
  }, [buttons, numberOfButtonsToShowInNavBar]);

  return (
    <Stack
      direction="row"
      py={2}
      px={4}
      justifyContent="space-between"
      wrap={{ base: 'wrap', md: 'nowrap' }}
    >
      <Stack direction="row" alignItems="center">
        <LeaseEndLogo
          onClick={(e) => handleNavBarButtonClick(e, { page: ROUTES.DASHBOARD, view: defaultView })}
        />

        {isAuthenticated && (
          <HStack>
            {navBarButtons.map((button) => (
              <Button
                key={button.label}
                variant="navBar"
                size="md"
                isActive={isActive({ button, currentPage, currentView })}
                onClick={(e) => handleNavBarButtonClick(e, button)}
                onAuxClick={(e) => handleNavBarButtonClick(e, button)}
              >
                {button.label}
                <SimpleNotification show={button.hasTextNotifications} />
              </Button>
            ))}

            {menuButtons.length && (
              <Menu>
                <MenuButton
                  as={Button}
                  variant="navBar"
                  size="md"
                  rightIcon={<ChevronDownIcon boxSize="2em" />}
                  {...(menuButtons.some((button) => isActive({ button, currentPage, currentView }))
                    ? activeNavBarButtonVariant
                    : {})}
                >
                  More
                </MenuButton>

                <MenuList zIndex="dropdown" fontSize="md">
                  {menuButtons.map((button) => (
                    <ActiveMenuItem
                      key={button.label}
                      isActive={isActive({ button, currentPage, currentView })}
                      onClick={(e) => handleNavBarButtonClick(e, button)}
                      justifyContent="space-between"
                    >
                      {button.label}
                      <SimpleNotification show={button.hasTextNotifications} />
                    </ActiveMenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </HStack>
        )}
      </Stack>

      <Stack direction="row">
        {isAuthenticated ? <DealSuggest /> : null}

        <HStack order={3} mt="0 !important">
          <UserStatusSwitcher isAuthenticated={isAuthenticated} />
          <UserMenu />
        </HStack>
      </Stack>
    </Stack>
  );
};

export default DesktopNavbar;
