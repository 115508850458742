import { useState } from 'react';

import { Box, BoxProps, Icon, Image, ResponsiveValue, Text, VStack } from '@chakra-ui/react';
import { VscFilePdf } from 'react-icons/vsc';

import { CustomerContactTypeEnum } from '../../../gql/customerGql';

import TextBubbleWithTimestamp from '../../shared/TextBubbleWithTimestamp';
import { ChatMessage } from '../TextModal';

import { getTimestamp } from '../../../utils/dates';

export type ActiveConversation = {
  role: CustomerContactTypeEnum | '';
  first_name: string;
  last_name: string;
  phone_number: string;
};

type MessageProps = {
  message: ChatMessage;
  isLastInGroup: boolean;
};

const MessageBubble = ({ message, isLastInGroup }: MessageProps) => {
  const {
    data: { mediaListObj, text: textMessage },
    date: messageDate,
    author,
    fromCustomer: isFromCustomer,
  } = message;
  const [showDate, setShowDate] = useState(false);

  const mediaMessageStyle: ResponsiveValue<BoxProps> = {
    bgColor: isFromCustomer ? 'gray.200' : 'azureishWhite',
    borderRadius: isFromCustomer ? '10px 10px 10px 0px' : '10px 10px 0px 10px',
    wordBreak: 'break-word',
    justifyContent: isFromCustomer ? 'left' : 'right',
    padding: 2,
  };

  const mediaListLength = mediaListObj?.data?.length ?? 0;
  return (
    <Box>
      <Box
        display="flex"
        onClick={() => setShowDate((prev) => !prev)}
        justifyContent={mediaMessageStyle.justifyContent}
      >
        <VStack maxW="75%" display="grid">
          {mediaListLength > 0 &&
            mediaListObj?.data?.map((ml) => {
              if (!ml.url) {
                return null;
              }

              const isPdf = ml.contentType === 'application/pdf';

              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={isFromCustomer ? 'flex-start' : 'flex-end'}
                >
                  {/* Media Content */}
                  <Box {...mediaMessageStyle} width="fit-content">
                    {isPdf ? (
                      <a href={ml.url} target="_blank" rel="noreferrer">
                        <Icon as={VscFilePdf} boxSize={12} />
                      </a>
                    ) : (
                      <Box minH="150px">
                        <Image src={ml.url} maxH="400px" />
                      </Box>
                    )}
                  </Box>
                  {/* timestamp */}
                  {showDate && (
                    <Text fontSize="xs" color="gray.500">
                      {getTimestamp(messageDate)}
                    </Text>
                  )}
                </Box>
              );
            })}
          {textMessage?.length && (
            <TextBubbleWithTimestamp
              timestamp={messageDate}
              message={textMessage}
              isFromCustomer={isFromCustomer}
              isLastInGroup={isLastInGroup}
              author={author}
            />
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default MessageBubble;
