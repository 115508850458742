import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MdFilterList } from 'react-icons/md';
import { VscTriangleUp } from 'react-icons/vsc';
import { useHistory, useLocation } from 'react-router-dom';

import { DealSourceEnum, dealSourceOptions, dealTypeOptions } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import CustomReactSelect from '../shared/CustomReactSelect';
import UserAutocomplete from '../shared/UserAutocomplete';
import { Option } from '../shared/types';

import MenuFilterContainer from './MenuFilterContainer';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import SELECT_OPTIONS from '../../constants/selectOptions';
import { FiltersActionKind, FiltersStates } from '../../globalFiltersUtils';
import useClickOutsideAction from '../../hooks/useClickOutsideAction';
import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { Can } from '../../libs/Can';
import { FiltersContext } from '../../libs/contextLib';
import { CurrentView } from '../../pages/Dashboard';
import { User } from '../../types/user';
import { objectsHaveSameValues } from '../../utils/objects';

const ALL_LOCATION_VALUE = 'all';
const ALL_LOCATION_OPTION = { value: ALL_LOCATION_VALUE, label: 'All' };

const GlobalFiltersMenu = ({ isMobile = false }: { isMobile?: boolean }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { viewAsUserSources, viewAsUserTypes, loggedUserSalesPods, loggedUserSources } =
    useUserAbilityAndVisibleStates();
  const {
    filters: { global },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  // Temporary states for filters.
  const [tempFilters, setTempFilters] = useState<FiltersStates>(global);

  const updateTempFilter = <K extends keyof FiltersStates>(key: K, value: FiltersStates[K]) => {
    setTempFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (!objectsHaveSameValues(tempFilters, global)) {
      setTempFilters(global);
    }
  }, [global]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const ALL_PODS_OPTION: Option = {
    value: '',
    label: 'All',
  };

  const availableSourceOptions = useMemo(
    () =>
      dealSourceOptions.filter((option) =>
        viewAsUserSources.includes(option.value as DealSourceEnum),
      ),
    [dealSourceOptions, viewAsUserSources],
  );

  const availableTypeOptions = useMemo(
    () => dealTypeOptions.filter((option) => viewAsUserTypes.includes(option.value as DealType)),
    [dealTypeOptions, viewAsUserTypes],
  );

  const appliedFiltersCount = useMemo(
    () =>
      (tempFilters.selectedPodId ? 1 : 0) +
      (tempFilters.sources.length !== availableSourceOptions.length ? 1 : 0) +
      (tempFilters.types.length !== availableTypeOptions.length ? 1 : 0) +
      (tempFilters.viewAsUserId ? 1 : 0) +
      (tempFilters.showOnlyUnread ? 1 : 0) +
      (tempFilters.showAssignedToMe ? 1 : 0) +
      (tempFilters.showBootRequested ? 1 : 0) +
      (tempFilters.showAssignedToDeletedUsers ? 1 : 0) +
      (tempFilters.showAssignedToMe ? 1 : 0) +
      (tempFilters.showFollowUps ? 1 : 0) +
      (tempFilters.showActiveTags ? 1 : 0) +
      (tempFilters.showMatureFollowUps ? 1 : 0) +
      (tempFilters.location ? 1 : 0),
    [tempFilters, availableSourceOptions.length, availableTypeOptions.length],
  );

  const salesPodsOptions = useMemo(
    () => [
      ALL_PODS_OPTION,
      ...loggedUserSalesPods
        .map((pod) => ({
          value: pod?.id?.toString() ?? '',
          label: pod?.name ?? '',
          color: pod?.color ?? '',
        }))
        .sort((a, b) => {
          if (a.label.includes('(ARCHIVED)')) {
            return 1;
          }
          if (b.label.includes('(ARCHIVED)')) {
            return -1;
          }
          return 0;
        }),
    ],
    [loggedUserSalesPods],
  );
  const selectedSalesPodOption = useMemo(
    () =>
      salesPodsOptions.find((option) => option.value === tempFilters.selectedPodId?.toString()) ??
      ALL_PODS_OPTION,
    [salesPodsOptions, tempFilters.selectedPodId],
  );

  const selectedLocationOption = useMemo(
    () =>
      SELECT_OPTIONS.LOCATIONS.find((option) => option.value === tempFilters.location) ??
      ALL_LOCATION_OPTION,
    [tempFilters.location],
  );

  useEffect(() => {
    // `viewAsUserId` only works on the dashboard view.
    if (pathname !== ROUTES.DASHBOARD || tempFilters.currentView !== CurrentView.DashboardView) {
      dispatchFilters({
        type: FiltersActionKind.SET_VIEW_AS_USER_ID,
        payload: undefined,
      });
    }
  }, [pathname, tempFilters.currentView]);

  const handleApply = () => {
    dispatchFilters({
      type: FiltersActionKind.SET_GLOBAL_FILTERS,
      payload: tempFilters,
    });

    onClose();
  };

  const handleReset = () => {
    // The `tempFilters` are synced with the global filters through the `useEffect`.
    dispatchFilters({
      type: FiltersActionKind.CLEAR_GLOBAL_FILTERS,
      payload: {
        allowedDealSources: loggedUserSources,
        types: Object.values(DealType),
      },
    });
  };

  const menuContainerRef = useRef<HTMLDivElement>(null);
  useClickOutsideAction(menuContainerRef, onClose, true);

  return (
    <Box ref={menuContainerRef}>
      <Menu isOpen={isOpen} onOpen={onOpen} isLazy lazyBehavior="unmount">
        {isMobile ? (
          <MenuButton
            as={IconButton}
            icon={<MdFilterList size="20px" />}
            variant="ghost"
            _active={{ bg: 'ceruleanBlue' }}
            _hover={{ bg: 'unset' }}
            bg={isOpen || appliedFiltersCount ? 'ceruleanBlue' : 'white'}
            color={appliedFiltersCount ? 'white' : 'darkLiver'}
            size="md"
            onClick={isOpen ? onClose : onOpen}
          />
        ) : (
          <MenuButton
            as={Button}
            leftIcon={<MdFilterList />}
            variant="lgWithIconLeft"
            bg={isOpen ? 'ceruleanBlue' : 'white'}
            color={isOpen ? 'white' : 'darkLiver'}
            size="md"
            onClick={isOpen ? onClose : onOpen}
          >
            Filter Deals {appliedFiltersCount ? `(${appliedFiltersCount})` : null}
          </MenuButton>
        )}

        <MenuList
          w={500}
          zIndex="dropdown"
          border="1px"
          borderColor="backgroundGray"
          fontSize={{ base: 'xs', sm: 'md' }}
          maxW={{ base: '70%', sm: 'unset' }}
        >
          <HStack margin={2} alignItems="start" spacing={0}>
            <Box
              as={VscTriangleUp}
              boxSize="50px"
              position="absolute"
              top="-25px"
              right="50px"
              color="white"
              pointerEvents="none"
            />
            <VStack w={{ base: '50%', sm: 'full' }} spacing={3}>
              <MenuFilterContainer label="Teams" spacing={3}>
                <CustomReactSelect
                  styles={{
                    option: (_provided, state) => ({
                      borderLeft: `5px solid ${state.data.color || 'black'}`,
                    }),
                    valueContainer: (_provided, state) => ({
                      borderLeft: `5px solid ${state.getValue()?.[0]?.color || 'black'}`,
                    }),
                  }}
                  value={selectedSalesPodOption}
                  options={salesPodsOptions}
                  onChange={(option) => {
                    const newSelectedOption = option ?? ALL_PODS_OPTION;
                    const newSelectedPod = loggedUserSalesPods.find(
                      (pod) => pod?.id?.toString() === newSelectedOption.value,
                    );
                    updateTempFilter('selectedPodId', newSelectedPod?.id ?? undefined);
                  }}
                />
              </MenuFilterContainer>

              <MenuFilterContainer label="FS Location" spacing={3}>
                <CustomReactSelect
                  value={selectedLocationOption}
                  options={[ALL_LOCATION_OPTION, ...SELECT_OPTIONS.LOCATIONS]}
                  onChange={(option) => {
                    updateTempFilter(
                      'location',
                      option?.value === ALL_LOCATION_VALUE ? undefined : option?.value,
                    );
                  }}
                />
              </MenuFilterContainer>

              <MenuFilterContainer label="Source" mb={20} fontWeight="normal" spacing={3}>
                {availableSourceOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    isChecked={tempFilters.sources.includes(option.value as DealSourceEnum)}
                    onChange={() =>
                      updateTempFilter(
                        'sources',
                        tempFilters.sources.includes(option.value as DealSourceEnum)
                          ? tempFilters.sources.filter((source) => source !== option.value)
                          : [...tempFilters.sources, option.value as DealSourceEnum],
                      )
                    }
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </MenuFilterContainer>

              <MenuFilterContainer label="Type" mb={20} fontWeight="normal" spacing={3}>
                {availableTypeOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    isChecked={tempFilters.types.includes(option.value as DealType)}
                    onChange={() =>
                      updateTempFilter(
                        'types',
                        tempFilters.types.includes(option.value as DealType)
                          ? tempFilters.types.filter((type) => type !== option.value)
                          : [...tempFilters.types, option.value as DealType],
                      )
                    }
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </MenuFilterContainer>
            </VStack>

            <VStack w={{ base: '70%', sm: 'full' }} spacing={3}>
              <Can I={[PermissionEnum.SeeUsers, PermissionEnum.SeeQueue]}>
                <MenuFilterContainer label="View As" spacing={3}>
                  <UserAutocomplete
                    includeEmptyOption
                    emptyOptionLabel="Me"
                    user={tempFilters.viewAsUserId ? new User(tempFilters.viewAsUserId) : undefined}
                    setUser={(newViewingAsUser) => {
                      if (newViewingAsUser?.id) {
                        updateTempFilter('viewAsUserId', newViewingAsUser?.id);
                      }

                      if (!newViewingAsUser?.id) {
                        updateTempFilter('sources', loggedUserSources);
                      }

                      if (newViewingAsUser?.id && pathname !== ROUTES.DASHBOARD) {
                        history.push(ROUTES.DASHBOARD);
                      }
                    }}
                  />
                </MenuFilterContainer>
              </Can>

              <MenuFilterContainer label="Filter By" fontWeight="normal" spacing={3}>
                <Checkbox
                  isChecked={tempFilters.showOnlyUnread}
                  onChange={(e) => updateTempFilter('showOnlyUnread', e.target.checked)}
                >
                  Unread Messages
                </Checkbox>
                <Can I={PermissionEnum.SeeAssignedToMeFilter}>
                  <Checkbox
                    isChecked={tempFilters.showAssignedToMe}
                    onChange={(e) => updateTempFilter('showAssignedToMe', e.target.checked)}
                  >
                    Assigned to Me
                  </Checkbox>
                </Can>
                <Checkbox
                  isChecked={tempFilters.showBootRequested}
                  onChange={(e) => updateTempFilter('showBootRequested', e.target.checked)}
                >
                  Boot Requested
                </Checkbox>
                <Can I={PermissionEnum.SeeAssignedToDeletedUsersFilter}>
                  <Checkbox
                    isChecked={tempFilters.showAssignedToDeletedUsers}
                    onChange={(e) =>
                      updateTempFilter('showAssignedToDeletedUsers', e.target.checked)
                    }
                  >
                    Assigned to Deleted Users
                  </Checkbox>
                </Can>
                <Checkbox
                  isChecked={tempFilters.showActiveTags}
                  onChange={(e) => updateTempFilter('showActiveTags', e.target.checked)}
                >
                  Active Tags
                </Checkbox>
                <Checkbox
                  isChecked={tempFilters.showMatureFollowUps}
                  onChange={(e) => updateTempFilter('showMatureFollowUps', e.target.checked)}
                >
                  Mature Follow-Ups
                </Checkbox>
              </MenuFilterContainer>

              <MenuFilterContainer label="Show" mb={20} fontWeight="normal">
                <Checkbox
                  isChecked={tempFilters.showFollowUps}
                  onChange={() => updateTempFilter('showFollowUps', !tempFilters.showFollowUps)}
                >
                  All Follow-Ups
                </Checkbox>
              </MenuFilterContainer>
            </VStack>
          </HStack>
          <HStack justifyContent="end" margin={3} mr={5}>
            <Button variant="secondary" size={{ base: 'sm', sm: 'md' }} onClick={handleReset}>
              Reset
            </Button>
            <Button size={{ base: 'sm', sm: 'md' }} onClick={handleApply}>
              Apply
            </Button>
          </HStack>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default GlobalFiltersMenu;
