import {
  Button,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UseModalProps,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BiCopy } from 'react-icons/bi';

import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';

import RequestPayoffButton from '../EstimateForm/RequestPayoffButton';
import Modal from '../shared/Modal';

import ReviveBootButton from './ReviveBootButton';

import { PermissionEnum } from '../../constants/permissions';
import { useHasPermission } from '../../hooks/useHasPermission';
import { useUser } from '../../hooks/useUser';

type BaseProps = {
  duplicateDeals: Deal[];
  showPayoffRequestButton?: boolean;
  upsertEstimateLoading?: boolean;
  disableProceedButton?: boolean;
  proceedButtonLabel?: string;
} & UseModalProps;

type OnDealDetailProps = {
  proceedFunction: () => void;
  submitEstimate?: never;
};

type OnNewEstimateProps = {
  submitEstimate:
    | ((values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void)
    | undefined;
  proceedFunction?: never;
};

type DuplicateDealsModalProps = BaseProps & (OnDealDetailProps | OnNewEstimateProps);

export const DuplicateDealsModal = ({
  duplicateDeals,
  isOpen,
  onClose,
  proceedFunction,
  submitEstimate,
  showPayoffRequestButton,
  proceedButtonLabel = 'CREATE DEAL',
  disableProceedButton = false,
  upsertEstimateLoading = false,
  ...rest
}: DuplicateDealsModalProps) => {
  const user = useUser();
  const { values, setSubmitting } = useFormikContext<Deal>();

  // if the user has permission to create duplicate deals or if all the duplicate deals were created by the user
  const canCreateDuplicateDeals =
    useHasPermission(PermissionEnum.CreateDuplicateDeals) ||
    duplicateDeals.every((d) => d.setter?.id === user.id);

  // order the duplicate deals
  const sortedDuplicateDeals = duplicateDeals.sort((a, b) => {
    return (
      new Date(b.creation_date_utc || '').getTime() - new Date(a.creation_date_utc || '').getTime()
    );
  });

  const showReviveBootButton = sortedDuplicateDeals.every((d) => d.state === DealStateEnum.Booted);

  const getRightHandButton = () => {
    if (showReviveBootButton) {
      return (
        <ReviveBootButton duplicateDeals={sortedDuplicateDeals} closeDuplicateModal={onClose} />
      );
    }
    if (!canCreateDuplicateDeals) {
      const handleCopyLink = () => {
        const links = duplicateDeals
          .map((d) => `${window.location.origin}/deals/${d.id}`)
          .join(',');
        navigator.clipboard.writeText(links);
        onClose();
      };

      return (
        <Button leftIcon={<BiCopy />} onClick={handleCopyLink}>
          Copy Link
        </Button>
      );
    }
    if (showPayoffRequestButton) {
      return <RequestPayoffButton />;
    }

    const proceedAction = submitEstimate
      ? () => submitEstimate(values, setSubmitting)
      : proceedFunction;

    return (
      <Button
        isLoading={upsertEstimateLoading}
        onClick={proceedAction}
        isDisabled={disableProceedButton}
      >
        {proceedButtonLabel}
      </Button>
    );
  };

  return (
    <Modal
      title="Duplicate Deal"
      isOpen={isOpen}
      onClose={onClose}
      leftButtons={
        <Button onClick={onClose} variant="warning">
          CANCEL
        </Button>
      }
      rightButtons={getRightHandButton()}
      size="4xl"
      {...rest}
    >
      <Text mb={8}>
        The information you have entered matches one or more deals already in the system. Please see
        below and send to your manager for review.
      </Text>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Vehicle</Th>
            <Th>Number</Th>
            <Th>State</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedDuplicateDeals.map((d) => (
            <Tr key={d.id}>
              <Td>
                <Link
                  href={`/deals/${d.id}`}
                  target="_blank"
                  rel="noreferrer"
                  width="10%"
                  fontWeight="bold"
                  color="mediumElectricBlue"
                >
                  {getFullName(d.customer)}
                </Link>
              </Td>
              <Td>
                {d.car.year} {d.car.make} {d.car.model}
              </Td>
              <Td>{d.customer.phone_number}</Td>
              <Td>{DealStateLabelMap[d.state]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Modal>
  );
};
