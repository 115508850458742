import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from 'react';

import { FetchResult, useApolloClient } from '@apollo/client';

import {
  GetUserCallHistoryDocument,
  GetUserCallHistoryQuery,
  MarkMissedSipNotificationAsSeenMutation,
  useGetUserCallHistoryQuery,
  useMarkMissedSipNotificationAsSeenMutation,
  useOnMissedSipCallSubscription,
} from '../gql/generated/graphql';

import usePaginationControls, { PaginationControls } from '../hooks/usePaginationControls';

export const DashboardInboxContext = createContext<{
  userCallHistoryData: GetUserCallHistoryQuery | undefined;
  callHistoryPaginationControls: PaginationControls;
  missedCallIds: string[];
  selectedTabState: [
    selectedTabIndex: number,
    setSelectedTabIndex: Dispatch<SetStateAction<number>>,
  ];
  markMissedCallAsSeen: () => Promise<FetchResult<MarkMissedSipNotificationAsSeenMutation>>;
}>({
  userCallHistoryData: {} as GetUserCallHistoryQuery,
  callHistoryPaginationControls: {} as PaginationControls,
  missedCallIds: [] as string[],
  selectedTabState: [0, () => undefined],
  markMissedCallAsSeen: () => Promise.resolve({ data: null }),
});

export const DashboardInboxProvider = ({ children }: { children: ReactNode }) => {
  const client = useApolloClient();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const paginationControls = usePaginationControls({
    initialItemsPerPageOptions: [50, 100, 250, 500],
  });

  const { data: userCallHistoryData } = useGetUserCallHistoryQuery({
    fetchPolicy: 'network-only',
    variables: {
      items_per_page: paginationControls.itemsPerPage,
      page: paginationControls.currentPage,
    },
    onCompleted: (data) => {
      if (!data.getUserCallHistory?.calls) {
        return;
      }

      const totalRecords = data.getUserCallHistory?.totalRecords;
      if (totalRecords) {
        paginationControls.setTotalRecords(totalRecords);
      }
    },
  });

  const missedCallIds = useMemo(() => {
    return (
      userCallHistoryData?.getUserCallHistory?.newMissedCallIds?.filter((id) => id != null) ?? []
    );
  }, [userCallHistoryData]);

  useOnMissedSipCallSubscription({
    onData: ({ data }) => {
      const missedCall = data?.data?.onMissedSipCall?.callInfo;
      if (!missedCall?.id) {
        return;
      }

      // Update the cache directly
      client.cache.updateQuery(
        {
          query: GetUserCallHistoryDocument,
          variables: {
            items_per_page: paginationControls.itemsPerPage,
            page: paginationControls.currentPage,
          },
        },
        (existingData) => {
          if (!existingData) {
            return null;
          }

          const missedCallForCache = {
            ...missedCall,

            created_at: new Date(),
          };

          return {
            ...existingData,
            getUserCallHistory: {
              ...existingData.getUserCallHistory,
              calls: [missedCallForCache, ...existingData.getUserCallHistory.calls],
            },
          };
        },
      );
    },
  });

  const [markMissedCallAsSeen] = useMarkMissedSipNotificationAsSeenMutation({
    variables: {
      callIds: missedCallIds,
    },
    onCompleted: () => {
      // Update cache to clear newMissedCallIds
      client.cache.updateQuery(
        {
          query: GetUserCallHistoryDocument,
          variables: {
            items_per_page: paginationControls.itemsPerPage,
            page: paginationControls.currentPage,
          },
        },
        (existingData) => {
          if (!existingData) {
            return null;
          }
          return {
            ...existingData,
            getUserCallHistory: {
              ...existingData.getUserCallHistory,
              newMissedCallIds: [],
            },
          };
        },
      );
    },
  });

  return (
    <DashboardInboxContext.Provider
      value={{
        userCallHistoryData,
        callHistoryPaginationControls: paginationControls,
        missedCallIds,
        selectedTabState: [selectedTabIndex, setSelectedTabIndex],
        markMissedCallAsSeen: () => markMissedCallAsSeen(),
      }}
    >
      {children}
    </DashboardInboxContext.Provider>
  );
};
