import { NavBarTextNotificationsQuery } from '../../gql/generated/graphql';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { CurrentView } from '../../pages/Dashboard';

export type NavBarButton = {
  label: string;
  shouldRender: boolean;
  page: string;
  menuOnly?: boolean;
  view?: CurrentView;
  hasTextNotifications?: boolean;
  search?: string;
};

export const getDefaultView = (abilities: Set<PermissionEnum>) => {
  if (abilities.has(PermissionEnum.SuperUser)) {
    return CurrentView.DashboardView;
  }
  if (abilities.has(PermissionEnum.TitleClerk)) {
    return CurrentView.TitleTableView;
  }
  if (abilities.has(PermissionEnum.PayoffClerk) || abilities.has(PermissionEnum.PayoffManager)) {
    return CurrentView.PayoffRequestsView;
  }
  return CurrentView.DashboardView;
};

type NavBarButtonProps = {
  abilities: Set<PermissionEnum>;
  textNotifications: NavBarTextNotificationsQuery['navBarTextNotifications'];
};

export const getNavBarButtons = ({ abilities, textNotifications }: NavBarButtonProps) => {
  const buttons: NavBarButton[] = [
    {
      label: 'Swimlanes',
      shouldRender: true,
      menuOnly: false,
      page: ROUTES.DASHBOARD,
      view: CurrentView.DashboardView,
    },
    {
      label: 'Payoffs',
      shouldRender: abilities.has(PermissionEnum.SeeAllPayoffRequests),
      menuOnly: false,
      page: ROUTES.DASHBOARD,
      view: CurrentView.PayoffRequestsView,
    },
    {
      label: 'Titling',
      shouldRender:
        abilities.has(PermissionEnum.TitleClerk) || abilities.has(PermissionEnum.SuperUser),
      menuOnly: false,
      page: ROUTES.DASHBOARD,
      view: CurrentView.TitleTableView,
    },
    {
      label: 'Structuring',
      shouldRender: abilities.has(PermissionEnum.SeeStructuringQueue),
      menuOnly: false,
      page: ROUTES.STRUCTURING_FOLLOW_UPS,
    },
    {
      label: 'Completed',
      shouldRender: !abilities.has(PermissionEnum.CannotSeeCompletedPage),
      menuOnly: false,
      page: ROUTES.COMPLETED_DEALS,
      hasTextNotifications: textNotifications?.completed,
    },
    {
      label: 'Booted',
      shouldRender: !abilities.has(PermissionEnum.CannotSeeBootedPage),
      menuOnly: false,
      page: ROUTES.REVIVE_BOOT,
      hasTextNotifications: textNotifications?.booted,
    },
    {
      label: 'Follow-Ups',
      shouldRender: !abilities.has(PermissionEnum.CannotSeeFollowUpsPage),
      menuOnly: false,
      page: ROUTES.FOLLOW_UPS,
      hasTextNotifications: textNotifications?.followUp,
    },
    {
      label: 'Metrics',
      shouldRender: !abilities.has(PermissionEnum.CannotSeeMetricsPage),
      menuOnly: false,
      page: ROUTES.METRICS,
      search: '?page=Overview',
    },
    {
      label: 'Teams',
      shouldRender: abilities.has(PermissionEnum.ManagePods),
      menuOnly: true,
      page: ROUTES.TEAMS,
    },
    {
      label: 'Com Reviews',
      shouldRender: abilities.has(PermissionEnum.SuperUser),
      menuOnly: true,
      page: ROUTES.COM_REVIEWS,
    },
    {
      label: 'Press Page Manager',
      shouldRender: abilities.has(PermissionEnum.SuperUser),
      menuOnly: true,
      page: ROUTES.PRESS_PAGE_MANAGER,
    },
  ];

  return buttons.filter(({ shouldRender }) => shouldRender);
};

type IsActiveProps = {
  button: NavBarButton;
  currentPage: string;
  currentView: CurrentView | undefined;
};
export const isActive = ({ button, currentPage, currentView }: IsActiveProps) =>
  currentPage === button?.page && (!button?.view || button.view === currentView);
