import { HStack, Icon, Link, Spacer, Stack, Td, Text, Tr } from '@chakra-ui/react';
import { MdPhoneCallback, MdPhoneMissed } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { getFullName } from '../../../gql/customerGql';
import { DealContact } from '../../../gql/dealContactGql';
import { DealStateLabelMap } from '../../../gql/dealGql';
import { Call, Customer } from '../../../gql/generated/graphql';

import { handleClickOrCommandClick } from '../../../libs/eventHandlers';
import { formatPhoneNumberForDisplay, formatTime } from '../../../libs/utils';
import { getMinutesAgo } from '../../../utils/dates';

const getCallerNameString = (call: Call) => {
  let fullName = '';
  const phoneNumber = formatPhoneNumberForDisplay(call?.fromNumber ?? '');
  const { deal } = call;

  if (
    deal?.customer?.phone_number &&
    phoneNumber === formatPhoneNumberForDisplay(deal.customer.phone_number)
  ) {
    fullName = `${getFullName(deal.customer)}`;
  } else if (
    deal?.cobuyer?.phone_number &&
    phoneNumber === formatPhoneNumberForDisplay(deal.cobuyer.phone_number)
  ) {
    fullName = `${getFullName(deal.cobuyer as Customer)} - Cobuyer`;
  } else if (
    deal?.contact?.phone_number &&
    phoneNumber === formatPhoneNumberForDisplay(deal.contact.phone_number)
  ) {
    fullName = `${getFullName(deal.contact as DealContact)} - External Contact`;
  } else if (
    deal?.second_contact?.phone_number &&
    phoneNumber === formatPhoneNumberForDisplay(deal.second_contact.phone_number)
  ) {
    fullName = `${getFullName(deal.second_contact as DealContact)} - External Contact`;
  }

  return fullName ? `${fullName} | ${phoneNumber}` : `${phoneNumber}`;
};

type PhoneCallRowProps = {
  call: Call;
};
const PhoneCallRow = ({ call }: PhoneCallRowProps) => {
  const history = useHistory();

  const { deal } = call;
  const stateLabel = DealStateLabelMap[deal?.state as keyof typeof DealStateLabelMap] ?? '';
  const wasAnswered = call.outcome === 'answered';
  const isVoicemail = call.outcome === 'voicemail';
  const isCallWithinLastHour = (getMinutesAgo(call.created_at) ?? 0) < 60;

  return (
    <Tr>
      <Td borderColor="lightGray">
        <HStack justifyContent="space-between" pr="15px">
          <HStack minW="60px" gap={2}>
            {wasAnswered ? (
              <Icon as={MdPhoneCallback} size={20} color="green" />
            ) : (
              <Icon as={MdPhoneMissed} size={20} color="errorsRed" />
            )}
            {/* Commenting out until we have a voicemail link in the calls table. The recordingUrl does not contain the voicemail.
             {isVoicemail && (
              <IconButton
                aria-label="Voicemail"
                icon={<MdOutlineVoicemail size={20} />}
                size="xs"
                variant="iconHover"
                color="oxfordBlue"
                onClick={() => window.open(call.recordingUrl ?? '', '_blank')}
              />
            )} */}
          </HStack>

          <Stack spacing={0}>
            <Text fontWeight="bold" color={isVoicemail ? 'errorsRed' : 'unset'}>
              {getCallerNameString(call)}
            </Text>
            <Text fontWeight="normal" fontSize="12px">
              {isCallWithinLastHour
                ? `${getMinutesAgo(call.created_at)} minutes ago`
                : formatTime(call.created_at?.toString())}
            </Text>
          </Stack>
          <Spacer />
          <Stack spacing={0}>
            <Link
              fontWeight="bold"
              textDecoration="underline"
              onClick={(event) => handleClickOrCommandClick(event, `/deals/${deal?.id}`, history)}
              onAuxClick={(event) =>
                handleClickOrCommandClick(event, `/deals/${deal?.id}`, history)
              }
            >
              {deal?.id ?? ''}
            </Link>
            <Text variant="inbox" fontWeight="normal" fontSize="12px" width="100px">
              {deal?.id ? stateLabel : ''}
            </Text>
          </Stack>
        </HStack>
      </Td>
    </Tr>
  );
};

export default PhoneCallRow;
