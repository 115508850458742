import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import { DealType, useDuplicateDealsLazyQuery } from '../../../gql/generated/graphql';

import Tooltip from '../../shared/Tooltip';
import { CreditAppOnDeal } from '../useCreditAppInitialValues';

import { LDFlags } from '../../../constants/experiments';
import { PermissionEnum } from '../../../constants/permissions';
import useFlag from '../../../hooks/useFlag';
import { DealContext } from '../../../libs/DealContext';
import { AbilityContext } from '../../../libs/contextLib';
import { validateFuture } from '../../../libs/yup-validators/dates';
import { DuplicateDealsModal } from '../../DuplicateDealsModal/DuplicateDealsModal';

enum ButtonAction {
  Move = 'move',
  Submit = 'submit',
}

type MoveOrSubmit = ButtonAction;

type SubmitApplicationButtonProps = {
  isSavingOrCheckingMileage: boolean;
  setDealState: Dispatch<SetStateAction<DealStateEnum>>;
  validatePayoffAndMileage: ({
    dealStateParam,
    skipPayoffValidation,
    skipMileageValidation,
  }: {
    dealStateParam?: DealStateEnum | undefined;
    skipPayoffValidation?: boolean | undefined;
    skipMileageValidation?: boolean | undefined;
  }) => Promise<void>;
};

const SubmitApplicationButtons = ({
  isSavingOrCheckingMileage,
  setDealState,
  validatePayoffAndMileage,
}: SubmitApplicationButtonProps) => {
  const prequalAutoStructureFlag = useFlag(LDFlags.PREQUAL_AUTOSTRUCTURE);

  const {
    deal,
    canAutoStructure,
    isRecalculatingPayoff,
    everyoneIsPrequalified,
    autosaving,
    isPayoffRequested,
  } = useContext(DealContext);
  const ability = useContext(AbilityContext);
  const { errors, isValid } = useFormikContext<CreditAppOnDeal>();

  const duplicateDealsModal = useDisclosure();

  const [buttonAction, setButtonAction] = useState<MoveOrSubmit>(ButtonAction.Move);

  const [checkDuplicateDeals, { data: duplicateDealsData, loading: duplicateDealsLoading }] =
    useDuplicateDealsLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const {
    hasVehiclePayoff,
    isVehiclePayoffMoreThanZero,
    hasGoodThroughDate,
    isGoodThroughDateInTheFuture,
  } = useMemo(
    () => ({
      hasVehiclePayoff: deal.car.payoff.vehicle_payoff != null,
      isVehiclePayoffMoreThanZero:
        deal.car.payoff.vehicle_payoff && deal.car.payoff.vehicle_payoff > 0,

      hasGoodThroughDate: deal.car.payoff.good_through_date != null,
      isGoodThroughDateInTheFuture: validateFuture(
        deal.car.payoff.good_through_date ? new Date(deal.car.payoff.good_through_date) : null,
      ),
    }),
    [deal.car.payoff.vehicle_payoff, deal.car.payoff.good_through_date],
  );

  const canMoveToStructuring = ability.has(PermissionEnum.MoveToStructuring);
  const isRefi = deal.type === DealType.Refi;
  const isMissingPrequalification = prequalAutoStructureFlag && !everyoneIsPrequalified;
  const isFollowUpScheduled = !!deal.follow_up;

  const getIsDisabled = (button: MoveOrSubmit) =>
    !isValid ||
    isPayoffRequested ||
    !hasVehiclePayoff ||
    !isVehiclePayoffMoreThanZero ||
    !hasGoodThroughDate ||
    !isGoodThroughDateInTheFuture ||
    isRecalculatingPayoff ||
    (isMissingPrequalification && (button === ButtonAction.Submit || !canMoveToStructuring)) ||
    isFollowUpScheduled ||
    autosaving;

  const getAllErrors = (button: MoveOrSubmit) => ({
    ...errors,
    ...(isPayoffRequested ? { payoffRequestError: 'Payoff was requested' } : {}),
    ...(isMissingPrequalification && (button === 'submit' || !canMoveToStructuring)
      ? { prequalificationError: 'Prequalification is required' }
      : {}),
    ...(isFollowUpScheduled ? { followupError: 'Cannot have a current follow up scheduled' } : {}),
    ...(!hasVehiclePayoff ? { payoffError: 'Vehicle payoff is required' } : {}),
    ...(!isVehiclePayoffMoreThanZero
      ? { payoffError: 'Vehicle Payoff must be greater than 0' }
      : {}),
    ...(!hasGoodThroughDate ? { goodThroughError: 'Must have a good through date' } : {}),
    ...(!isGoodThroughDateInTheFuture
      ? { goodThroughError: 'Good through date must be in the future' }
      : {}),
  });

  const { moveIsDisabled, submitIsDisabled } = useMemo(
    () => ({
      moveIsDisabled: getIsDisabled(ButtonAction.Move),
      submitIsDisabled: getIsDisabled(ButtonAction.Submit),
    }),
    [
      isValid,
      isPayoffRequested,
      hasVehiclePayoff,
      isVehiclePayoffMoreThanZero,
      hasGoodThroughDate,
      isGoodThroughDateInTheFuture,
      isRecalculatingPayoff,
      isMissingPrequalification,
      canMoveToStructuring,
      isFollowUpScheduled,
      autosaving,
    ],
  );

  const { moveAllErrors, submitAllErrors } = useMemo(() => {
    duplicateDealsModal.onClose();
    return {
      moveAllErrors: getAllErrors(ButtonAction.Move),
      submitAllErrors: getAllErrors(ButtonAction.Submit),
    };
  }, [
    errors,
    isPayoffRequested,
    isMissingPrequalification,
    canMoveToStructuring,
    isFollowUpScheduled,
    hasVehiclePayoff,
    isVehiclePayoffMoreThanZero,
    hasGoodThroughDate,
    isGoodThroughDateInTheFuture,
  ]);

  const handleProceed = (action: MoveOrSubmit) => {
    const submitState =
      !isRefi &&
      (!prequalAutoStructureFlag || canAutoStructure) &&
      ability.has(PermissionEnum.AutomateCreditApplication)
        ? DealStateEnum.StructuringInProgress
        : DealStateEnum.Structuring;

    const newState = action === ButtonAction.Move ? DealStateEnum.Structuring : submitState; // Use parameter instead of state var to make sure the rerender contains the correct state
    setDealState(newState);
    validatePayoffAndMileage({ dealStateParam: newState });
  };

  const handleClick = async (action: MoveOrSubmit) => {
    if (!isValid) {
      return;
    }

    // Skip duplicate check if the deal was already intentionally duplicated.
    if (!deal.duplicated_from_deal_id) {
      const { data } = await checkDuplicateDeals({
        variables: {
          dealId: deal.id,
          firstName: deal.customer.first_name,
          lastName: deal.customer.last_name,
          phoneNumber: deal.customer.phone_number,
          homePhoneNumber: deal.customer.home_phone_number,
          vin: deal.car.vin,
          matchBootedDeals: false,
        },
      });

      // If duplicates found, open modal and return.
      if (data?.duplicateDeals?.length) {
        duplicateDealsModal.onOpen();
        return;
      }
    }

    // If no duplicates or modal wasn't opened, proceed.
    handleProceed(action);
  };

  return (
    <>
      {canMoveToStructuring && (
        <Tooltip errors={moveAllErrors}>
          <Button
            variant="secondary"
            isLoading={duplicateDealsLoading || isSavingOrCheckingMileage}
            loadingText="MOVE TO STRUCTURING"
            isDisabled={moveIsDisabled}
            onClick={() => {
              setButtonAction(ButtonAction.Move);
              handleClick(ButtonAction.Move);
            }}
          >
            MOVE TO STRUCTURING
          </Button>
        </Tooltip>
      )}
      <Tooltip errors={submitAllErrors}>
        <Button
          isLoading={duplicateDealsLoading || isSavingOrCheckingMileage}
          loadingText="SUBMIT APPLICATION"
          isDisabled={submitIsDisabled}
          onClick={() => {
            setButtonAction(ButtonAction.Submit);
            handleClick(ButtonAction.Submit);
          }}
        >
          SUBMIT APPLICATION
        </Button>
      </Tooltip>
      <DuplicateDealsModal
        {...duplicateDealsModal}
        duplicateDeals={(duplicateDealsData?.duplicateDeals ?? []) as Deal[]}
        proceedButtonLabel={buttonAction === ButtonAction.Submit ? 'SUBMIT' : 'MOVE'}
        disableProceedButton={getIsDisabled(buttonAction)}
        proceedFunction={() => handleProceed(buttonAction)}
      />
    </>
  );
};

export default SubmitApplicationButtons;
