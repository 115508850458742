import { useContext, useMemo } from 'react';

import {
  Button,
  Flex,
  List,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  UnorderedList,
} from '@chakra-ui/react';

import {
  DealMediaTypeEnum,
  Maybe,
  TtDocumentName,
  TtJurisdiction,
} from '../../../gql/generated/graphql';
import { TTValidationName } from '../../../gql/taterTitleGql';

import Modal from '../../shared/Modal';

import { DealMediaTypeLabels } from '../../../constants/media';
import { DealContext } from '../../../libs/DealContext';
import { isNullOrUndefined } from '../../../utils';

const driversLicenseValidations = [
  {
    name: TTValidationName.DriversLicenseNameMatch,
    text: `requires the name on the buyer's driver’s license to match the name on the application.`,
  },
  {
    name: TTValidationName.DriversLicenseAddressMatch,
    text: `requires the address on the buyer's driver’s license to match the address on the application.`,
  },
  {
    name: TTValidationName.DriversLicenseStateMatch,
    text: `requires the state on the buyer's driver’s license to match the state on the application.`,
  },
];

const insuranceValidations = [
  {
    name: TTValidationName.InsuranceNameMatch,
    text: `The buyer's name on the insurance policy needs to match the name as it appears on the buyer's driver's license.`,
  },
  {
    name: TTValidationName.InsuranceStateMatch,
    text: `The state in which the buyer's policy is effective needs to match the state that issued the buyer's driver's license.`,
  },
  {
    name: TTValidationName.InsuranceNotExpired,
    text: 'The policy needs to be active and not expired.',
  },
  {
    name: TTValidationName.CobuyerOnInsurance,
    text: 'The cobuyer must be included on the insurance.',
  },
];

export const getRequiredDocumentDescriptions = (
  requiredDocuments: string[],
  validations: TTValidationName[],
  registrationInformation: string[],
  state: string,
  moved_states?: boolean,
) => {
  const driversLicenseInformation: string[] = [];
  driversLicenseValidations.forEach((v) => {
    if (validations.includes(v.name)) {
      driversLicenseInformation.push(`${state} ${v.text}`);
    }
  });

  const insuranceInformation: string[] = [];
  insuranceValidations.forEach((v) => {
    if (validations.includes(v.name)) {
      insuranceInformation.push(v.text);
    }
  });

  const documentDescriptionsArr = [
    {
      name: DealMediaTypeEnum.FrontOfDriversLicense,
      label: DealMediaTypeLabels[DealMediaTypeEnum.FrontOfDriversLicense],
      information: driversLicenseInformation.length
        ? driversLicenseInformation
        : [`${state} requires a copy of the front of the buyer's driver's license.`],
    },
    {
      name: DealMediaTypeEnum.BackOfDriversLicense,
      label: DealMediaTypeLabels[DealMediaTypeEnum.BackOfDriversLicense],
      information: [`${state} requires a copy of the back of the buyer's driver's license.`],
    },
    {
      name: DealMediaTypeEnum.FrontOfCoBuyersLicense,
      label: DealMediaTypeLabels[DealMediaTypeEnum.FrontOfCoBuyersLicense],
      information: [`${state} requires a copy of the front of the cobuyer's driver's license.`],
    },
    {
      name: DealMediaTypeEnum.BackOfCoBuyersLicense,
      label: DealMediaTypeLabels[DealMediaTypeEnum.BackOfCoBuyersLicense],
      information: [`${state} requires a copy of the back of the cobuyer's driver's license.`],
    },
    {
      name: DealMediaTypeEnum.FrontOfInsuranceCard,
      label: DealMediaTypeLabels[DealMediaTypeEnum.FrontOfInsuranceCard],
      information: insuranceInformation.length
        ? insuranceInformation
        : [
            `${state} requires proof of insurance.`,
            ...(state === 'MA'
              ? [
                  'MA requires a MA insurance policy.',
                  'Our main buyer on the deal must be the policy holder.',
                  'Co-buyers must be listed as a driver on the policy.',
                ]
              : []),
          ],
    },
    {
      name: DealMediaTypeEnum.VinInspection,
      label: DealMediaTypeLabels[DealMediaTypeEnum.VinInspection],
      information: [
        moved_states
          ? `${state} requires a VIN inspection because the buyer relocated during their lease term.`
          : `${state} requires a VIN inspection to ensure that the correct vehicle is registered.`,
        'Most DMVs or local police stations offer VIN inspections.',
        ...(state === 'HI'
          ? [
              'HI deals require that the vehicle title be a Hawaii title or that the vehicle is physically present in the state.',
            ]
          : []),
      ],
    },
    {
      name: DealMediaTypeEnum.Emissions,
      label: DealMediaTypeLabels[DealMediaTypeEnum.Emissions],
      information: [
        `${state} requires an emissions inspection to ensure the vehicle is in compliance with state law.`,
        'Many automotive repair shops offer emissions testing/reporting. The buyer can also contact their county emissions authority to get a list of approved providers.',
      ],
    },
    {
      name: DealMediaTypeEnum.ProofOfResidence,
      label: DealMediaTypeLabels[DealMediaTypeEnum.ProofOfResidence],
      information: [
        `The buyer's residential address doesn’t match the address on their driver’s license, so ${state} requires additional proof of residence.`,
        `Proof of residence can be a document like a phone or utility bill, bank statement, etc. that clearly shows the buyer's current address.`,
      ],
    },
    {
      name: DealMediaTypeEnum.Registration,
      label: DealMediaTypeLabels[DealMediaTypeEnum.Registration],
      information: registrationInformation,
    },
    {
      name: DealMediaTypeEnum.PropertyTaxReceipt,
      label: DealMediaTypeLabels[DealMediaTypeEnum.PropertyTaxReceipt],
      information: [
        `${state} requires a property tax receipt to be paid before registering a vehicle.`,
        `This tax is not included in the registration fees collected by LeaseEnd.`,
        `A property tax receipt is required to complete the registration process.`,
        'The buyer can go to their local DMV office to obtain this.',
      ],
    },
    {
      name: DealMediaTypeEnum.PlateTransferLetter,
      label: DealMediaTypeLabels[DealMediaTypeEnum.PlateTransferLetter],
      information: [
        `To transfer plates in ${state} the driver will need to reach out to their lienholder and get a plate release letter.`,
      ],
    },
  ];

  return documentDescriptionsArr.filter((d) => requiredDocuments.includes(d.name));
};

/**
 * Retrieve validation descriptions for the `Registration` document for both `Title` and `Registration` products.
 */

const getRegistrationValidationDescriptions = (data: Maybe<TtJurisdiction>) => {
  const allDescriptions =
    data?.products?.items
      ?.flatMap(
        (productItem) =>
          productItem?.documents?.items
            ?.find((documentItem) => documentItem?.type?.name === TtDocumentName.Registration)
            ?.validations?.flatMap(
              (documentValidation) =>
                documentValidation?.internalDescription ?? documentValidation?.description,
            ) ?? [],
      )
      .filter((description) => !isNullOrUndefined(description)) ?? [];

  // Remove any duplicates
  return Array.from(new Set(allDescriptions));
};

interface RequiredDocumentsModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const RequiredDocumentsModal = ({ isOpen, onClose }: RequiredDocumentsModalProps) => {
  const {
    deal,
    jurisdictionData: { jurisdiction, jurisdictionLoading, requiredDocuments, validations },
  } = useContext(DealContext);

  const requiredDocumentsDescriptions = useMemo(
    () =>
      getRequiredDocumentDescriptions(
        requiredDocuments,
        validations,
        getRegistrationValidationDescriptions(jurisdiction),
        deal.customer.address?.state,
        deal.customer.address?.moved_states,
      ),
    [deal, jurisdiction, requiredDocuments, validations],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="5xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Required Customer Files</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="center" marginBottom={4} hidden={!jurisdictionLoading}>
            <Spinner size="lg" />
          </Flex>
          {requiredDocumentsDescriptions?.map((d) => {
            return (
              <List marginBottom={3} marginLeft={10} key={d.name}>
                <ListItem marginBottom={2}>{d.label}</ListItem>
                <ListItem>
                  <UnorderedList marginLeft={10}>
                    {d.information.map((i) => {
                      return <ListItem key={i}>{i}</ListItem>;
                    })}
                  </UnorderedList>
                </ListItem>
              </List>
            );
          })}
          <Flex justifyContent="flex-end">
            <Button variant="secondary" onClick={onClose} marginY={4}>
              CLOSE
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RequiredDocumentsModal;
