import { useContext } from 'react';

import { NewEstimateContext } from '../libs/NewEstimateContext';

export const useNewEstimateContext = () => {
  const context = useContext(NewEstimateContext);
  if (!context) {
    throw new Error(
      `useNewEstimateContext returned \`undefined\`. Seems you forgot to use NewEstimateContext.Provider`,
    );
  }

  return context;
};
