import { Dispatch, SetStateAction, useContext } from 'react';

import { useMutation } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, estimateUpsert } from '../../gql/dealGql';
import {
  useCreatePayoffRequestMutation,
  useDuplicateDealsLazyQuery,
  usePayoffRequestCancelMutation,
} from '../../gql/generated/graphql';

import Tooltip from '../shared/Tooltip';

import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { useUser } from '../../hooks/useUser';
import { Can } from '../../libs/Can';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';
import { cleanDealForEstimateUpsert } from '../../utils/deals';

type RequestPayoffButtonProps = ButtonProps & {
  isDealDetail?: boolean;
  onClick?: () => void;
  setShowPayoffRequestButton?: Dispatch<SetStateAction<boolean>>;
  isPayoffRequestLoading?: boolean;
  setIsPayoffRequestLoading?: Dispatch<SetStateAction<boolean>>;
  isSaving?: boolean;
  setDuplicateDeals?: Dispatch<SetStateAction<Deal[]>>;
  duplicateDealsModal?: {
    isOpen: boolean;
    onOpen: () => void;
  };
};

const RequestPayoffButton = ({
  isDealDetail,
  onClick,
  isPayoffRequestLoading,
  setIsPayoffRequestLoading,
  isSaving = false,
  setShowPayoffRequestButton,
  setDuplicateDeals,
  duplicateDealsModal,
  ...rest
}: RequestPayoffButtonProps) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useUser();

  const { dispatch, payoffRequest, isPayoffRequested, payoffRequestRefetch } =
    useContext(DealContext);
  const {
    values,
    errors: { customer: customerError, car: carError },
    setFieldValue,
  } = useFormikContext<Deal>();

  const [upsertEstimate, { loading: isUpsertingEstimate }] = useMutation(estimateUpsert);
  const [createPayoffRequest, { loading: isCreatingPayoffRequest }] =
    useCreatePayoffRequestMutation();
  const [cancelPayoffRequest, { loading: isCancelingPayoffRequest }] =
    usePayoffRequestCancelMutation();
  const [checkDuplicateDeals] = useDuplicateDealsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const requestPayoffButtonErrors = [
    customerError?.first_name,
    customerError?.last_name,
    customerError?.address?.zip,
    customerError?.phone_number,
    customerError?.home_phone_number,
    customerError?.ssn,
    customerError?.ssn_last_4,
    customerError?.ssn_last_6,
    customerError?.dob,
    carError?.year,
    carError?.make,
    carError?.vin,
    carError?.mileage,
    carError?.model,
    carError?.payoff?.lienholder_slug,
    carError?.payoff?.account_number,
  ];

  const isDisabled =
    requestPayoffButtonErrors.some(Boolean) ||
    isPayoffRequestLoading ||
    isPayoffRequested ||
    isSaving;

  const requestPayoff = async () => {
    if (isDisabled) {
      return;
    }

    if (setShowPayoffRequestButton) {
      setShowPayoffRequestButton(true);
    }

    if (setIsPayoffRequestLoading) {
      setIsPayoffRequestLoading(true);
    }

    let deal_id = values.id;

    try {
      const updatedDeal = cleanDealForEstimateUpsert(values);

      // `setDuplicateDeals` is only passed in `LienholderLenderDetails` and only applies in `NewEstimate` page.
      if (setDuplicateDeals) {
        const duplicateData = await checkDuplicateDeals({
          variables: {
            dealId: deal_id,
            firstName: updatedDeal.customer?.first_name,
            middleName: updatedDeal.customer?.middle_name,
            lastName: updatedDeal.customer?.last_name,
            phoneNumber: updatedDeal.customer?.phone_number,
            homePhoneNumber: updatedDeal.customer?.home_phone_number,
            vin: updatedDeal.car?.vin,
            matchBootedDeals: true,
          },
        });

        const dups = (duplicateData.data?.duplicateDeals ?? []) as Deal[];

        if (dups && dups.length) {
          setDuplicateDeals(dups);

          if (duplicateDealsModal && !duplicateDealsModal?.isOpen && setIsPayoffRequestLoading) {
            setIsPayoffRequestLoading(false);
            duplicateDealsModal.onOpen();
            return;
          }
        }
      }

      if (!deal_id) {
        const {
          data: {
            estimateUpsert: {
              id: new_deal_id,
              car: { id: car_id },
              customer: { id: customer_id },
            },
          },
        } = await upsertEstimate({
          variables: {
            deal: {
              ...updatedDeal,
              creation_date_utc: new Date(),
              creation_date_tz: getTimezoneStr(),
              notes: [
                ...updatedDeal.notes,
                {
                  text: 'Deal Created',
                  author_id: id,
                  creation_date_tz: getTimezoneStr(),
                },
              ],
            },
          },
        });

        deal_id = new_deal_id as number;

        setFieldValue('id', deal_id, false);
        setFieldValue('car.id', car_id, false);
        setFieldValue('customer.id', customer_id, false);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!isDealDetail && values.state === DealStateEnum.Estimate) {
          const res = await upsertEstimate({
            variables: {
              deal: updatedDeal,
            },
          });

          dispatch({ type: DealActionsEnum.UpdateDeal, payload: res.data.estimateUpsert });
        }
      }

      onClick?.();

      await createPayoffRequest({ variables: { deal_id } });

      payoffRequestRefetch();

      if (location.pathname === ROUTES.NEW_ESTIMATE) {
        history.push(`/deals/${deal_id}`);
      }
    } catch (error) {
      toast.error('Failed to request payoff');
      logger.error('RequestPayoffButton.tsx', 'Failed to request payoff', null, error);
    }

    if (setIsPayoffRequestLoading) {
      setIsPayoffRequestLoading(false);
    }
  };

  const cancelPayoff = async () => {
    try {
      if (payoffRequest?.id) {
        await cancelPayoffRequest({
          variables: { payoffRequestId: payoffRequest.id },
        });
        payoffRequestRefetch();
      }
    } catch (error) {
      toast.error('Failed to cancel payoff');
      logger.error('RequestPayoffButton.tsx', 'Failed to cancel payoff request', null, error);
    }
  };

  return (
    <Can I={PermissionEnum.RequestPayoff}>
      <Tooltip errors={requestPayoffButtonErrors}>
        {isPayoffRequested ? (
          <Button
            {...rest}
            variant="warning"
            isLoading={isCancelingPayoffRequest}
            loadingText="CANCEL REQUEST"
            onClick={cancelPayoff}
          >
            CANCEL REQUEST
          </Button>
        ) : (
          <Button
            {...rest}
            isDisabled={isDisabled}
            isLoading={isCreatingPayoffRequest || isUpsertingEstimate}
            loadingText="REQUEST PAYOFF"
            onClick={requestPayoff}
          >
            REQUEST PAYOFF
          </Button>
        )}
      </Tooltip>
    </Can>
  );
};

export default RequestPayoffButton;
