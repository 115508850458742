import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  HuntGroup,
  LocationType,
  UpdateUserInput,
  User,
  useGetAuth0RolesQuery,
  useGetUserCreationDataQuery,
  useUpdateUserMutation,
} from '../../../gql/generated/graphql';
import { Pod } from '../../../gql/podGql';

import Modal, { LEModalProps } from '../../../components/shared/Modal';
import { editUserValidationSchema } from '../utils';

import UserForm from './UserForm';

import { logger } from '../../../libs/Logger';

export type EditUserModalFormikFields = Omit<UpdateUserInput, 'podIds' | 'huntGroupIds'> & {
  pods: Pod[];
  huntGroups: HuntGroup[];
};

type EditUserModalProps = LEModalProps & {
  user: User | null;
};

const EditUserModal = ({ user, isOpen, onClose, ...rest }: EditUserModalProps) => {
  const [updateUser, { loading }] = useUpdateUserMutation();
  const { data: userCreationData } = useGetUserCreationDataQuery({
    skip: !isOpen,
  });
  const { data: auth0RolesData } = useGetAuth0RolesQuery({
    skip: !isOpen,
  });

  const handleCloseModal = (formikHelpers: { resetForm: () => void }) => {
    onClose();
    formikHelpers.resetForm();
  };

  const initialValues: EditUserModalFormikFields = {
    id: user?.id ?? '',
    name: user?.name ?? '',
    email: user?.email ?? '',
    auth0Roles:
      user?.auth0_roles?.roles?.map((role) => ({
        id: role?.id ?? '',
        name: role?.name ?? '',
      })) ?? [],
    phoneNumber: user?.phone_number ?? '',
    pods:
      user?.pods?.map((pod) => ({
        id: pod?.id ?? -1,
        name: pod?.name ?? '',
        color: pod?.color ?? '',
      })) ?? [],
    recruiterId: user?.recruiter_id ?? '',
    huntGroups:
      user?.huntGroups?.map((huntGroup) => ({
        id: huntGroup?.id ?? -1,
        name: huntGroup?.name ?? '',
      })) ?? [],
    location: user?.location ?? LocationType.Utah,
  };

  const onSave = async (
    values: EditUserModalFormikFields,
    formikHelpers: { resetForm: () => void },
  ) => {
    try {
      const { data } = await updateUser({
        variables: {
          updateUserInput: {
            id: values.id,
            name: values.name,
            email: values.email,
            auth0Roles: values.auth0Roles ?? [],
            recruiterId: values.recruiterId,
            phoneNumber: values.phoneNumber || null,
            podIds: values.pods?.map((pod) => pod.id),
            huntGroupIds: values.huntGroups?.map((huntGroup) => huntGroup.id),
            location: values.location,
          },
          allAuth0RoleIds: auth0RolesData?.getAuth0Roles?.map((r) => r.id ?? '') ?? [],
        },
      });

      if (data?.updateUser) {
        handleCloseModal({ resetForm: formikHelpers.resetForm });
        toast.success('User edited successfully');
      }
    } catch (e) {
      const error = e as Error;
      toast.error(`Failed to edit user: ${error.message}`);
      logger.error('EditUserModal.tsx', 'Failed to edit user', null, e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editUserValidationSchema}
      validateOnMount
      onSubmit={onSave}
      enableReinitialize
    >
      {({ isValid, resetForm, values }) => (
        <Modal
          {...rest}
          title="Edit User"
          size="3xl"
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            handleCloseModal({ resetForm });
          }}
          leftButtons={
            <Button variant="warning" onClick={() => handleCloseModal({ resetForm })}>
              CANCEL
            </Button>
          }
          rightButtons={
            <Button
              variant="primary"
              type="submit"
              isDisabled={!isValid}
              isLoading={loading}
              onClick={() => onSave(values, { resetForm })}
            >
              SAVE CHANGES
            </Button>
          }
        >
          <UserForm
            pods={userCreationData?.getUserCreationData?.pods}
            huntGroups={userCreationData?.getUserCreationData?.huntGroups}
            auth0Roles={auth0RolesData?.getAuth0Roles}
            isEdit
          />
        </Modal>
      )}
    </Formik>
  );
};

export default EditUserModal;
