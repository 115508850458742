import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { getFullName } from '../gql/customerGql';
import { Deal } from '../gql/dealGql';

import ROUTES from '../constants/routes';

type Routes = typeof ROUTES;
type RouteValue = Routes[keyof Routes];
type RouteTitles = {
  [key in RouteValue]: string;
};

const routeTitles: RouteTitles = {
  [ROUTES.HOME]: 'Home',
  [ROUTES.DASHBOARD]: 'Dashboard',
  [ROUTES.COM_REVIEWS]: 'Com Reviews',
  [ROUTES.NEW_ESTIMATE]: 'New Estimate',
  [ROUTES.DEAL_SHOW]: 'Deal',
  [ROUTES.TITLING_DEAL_SHOW]: 'Titling Deal',
  [ROUTES.REVIVE_BOOT]: 'Revive Boot',
  [ROUTES.COMPLETED_DEALS]: 'Completed Deals',
  [ROUTES.FOLLOW_UPS]: 'Follow-Ups',
  [ROUTES.TEAMS]: 'Teams',
  [ROUTES.TRANSCRIPTIONS]: 'Transcriptions',
  [ROUTES.PRESS_PAGE_MANAGER]: 'Press Page Manager',
  [ROUTES.METRICS]: 'Metrics',
  [ROUTES.STRUCTURING_FOLLOW_UPS]: 'Structuring Queue',
  [ROUTES.THUNDER_CONVERSATION]: 'Thunder Conversation',
  [ROUTES.ACCESS_DENIED]: 'Access Denied',
};

const getTitleFromPathname = (pathname: string) => {
  const matchingPath = Object.keys(routeTitles).find((path) => {
    const pathSegments = path.split('/');
    const pathnameSegments = pathname.split('/');

    return pathSegments.every(
      (segment, index) => segment === pathnameSegments[index] || segment.startsWith(':'),
    );
  });

  return matchingPath ? routeTitles[matchingPath as RouteValue] : undefined;
};

interface UsePageTitleProps {
  deal?: Deal | null;
}

const usePageTitle = ({ deal }: UsePageTitleProps = {}) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const baseTitle = 'Lease End';
    const customerName = getFullName(deal?.customer).trim();
    const pageTitle = customerName || getTitleFromPathname(pathname);
    const newTitle = pageTitle ? `${pageTitle} | ${baseTitle}` : baseTitle;

    document.title = newTitle;
  }, [pathname, deal]);
};

export default usePageTitle;
