import { useCallback, useContext, useEffect } from 'react';

import { Flex, FlexProps, HStack, StyleProps, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal, DealStateEnum } from '../../../gql/dealGql';
import {
  DealType,
  TitleRegistrationOption,
  useNoteCreateMutation,
} from '../../../gql/generated/graphql';
import { NoteTypeEnum } from '../../../gql/noteGql';

import RadioButtons from '../../shared/RadioButtons';
import { DebouncedSave } from '../DealInfoBuyoutForm';

import InputRow from './InputRow';
import { Registration } from './Registration';
import { Taxes } from './Taxes';
import CalcFeesButton from './buttons/CalcFeesButton';

import { LE_TIME_ZONE } from '../../../constants/LeaseEndInfo';
import useTitleRegistrationOptions from '../../../hooks/useTitleRegistrationOptions';
import { useUser } from '../../../hooks/useUser';
import { DealContext } from '../../../libs/DealContext';
import { NotesContext } from '../../../libs/contextLib';
import { formatMoney } from '../../../libs/utils';
import { isNullOrUndefined, someIsNullOrUndefined } from '../../../utils';

export type InsertNoteIfValueManuallyChangedArgs = {
  fieldName: 'registration' | 'taxes';
  oldValue?: number;
  newValue?: number;
};

const TitleRegistrationOptionsPriority: TitleRegistrationOption[] = [
  TitleRegistrationOption.TitleAndNewRegistration,
  TitleRegistrationOption.TitleOnly,
  TitleRegistrationOption.TitleAndRegistrationTransfer,
];

type TitleAndRegistrationSectionProps = FlexProps & {
  isEditable: boolean;
  debouncedSave: DebouncedSave;
  defaultFontSize?: StyleProps['fontSize'];
};

export const TitleAndRegistrationSection = ({
  isEditable,
  debouncedSave,
  defaultFontSize = 'sm',
  ...rest
}: TitleAndRegistrationSectionProps) => {
  const {
    deal,
    jurisdictionData: { jurisdiction },
    setUserChangedSomething,
  } = useContext(DealContext);
  const { notesRefetch } = useContext(NotesContext);
  const user = useUser();

  const { values, setFieldValue } = useFormikContext<Deal>();

  const titleRegistrationOptions = useTitleRegistrationOptions(deal, jurisdiction);

  const isRefi = deal.type === DealType.Refi;
  const inClosing = deal.state === DealStateEnum.Closing;
  const inStructuring = deal.state === DealStateEnum.Structuring;

  const [createNote] = useNoteCreateMutation();

  useEffect(() => {
    if (
      isRefi ||
      someIsNullOrUndefined([
        jurisdiction?.hasTitleOnly,
        jurisdiction?.hasTitleAndRegistration,
        jurisdiction?.allowRegistrationTransfer,
      ])
    ) {
      return;
    }

    if (
      isNullOrUndefined(values.financial_info.title_registration_option) ||
      titleRegistrationOptions.some(
        (option) =>
          option.value === values.financial_info.title_registration_option && !option.enabled,
      )
    ) {
      const firstEnabledOption =
        TitleRegistrationOptionsPriority.find((priorityValue) =>
          titleRegistrationOptions.some(
            (option) => option.value === priorityValue && option.enabled,
          ),
        ) || TitleRegistrationOption.TitleAndNewRegistration;

      setFieldValue('financial_info.title_registration_option', firstEnabledOption);
    }

    if (
      isNullOrUndefined(values.financial_info.plate_transfer) &&
      jurisdiction?.allowPlateTransfer === false
    ) {
      setFieldValue('financial_info.plate_transfer', false);
    }
  }, [
    jurisdiction?.hasTitleOnly,
    jurisdiction?.hasTitleAndRegistration,
    jurisdiction?.allowRegistrationTransfer,
    jurisdiction?.allowPlateTransfer,
    titleRegistrationOptions,
    values.financial_info.title_registration_option,
    values.financial_info.plate_transfer,
  ]);

  const insertNoteIfValueManuallyChanged = useCallback(
    async ({ fieldName, oldValue, newValue }: InsertNoteIfValueManuallyChangedArgs) => {
      if (oldValue === newValue) {
        return;
      }

      await createNote({
        variables: {
          note: {
            author_id: user.id,
            deal_id: deal.id,
            creation_date_tz: LE_TIME_ZONE,
            note_type: NoteTypeEnum.System,
            text: `Calculated ${fieldName} of ${
              formatMoney(oldValue) || '$0.00'
            } manually updated to ${formatMoney(newValue) || '$0.00'}.`,
          },
        },
      });

      await notesRefetch();
    },
    [user.id, deal.id, notesRefetch],
  );

  return (
    <Flex direction="column" {...rest}>
      {!isRefi ? (
        <RadioButtons
          name="financial_info.title_registration_option"
          options={titleRegistrationOptions}
          isDisabled={!isEditable}
          setUserChangedSomething={setUserChangedSomething}
        />
      ) : null}
      <InputRow label="Title">
        <Text variant="dealInfoBuyout">{formatMoney(values.financial_info?.title_fee)}</Text>
      </InputRow>
      {!isRefi ? (
        <InputRow label="Taxes">
          <Taxes
            dealDetailsPageBeingEdited={isEditable}
            debouncedSave={debouncedSave}
            insertNoteIfValueManuallyChanged={insertNoteIfValueManuallyChanged}
            fontSize={defaultFontSize}
          />
        </InputRow>
      ) : null}
      {!isRefi ? (
        <InputRow label="Registration">
          <Registration
            dealDetailsPageBeingEdited={isEditable}
            debouncedSave={debouncedSave}
            insertNoteIfValueManuallyChanged={insertNoteIfValueManuallyChanged}
            fontSize={defaultFontSize}
          />
        </InputRow>
      ) : null}
      <InputRow label="Doc Fee">
        <Text variant="dealInfoBuyout">{formatMoney(values.financial_info?.doc_fee)}</Text>
      </InputRow>
      <HStack alignItems="start" mt={3}>
        {!isRefi && (inStructuring || inClosing) ? <CalcFeesButton /> : null}
      </HStack>
    </Flex>
  );
};
