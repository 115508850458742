import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdLogin, MdLogout, MdMenu, MdSearch } from 'react-icons/md';

import { useUpdateUserCallStatusMutation } from '../../gql/generated/graphql';

import SimpleNotification from '../NotificationBadge/SimpleNotification';
import DealSuggest from '../Suggest/DealSuggest';
import { UserStatusSwitcher } from '../UserStatusSwitcher/UserStatusSwitcher';
import Drawer from '../shared/Drawer';

import LeaseEndLogo from './LeaseEndLogo';
import { isActive } from './utils';

import { NavBarProps } from '.';
import config from '../../config';
import ROUTES from '../../constants/routes';

const MobileNavbar = ({
  buttons,
  currentPage,
  currentView,
  defaultView,
  handleNavBarButtonClick,
  isAuthenticated,
}: NavBarProps) => {
  const { user, loginWithRedirect, logout } = useAuth0();
  const [showDealSearch, setShowDealSearch] = useState(false);
  const viewsDrawer = useDisclosure();

  const [updateStatus] = useUpdateUserCallStatusMutation();

  const handleLogout = async () => {
    // Set user inactive on log out
    await updateStatus({ variables: { status: 'inactive' } });
    logout({
      logoutParams: {
        returnTo: config.auth0.logoutUri,
      },
    });
  };

  return (
    <Box w="full" overflowX="hidden">
      <Stack>
        <Stack direction="row" pt={1} pl={4} bgColor="white" justifyContent="space-between">
          <LeaseEndLogo
            onClick={(e) =>
              handleNavBarButtonClick(e, { page: ROUTES.DASHBOARD, view: defaultView })
            }
          />
          <Box>
            <IconButton
              aria-label="search-deals"
              variant="ghost"
              isActive={showDealSearch}
              icon={<MdSearch size="20px" />}
              onClick={() => setShowDealSearch(!showDealSearch)}
            />
            <IconButton
              aria-label="open-menu"
              variant="ghost"
              icon={<MdMenu size="20px" />}
              onClick={viewsDrawer.onOpen}
            />
          </Box>
        </Stack>
        <Flex bgColor="queenBlue" justify="center" py={2} hidden={!showDealSearch}>
          <DealSuggest />
        </Flex>
      </Stack>
      <Drawer
        title={
          <Flex align="center" w="full">
            <Text fontSize={16}>{user?.name}</Text>
            <Spacer />
            <UserStatusSwitcher isMobile isAuthenticated={isAuthenticated} />
          </Flex>
        }
        isOpen={viewsDrawer.isOpen}
        onClose={viewsDrawer.onClose}
      >
        <Flex flexDirection="column" h="100%">
          {buttons.map((button) => (
            <Button
              m={0}
              key={button.label}
              variant="navBar"
              w="full"
              size="md"
              borderColor="oxfordBlue"
              isActive={isActive({ button, currentPage, currentView })}
              borderTop="1px"
              onClick={(e) => {
                viewsDrawer.onClose();
                handleNavBarButtonClick(e, button);
              }}
              onAuxClick={(e) => {
                viewsDrawer.onClose();
                handleNavBarButtonClick(e, button);
              }}
            >
              {button.label}
              <SimpleNotification show={button.hasTextNotifications} />
            </Button>
          ))}
          <Spacer borderTop="1px" borderColor="queenBlue" bgColor="azureishWhite" flexGrow={1} />
          <Button
            justifySelf="flex-end"
            variant={isAuthenticated ? 'boot' : 'navBar'}
            borderRadius={0}
            w="full"
            m={0}
            onClick={async () => (isAuthenticated ? handleLogout() : loginWithRedirect())}
          >
            <HStack>
              <Text>{isAuthenticated ? 'Logout' : 'Login'}</Text>
              {isAuthenticated ? <MdLogout size="20px" /> : <MdLogin size="20px" />}
            </HStack>
          </Button>
        </Flex>
      </Drawer>
    </Box>
  );
};

export default MobileNavbar;
