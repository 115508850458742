import * as yup from 'yup';

import { getFullNameWithMiddle } from '../../../../gql/customerGql';
import { Deal } from '../../../../gql/dealGql';
import { DealMediaTypeEnum } from '../../../../gql/generated/graphql';

import { formatDateTimeISOWithUTC } from '../../../../libs/utils';
import { validateDateIsAfter, validateFuture } from '../../../../libs/yup-validators/dates';
import {
  DealMediaEdit,
  DriverLicenseMetadataValueFields,
  InsuranceCardMetadataValueFields,
  RegistrationMetadataValueFields,
} from '../../../../types/media';
import { removeWhiteSpacesAndLowercase } from '../../../../utils/text';
import { zipRegExp } from '../../../../utils/validation/address';

export const stringDoesNotMatchMessage = 'String does not match value';
export const enterValidZipMessage = 'Please enter a valid Zip';

const yupStringMatchesValue = (
  value?: string | null,
  required = true,
  customFirstValidation?: yup.AnySchema,
) => {
  const baseValidation = (customFirstValidation ?? yup.string()).test(
    'stringMatchesValue',
    stringDoesNotMatchMessage,
    (fieldString) =>
      removeWhiteSpacesAndLowercase(fieldString) === removeWhiteSpacesAndLowercase(value),
  );

  return required ? baseValidation.required() : baseValidation;
};

export const dateDoesNotMatchMessage = 'Date does not match value';

const yupFutureDate = () =>
  yup.date().required().test('expired', 'Date is in the past', validateFuture);

const yupDateIsAfter = (days = 0) =>
  yup
    .date()
    .required()
    .test('dateIsAfter', `Date is not ${days} days in the future`, (value) =>
      validateDateIsAfter(value, days),
    );

const dateMatchesValue = (
  value?: string | null,
  required = true,
  customFirstValidation?: yup.AnySchema,
) => {
  const baseValidation = (customFirstValidation ?? yup.string()).test(
    'dateMatchesValue',
    dateDoesNotMatchMessage,
    (fieldDate) => {
      if (!fieldDate) {
        return true;
      }

      return formatDateTimeISOWithUTC(fieldDate) === value;
    },
  );

  return required ? baseValidation.required() : baseValidation;
};

export const getValidationSchema = (
  deal: Deal,
  dealMedia: DealMediaEdit,
  requiredDriverLicenseFields?: Map<DriverLicenseMetadataValueFields, boolean>,
  requiredInsuranceCardFields?: Map<InsuranceCardMetadataValueFields, boolean>,
  requiredRegistrationFields?: Map<RegistrationMetadataValueFields, boolean>,
) => {
  if (dealMedia.type === DealMediaTypeEnum.FrontOfDriversLicense) {
    const metadata: {
      [key in DriverLicenseMetadataValueFields]?: yup.AnySchema;
    } = {
      name: requiredDriverLicenseFields?.has('name')
        ? yupStringMatchesValue(getFullNameWithMiddle(deal.customer))
        : undefined,
      state:
        requiredDriverLicenseFields?.has('state') || requiredDriverLicenseFields?.has('address')
          ? yupStringMatchesValue(deal.customer?.address?.state)
          : undefined,
      address: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(deal.customer?.address?.address_line)
        : undefined,
      address2: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(deal.customer?.address?.address_line_2, false)
        : undefined,
      city: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(deal.customer?.address?.city)
        : undefined,
      zip: requiredDriverLicenseFields?.has('address')
        ? yupStringMatchesValue(
            deal.customer?.address?.zip,
            true,
            yup.string().matches(zipRegExp, {
              message: enterValidZipMessage,
            }),
          )
        : undefined,
      expirationDate: requiredDriverLicenseFields?.has('expirationDate')
        ? yupFutureDate()
        : undefined,
    };

    return yup.object().shape({ metadata: yup.object().shape(metadata) });
  }

  if (dealMedia.type === DealMediaTypeEnum.FrontOfInsuranceCard) {
    const metadata: {
      [key in InsuranceCardMetadataValueFields]?: yup.AnySchema;
    } = {
      name: requiredInsuranceCardFields?.has('name')
        ? yupStringMatchesValue(getFullNameWithMiddle(deal.customer))
        : undefined,
      state: requiredInsuranceCardFields?.has('state')
        ? yupStringMatchesValue(deal.customer?.address?.state)
        : undefined,
      vin: requiredInsuranceCardFields?.has('vin')
        ? yupStringMatchesValue(deal.car.vin)
        : undefined,
      expirationDate: requiredInsuranceCardFields?.has('expirationDate')
        ? dateMatchesValue(deal.customer?.proof_of_insurance?.expires, true, yupFutureDate())
        : undefined,
      insuranceCompany: requiredInsuranceCardFields?.has('insuranceCompany')
        ? yupStringMatchesValue(deal.customer?.proof_of_insurance?.company_name)
        : undefined,
      policyNo: requiredInsuranceCardFields?.has('policyNo')
        ? yupStringMatchesValue(deal.customer?.proof_of_insurance?.policy_number)
        : undefined,
    };

    return yup.object().shape({ metadata: yup.object().shape(metadata) });
  }

  if (dealMedia.type === DealMediaTypeEnum.Registration) {
    const formattedRegistrationExpiration = deal.car.registration_expiration
      ? formatDateTimeISOWithUTC(deal.car.registration_expiration)
      : '';

    const metadata: {
      [key in RegistrationMetadataValueFields]?: yup.AnySchema;
    } = {
      expirationDate: requiredRegistrationFields?.has('expirationDate')
        ? dateMatchesValue(formattedRegistrationExpiration, true, yupFutureDate())
        : undefined,
      expirationDate60Days: requiredRegistrationFields?.has('expirationDate60Days')
        ? dateMatchesValue(formattedRegistrationExpiration, true, yupDateIsAfter(60))
        : undefined,
      expirationDate90Days: requiredRegistrationFields?.has('expirationDate90Days')
        ? dateMatchesValue(formattedRegistrationExpiration, true, yupDateIsAfter(90))
        : undefined,
    };

    return yup.object().shape({ metadata: yup.object().shape(metadata) });
  }

  return yup.object().shape({});
};
