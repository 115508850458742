import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';

import {
  DealStateEnum,
  DuplicatedInfo,
  useOnDuplicatedInfoSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const DuplicatedInfoHandler = () => {
  const [deals, setDeals] = useState<DuplicatedInfo[]>([]);

  useOnDuplicatedInfoSubscription({
    onData: ({ data }) => {
      if (!data.data?.onDuplicatedInfo) {
        return;
      }

      const newDeal = data.data.onDuplicatedInfo;

      if (!deals.some((deal) => deal.deal_id === newDeal.deal_id)) {
        setDeals([...deals, newDeal]);
      }
    },
  });

  return (
    <>
      {deals.map((d) => (
        <NotificationToast
          key={`duplicated-deal-${d.deal_id}`}
          dealId={d.deal_id ?? null}
          slideFadeIn={!!d.first_name}
          title={`${d.first_name} ${d.last_name} - Deal ${d.deal_id}`}
          titleIcon={FaRegCopy}
          titleIconColor="green"
          subTitle={
            d.state === DealStateEnum.Booted
              ? 'Matching web app detected for your booted deal: The deal has been revived, ' +
                'please review.'
              : 'Matching web app detected: Review data'
          }
          details={
            <Text>
              {d.state === DealStateEnum.Booted
                ? 'An abandoned web app matching one of your booted deals has been detected. ' +
                  'That booted deal has been revived. Please review the data and reach out to ' +
                  'the customer if they are showing renewed interest.'
                : 'An abandoned web app that matches this deal was detected. Please review the ' +
                  'pinned note in the deal for any missing data.'}
            </Text>
          }
          onClose={() => setDeals(deals.filter((p) => p.deal_id !== d.deal_id))}
        />
      ))}
    </>
  );
};

export default DuplicatedInfoHandler;
