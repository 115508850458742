import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { Deal } from '../gql/dealGql';

export const NewEstimateContext = createContext<{
  // TODO: The New Estimates page is pretty messy. Add more things here to reduce prop drilling.
  duplicateDeals?: Deal[];
  showPayoffRequestButton?: boolean;
  setDuplicateDeals?: Dispatch<SetStateAction<Deal[]>>;
  setShowPayoffRequestButton?: Dispatch<SetStateAction<boolean>>;
  duplicateDealsModal?: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}>({
  // Initial values are undefined so we can check `setDuplicateDeals` in `RequestPayoffButton` and know when is a new estimate that needs to check duplicates.
  // Probably need a refactor. This is a fix for now.
  duplicateDeals: undefined,
  showPayoffRequestButton: undefined,
  setDuplicateDeals: undefined,
  setShowPayoffRequestButton: undefined,
  duplicateDealsModal: undefined,
});

export const NewEstimateProvider = ({ children }: { children: ReactNode }) => {
  // New Estimate Context Values
  const [duplicateDeals, setDuplicateDeals] = useState<Deal[]>([]);
  const [showPayoffRequestButton, setShowPayoffRequestButton] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const newEstimateContextValue = {
    duplicateDeals,
    showPayoffRequestButton,
    setDuplicateDeals,
    setShowPayoffRequestButton,
    duplicateDealsModal: { isOpen, onClose, onOpen },
  };
  return (
    <NewEstimateContext.Provider value={newEstimateContextValue}>
      {children}
    </NewEstimateContext.Provider>
  );
};
