import { IconButton } from '@chakra-ui/react';
import { MdBuild } from 'react-icons/md';

import { SOFT_CLOSE } from '../../gql/dealGql';
import { useCreateDdDevDealMutation } from '../../gql/generated/graphql';

import Tooltip from '../shared/Tooltip';

type DevBuildDealButtonProps = {
  lane: string;
};
const DevBuildDealButton = ({ lane }: DevBuildDealButtonProps) => {
  const [buildDeal, { loading }] = useCreateDdDevDealMutation();
  if (lane !== SOFT_CLOSE) {
    // Dev tool only works for Soft Close lane
    return null;
  }
  return (
    <Tooltip
      label={`Build Dev Deal in ${lane}`}
      aria-label="Build Dev Deal"
      bgColor="cornflowerblue"
      placement="top"
    >
      <IconButton
        aria-label="Build Dev Deal"
        icon={<MdBuild />}
        variant="secondary"
        isRound
        size="xs"
        isLoading={loading}
        onClick={() => buildDeal()}
      />
    </Tooltip>
  );
};

export default DevBuildDealButton;
