import { Button } from '@chakra-ui/react';
import { IoSparkles } from 'react-icons/io5';

type SpudacusSuggestButtonProps = {
  isComposingMessage: boolean;
  isLoading: boolean;
  onClick: () => void;
};

const SpudacusSuggestButton = ({
  isComposingMessage,
  isLoading,
  onClick,
}: SpudacusSuggestButtonProps) => (
  <Button
    variant="smWithIconLeft"
    bgColor="green"
    color="oxfordBlue"
    size="sm"
    onClick={onClick}
    isLoading={isLoading}
    leftIcon={<IoSparkles />}
    _hover={{ opacity: 0.9 }}
  >
    {isComposingMessage ? 'AI Review' : 'AI Generate'}
  </Button>
);

export default SpudacusSuggestButton;
