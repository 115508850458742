import { useContext, useEffect, useRef, useState } from 'react';

import { useSubscription } from '@apollo/client';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { MdChatBubble, MdPhone } from 'react-icons/md';

import { CustomerContactTypeEnum } from '../../gql/customerGql';
import { DealStateEnum } from '../../gql/dealGql';
import {
  FollowUp,
  Maybe,
  StructuringFollowUp,
  useFollowUpQuery,
  useOnDealUpdateSubscription,
  useOnFollowUpUpdateSubscription,
  useOnStructuringFollowUpUpdateSubscription,
  useStructuringFollowUpQuery,
} from '../../gql/generated/graphql';
import { onNewMessage } from '../../gql/messageGql';

import CallWidget from '../CallWidget/CallWidget';
import AddFollowUp from '../FollowUp/AddFollowUp';
import EditFollowUp from '../FollowUp/EditFollowUp';
import NotificationsAvatar from '../NotificationBadge/NotificationAvatar';
import AddStructuringFollowUp from '../StructuringFollowUp/AddStructuringFollowUp';
import EditStructuringFollowUp from '../StructuringFollowUp/EditStructuringFollowUp';

import { notificationMessageMap } from '../../constants/notifications';
import { PermissionEnum } from '../../constants/permissions';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { AbilityContext, TextWidgetContext } from '../../libs/contextLib';
import { formatPhone } from '../../libs/utils';

const CustomerContact = () => {
  const { deal, dispatch } = useContext(DealContext);
  const { setActiveConversation, setTextDealId, unreadCount, setUnreadCount } =
    useContext(TextWidgetContext);
  const abilities = useContext(AbilityContext);

  const callWidgetRef = useRef<HTMLDivElement>(null);
  const [callWidgetOpen, setCallWidgetOpen] = useState<boolean>(false);
  const [followUp, setFollowUp] = useState<Maybe<FollowUp>>();
  const [structuringFollowUp, setStructuringFollowUp] = useState<Maybe<StructuringFollowUp>>();

  const canSeeStructuringQueue =
    [
      DealStateEnum.Estimate,
      DealStateEnum.SoftClose,
      DealStateEnum.Structuring,
      DealStateEnum.StructuringInProgress,
    ].includes(deal.state) && abilities.has(PermissionEnum.SeeStructuringQueue);

  useFollowUpQuery({
    skip: !deal.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { deal_id: deal.id! },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setFollowUp(data.followUp);
    },
  });

  useStructuringFollowUpQuery({
    skip: !canSeeStructuringQueue || !deal.id,
    variables: { dealId: deal.id ?? 0 },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setStructuringFollowUp(data.structuringFollowUp);
    },
  });

  useEffect(() => {
    dispatch({
      type: DealActionsEnum.UpdateDeal,
      payload: {
        follow_up: followUp,
      },
    });
  }, [followUp]);

  const dealPhoneNumbers = [formatPhone(deal.customer.phone_number)];
  if (deal.cobuyer) {
    dealPhoneNumbers.push(formatPhone(deal.cobuyer.phone_number));
  }
  if (deal.contact && deal.contact.phone_number) {
    dealPhoneNumbers.push(formatPhone(deal.contact.phone_number));
  }
  if (deal.second_contact && deal.second_contact.phone_number) {
    dealPhoneNumbers.push(formatPhone(deal.second_contact.phone_number));
  }

  const messagesSubGql = useSubscription(onNewMessage, {
    variables: {
      dealPhoneNumbers,
    },
    skip: !deal.customer.phone_number,
  });

  useOnDealUpdateSubscription({
    variables: { sources: [deal.source] },
    onData: ({ data }) => {
      if (!data.data?.onDealUpdate?.length) {
        return;
      }

      const filteredDeals = data.data.onDealUpdate.filter((d) => d?.id === deal.id);

      if (filteredDeals.length) {
        setUnreadCount(
          filteredDeals?.[0]?.notifications?.filter(
            (n) => n?.message === notificationMessageMap.MESSAGE_RECEIVED && n.seen === false,
          ).length ?? 0,
        );
      }
    },
  });

  useOnFollowUpUpdateSubscription({
    variables: { dealId: deal.id ?? -1 },
    onData: ({ data: { data } }) => {
      if (!data?.onFollowUpUpdate?.[0]) {
        return;
      }
      setFollowUp(data.onFollowUpUpdate[0].follow_up);
    },
  });

  useOnStructuringFollowUpUpdateSubscription({
    skip: !canSeeStructuringQueue || !deal.id,
    variables: { dealId: deal.id ?? 0 },
    onData: ({ data: { data } }) => {
      setStructuringFollowUp(data?.onStructuringFollowUpUpdate?.[0]?.structuring_follow_up);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (callWidgetRef.current && !callWidgetRef.current.contains(event.target)) {
        setCallWidgetOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callWidgetRef]);

  useEffect(() => {
    if (messagesSubGql.data) {
      setUnreadCount(unreadCount + 1);
    }
  }, [messagesSubGql.data]);

  useEffect(() => {
    if (deal.notifications) {
      setUnreadCount(
        deal.notifications.filter(
          (n) => n.message === notificationMessageMap.MESSAGE_RECEIVED && n.seen === false,
        ).length,
      );
    }
  }, [deal.notifications]);

  const callCustomer = () => {
    setCallWidgetOpen(!callWidgetOpen);
  };

  return (
    <Flex
      borderTopColor="gray.100"
      borderTopWidth="2px"
      gap={4}
      pt="1rem"
      justify="center"
      wrap="wrap"
    >
      <Box position="relative">
        <IconButton
          aria-label="set active conversation"
          rounded="full"
          size="lg"
          variant="secondary"
          onClick={() => {
            setActiveConversation({
              role: CustomerContactTypeEnum.Customer,
              phone_number: deal.customer.phone_number,
              first_name: deal.customer.first_name,
              last_name: deal.customer.last_name,
            });
            if (deal.id) {
              setTextDealId(deal.id);
            }
          }}
          icon={<MdChatBubble size={24} />}
        />
        <NotificationsAvatar
          count={unreadCount}
          maxDisplayCount={9}
          size="xs"
          pos="absolute"
          top={-1.5}
          right={-1.5}
        />
      </Box>

      <Box
        ref={callWidgetRef}
        alignItems="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        pos="relative"
      >
        <Box pos="relative">
          <IconButton
            aria-label="call customer"
            rounded="full"
            size="lg"
            variant="secondary"
            onClick={callCustomer}
            icon={<MdPhone size={24} />}
          />
        </Box>
        <CallWidget widgetOpen={callWidgetOpen} toggleCallWidget={callCustomer} />
      </Box>
      {followUp ? <EditFollowUp followUp={followUp} /> : <AddFollowUp />}

      {canSeeStructuringQueue ? (
        structuringFollowUp ? (
          <EditStructuringFollowUp structuringFollowUp={structuringFollowUp} />
        ) : (
          <AddStructuringFollowUp />
        )
      ) : null}
    </Flex>
  );
};

export default CustomerContact;
