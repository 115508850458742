import { Box } from '@chakra-ui/react';

type SimpleNotificationProps = {
  show?: boolean;
};

const SimpleNotification = ({ show }: SimpleNotificationProps) => {
  return show ? (
    <Box pl={1}>
      <Box borderRadius="full" boxSize="12px" bg="green" mb="2px" />
    </Box>
  ) : null;
};

export default SimpleNotification;
