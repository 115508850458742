import * as Yup from 'yup';

import { RemoveIndex } from '../types';

import { optionalStringValidation, requiredStringValidation } from './strings';

export const followUpValidationSchema = Yup.object().shape({
  date: Yup.date().nullable().required('Date is required'),
  time: Yup.date().nullable().required('Time is required'),
  type: requiredStringValidation('Type'),
  note: optionalStringValidation,
});

export type FollowUpFormType = Partial<RemoveIndex<Yup.InferType<typeof followUpValidationSchema>>>;
