import { object } from 'yup';
import { SchemaLike } from 'yup/lib/types';

import { numberOptionalValidation, numberRequiredValidation } from './number';
import { optionalStringValidation, requiredStringValidation } from './strings';

export const zipRegExp = /^(\d{5}(?:-\d{4})??)$/;
export const requirePreviousAddress = (yearsAtHome: number | undefined | null = null) =>
  yearsAtHome !== null && yearsAtHome < 2;
export const preAddressReq = (then: SchemaLike) =>
  object()
    .nullable()
    .when('address.years_at_home', {
      is: (years: number) => requirePreviousAddress(years),
      then,
    });

export const zipValidationOptional = (person?: string) =>
  optionalStringValidation.matches(zipRegExp, `Please enter a valid ${person || ''}zip code`);

export const zipValidationRequired = (person?: string) =>
  requiredStringValidation(`${person || ''}Zip Code`).matches(
    zipRegExp,
    `Please enter a valid ${person || ''}zip code`,
  );
export const cityValidation = (person?: string) => requiredStringValidation(`${person || ''}City`);
export const countyValidation = (person?: string) =>
  requiredStringValidation(`${person || ''}County`);

export const stateValidation = (person?: string, isRequired = true) =>
  (isRequired ? requiredStringValidation(`${person || ''}State`) : optionalStringValidation).test(
    'customer.address.state-select-state',
    `${person || ''}State is required`,
    (value) => {
      if (value === 'State') {
        return false;
      }
      return true;
    },
  );

export const personAddressValidation = (person?: string) => ({
  address_line: requiredStringValidation(`${person || ''}Address`),
  address_line_2: optionalStringValidation,
  zip: zipValidationRequired(person),
  city: cityValidation(person),
  state: stateValidation(person),
  county: countyValidation(person),
});

const personAddressDetailsValidation = (person?: string, monthlyPayment?: boolean) => ({
  monthly_payment: monthlyPayment
    ? numberRequiredValidation(`${person || ''}Monthly Payment`)
    : numberOptionalValidation,
  years_at_home: numberRequiredValidation(`${person || ''}Years at home`),
  months_at_home: numberRequiredValidation(`${person || ''}Months at home`),
});

export const fullAddressValidation = (person?: string, monthlyPayment?: boolean) =>
  object().shape({
    ...personAddressValidation(person),
    ...personAddressDetailsValidation(person, monthlyPayment),
  });
export const previousAddressValidation = (person?: string) =>
  preAddressReq(fullAddressValidation(person).nullable());
