import { MouseEvent, useState } from 'react';

import { IconButton, Spacer } from '@chakra-ui/react';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

import { useCreateSsnLogMutation } from '../../gql/generated/graphql';

import { useUser } from '../../hooks/useUser';

type MaskedSsnButtonProps = {
  type: 'form' | 'payoff' | 'customerInfo';
  onClick: (value: boolean) => void;
  customerId?: number;
  tempInfoId?: number | null;
  originalLesseeId?: number | null;
  visible?: boolean;
};

const MaskedSsnButton = ({
  type,
  onClick,
  customerId,
  tempInfoId,
  originalLesseeId,
  visible,
}: MaskedSsnButtonProps) => {
  const user = useUser();
  const [isHidden, setIsHidden] = useState(true);
  const [createSsnLog] = useCreateSsnLogMutation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isHidden) {
      createSsnLog({
        variables: {
          log: {
            user_id: user.id,
            customer_id: parseInt(customerId as unknown as string, 10) || null,
            temp_info_id: tempInfoId || null,
            original_lessee_id: originalLesseeId || null,
          },
        },
      });
    }
    setIsHidden(!isHidden);
    onClick(!isHidden);
  };
  return (
    <>
      {type === 'form' && <Spacer />}
      <IconButton
        variant="iconHover"
        size="xs"
        height="100%"
        icon={isHidden ? <MdOutlineVisibility size={15} /> : <MdOutlineVisibilityOff size={15} />}
        visibility={type === 'payoff' && !visible ? 'hidden' : 'visible'}
        onClick={handleClick}
        aria-label="Show/Hide SSN"
      />
      {type !== 'form' && <Spacer />}
    </>
  );
};

export default MaskedSsnButton;
