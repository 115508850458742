import { useContext, useEffect, useMemo } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, Icon } from '@chakra-ui/react';
import Big from 'big.js';
import { useFormikContext } from 'formik';
import { AiFillCalculator } from 'react-icons/ai';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Deal } from '../../../../gql/dealGql';
import { FinancialInfo, getTotalTaxes } from '../../../../gql/financialInfoGql';
import { TtGetFeesSourceType, useGetFeesLazyQuery } from '../../../../gql/generated/graphql';

import Tooltip from '../../../shared/Tooltip';

import { PermissionEnum } from '../../../../constants/permissions';
import { STATES_TAXED_ON_WARRANTY } from '../../../../constants/states';
import { Can } from '../../../../libs/Can';
import { DealContext } from '../../../../libs/DealContext';
import { isNumber } from '../../../../libs/utils';
import { mapDealToGetTTFeesVariables } from '../../../../utils/deals';
import {
  cityValidation,
  countyValidation,
  stateValidation,
  zipValidationRequired,
} from '../../../../utils/validation/address';
import { personNameValidation } from '../../../../utils/validation/personInfo';
import { fullSsnValidation } from '../../../../utils/validation/ssn';

const CalcFeesButton = () => {
  const { deal } = useContext(DealContext);

  const { values, setFieldValue, setFieldTouched } = useFormikContext<Deal>();

  const { feesParamsAreValid, feesParamsErrors } = useMemo((): {
    feesParamsAreValid: boolean;
    feesParamsErrors: string[];
  } => {
    const getFeesValidationSchema = Yup.object().shape({
      customer: Yup.object().shape({
        ...personNameValidation(),
        ssn: fullSsnValidation(),
        address: Yup.object().shape({
          zip: zipValidationRequired(),
          state: stateValidation(),
          county: countyValidation(),
          city: cityValidation(),
        }),
      }),
      car: Yup.object().shape({
        vin: Yup.string().required('VIN is required'),
        year: Yup.number().required('Car Year is required'),
        make: Yup.string().required('Make is required'),
        model: Yup.string().required('Model is required'),
        vehicle_type: Yup.string().nullable().required('Vehicle Type is required'),
        fuel_type: Yup.string().nullable(),
        retail_book_value: Yup.number().required('KBB Retail is required'),
        payoff: Yup.object().shape({
          vehicle_payoff: Yup.number().required('Payoff is required'),
        }),
      }),
      financial_info: Yup.object().shape({
        vsc_price: Yup.number().nullable().required('VSC Price is required'),
        doc_fee: Yup.number().required('Doc Fee is required'),
      }),
    });

    try {
      getFeesValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return {
        feesParamsAreValid: true,
        feesParamsErrors: [],
      };
    } catch (error) {
      const yupError = error as Yup.ValidationError;
      return {
        feesParamsAreValid: false,
        feesParamsErrors: yupError.errors as string[],
      };
    }
  }, [values.customer, values.car, values.financial_info]);

  // https://github.com/formium/formik/issues/2059
  const updateField = (name: string, value: unknown) => {
    setFieldValue(name, value);
    setTimeout(() => setFieldTouched(name, true));
  };

  const updateFieldsAndShowToast = ({
    sales_tax_rate,
    base_tax_amount,
    warranty_tax_amount,
    new_registration_fee,
    registration_transfer_fee,
  }: Partial<FinancialInfo>) => {
    updateField('financial_info.sales_tax_rate', sales_tax_rate);
    updateField('financial_info.base_tax_amount', base_tax_amount);
    updateField('financial_info.warranty_tax_amount', warranty_tax_amount);
    updateField('financial_info.new_registration_fee', new_registration_fee);
    updateField('financial_info.registration_transfer_fee', registration_transfer_fee);

    updateField(
      'financial_info.totalTaxAmount',
      getTotalTaxes({
        option_type: values.financial_info.option_type,
        base_tax_amount,
        warranty_tax_amount,
      } as FinancialInfo),
    );
    updateField('financial_info.feesQueryFailed', false);

    toast.success('Success retrieving fees');
  };

  const resetTaxesAndEnableManualEdition = () => {
    setFieldValue('financial_info.sales_tax_rate', 0);
    setFieldValue('financial_info.base_tax_amount', 0);
    setFieldValue('financial_info.warranty_tax_amount', 0);
    setFieldValue('financial_info.new_registration_fee', 0);
    setFieldValue('financial_info.registration_transfer_fee', 0);

    setFieldValue('financial_info.totalTaxAmount', 0);
    setFieldValue('financial_info.feesQueryFailed', true);
  };

  const handleFeesError = (error: ApolloError) => {
    resetTaxesAndEnableManualEdition();
    toast.error(error?.message || 'Failed to retrieve fees');
  };

  const [getTTFees, { loading: loadingTTFees }] = useGetFeesLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.getFees) {
        updateFieldsAndShowToast({
          sales_tax_rate: data.getFees.salesTaxRate ?? 0,
          base_tax_amount: data.getFees.baseTaxAmount ?? 0,
          warranty_tax_amount: data.getFees.warrantyTaxAmount ?? 0,
          new_registration_fee: data.getFees.totalFeeAmount ?? 0,
          registration_transfer_fee: data.getFees.registrationTransferFee ?? 0,
        });
      }
    },
    onError: (error) => {
      handleFeesError(error);
    },
  });

  useEffect(() => {
    if (
      STATES_TAXED_ON_WARRANTY.includes(values.customer?.address?.state ?? '') &&
      values.financial_info.sales_tax_rate &&
      isNumber(values.financial_info.vsc_price)
    ) {
      const warrantyTaxAmount = new Big(values.financial_info.vsc_price as number)
        .times(values.financial_info.sales_tax_rate)
        .toNumber();
      if (warrantyTaxAmount !== deal?.financial_info?.warranty_tax_amount) {
        updateField('financial_info.warranty_tax_amount', warrantyTaxAmount);
        updateField(
          'financial_info.totalTaxAmount',
          getTotalTaxes({
            option_type: values.financial_info.option_type,
            base_tax_amount: values.financial_info.base_tax_amount,
            warranty_tax_amount: warrantyTaxAmount,
          } as FinancialInfo),
        );
      }
    }
  }, [
    values.financial_info.vsc_price,
    values.financial_info.option_type,
    values.customer?.address?.state,
  ]);

  return (
    <Can I={PermissionEnum.CalculateFee}>
      <Tooltip errors={feesParamsErrors}>
        <Button
          variant="secondary"
          size="sm"
          leftIcon={<Icon as={AiFillCalculator} fontSize="md" />}
          isLoading={loadingTTFees}
          loadingText="CALC TAX & REG"
          isDisabled={!feesParamsAreValid}
          onClick={() => {
            const getTTFeesVariables = mapDealToGetTTFeesVariables(values, {
              ttEndpoint: 'getFees',
              ttGetFeesSource: TtGetFeesSourceType.DdCalcFees,
            });
            // Sending `financialInfoId` to save the fees in the DB.
            getTTFees({
              variables: { ...getTTFeesVariables, financialInfoId: values.financial_info?.id },
            });
          }}
        >
          CALC TAX & REG
        </Button>
      </Tooltip>
    </Can>
  );
};

export default CalcFeesButton;
