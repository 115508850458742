import cloneDeep from 'lodash/cloneDeep';

import {
  AddressTypeEnum,
  Customer,
  DealContact,
  EmploymentStatusType,
  EmploymentTypeType,
  OriginalLessee,
  RelationToBuyerEnum,
} from '../../gql/generated/graphql';

import { CreditAppOnDeal } from './useCreditAppInitialValues';
import { validationSchema } from './validationSchema';

import { emptyPhoneNumberMask, emptySsnFullMask, emptyZipMask } from '../../constants/masks';
import { isNumber, removeFailedValidation } from '../../libs/utils';
import { requirePreviousAddress } from '../../utils/validation/address';
import { requirePreviousEmployment } from '../../utils/validation/employment';

export const getEmptyContact = (dealId: number): CreditAppOnDeal['contact'] => ({
  deal_id: dealId,
  relation_to_buyer: RelationToBuyerEnum.ResidesWith,
  first_name: undefined,
  middle_name: undefined,
  last_name: undefined,
  phone_number: undefined,
  email: undefined,
});

export const getEmptyPerson = (): CreditAppOnDeal['customer'] => ({
  auth0_id: undefined,
  dob: undefined,
  ssn: undefined,
  first_name: undefined,
  middle_name: undefined,
  last_name: undefined,
  name_suffix: undefined,
  phone_number: undefined,
  home_phone_number: undefined,
  no_email: undefined,
  email: undefined,
  marital_status: undefined,
  relation_to_buyer: RelationToBuyerEnum.Spouse,
  has_same_address_as_cobuyer: undefined,
  wi_notification_agreement: undefined,
  address: {
    address_type: AddressTypeEnum.Current,
    address_line: undefined,
    address_line_2: undefined,
    city: undefined,
    county: undefined,
    state: undefined,
    zip: undefined,
    residence_type: undefined,
    monthly_payment: undefined,
    months_at_home: undefined,
    years_at_home: undefined,
  },
  employment: {
    employment_type: EmploymentTypeType.Current,
    status: EmploymentStatusType.Employed,
    name: undefined,
    job_title: undefined,
    phone_number: undefined,
    years_at_job: undefined,
    months_at_job: undefined,
    gross_income: undefined,
    pay_frequency: undefined,
  },
  proof_of_insurance: {
    company_name: undefined,
    policy_number: undefined,
    expires: undefined,
  },
});

export const getEmptyOriginalLessee = (): CreditAppOnDeal['original_lessee'] => ({
  first_name: undefined,
  last_name: undefined,
  phone_number: undefined,
  email: undefined,
  address_line: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  county: undefined,
  ssn: undefined,
});

type CleanPersonProps = {
  values: CreditAppOnDeal;
  isCobuyer?: boolean;
};

const cleanPerson = ({ isCobuyer, values }: CleanPersonProps) => {
  // Take out `wi_notification_agreement` from the person object
  const {
    address,
    ssn,
    phone_number,
    home_phone_number,
    employment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    wi_notification_agreement,
    ...person
  } = cloneDeep(isCobuyer && values.cobuyer ? values.cobuyer : values.customer);

  if (requirePreviousAddress(address?.years_at_home)) {
    // Make sure that the prev_address object exists when it should
    person.prev_address = {
      ...person.prev_address,
      address_type: AddressTypeEnum.Previous,
    };
  }

  const { years_at_job, months_at_job, gross_income } = employment ?? {};
  if (requirePreviousEmployment(years_at_job)) {
    // Make sure that the prev_employment object exists when it should
    person.prev_employment = {
      ...person.prev_employment,
      employment_type: EmploymentTypeType.Previous,
    };
  }

  const cleanedCustomer: Customer = {
    ...person,
    first_name: person.first_name?.trim() ?? '',
    middle_name: person.middle_name?.trim() ?? '',
    last_name: person.last_name?.trim() ?? '',
    ssn: ssn === emptySsnFullMask ? '' : ssn,
    phone_number: phone_number === emptyPhoneNumberMask ? '' : phone_number,
    home_phone_number: home_phone_number === emptyPhoneNumberMask ? '' : home_phone_number,
    address: {
      ...address,
      zip: !address?.zip || address.zip === emptyZipMask ? '' : address.zip,
    },
    employment: {
      ...employment,
      years_at_job: isNumber(years_at_job) ? years_at_job : undefined,
      months_at_job: isNumber(months_at_job) ? months_at_job : undefined,
      gross_income: isNumber(gross_income) ? gross_income : undefined,
      phone_number:
        employment?.phone_number === emptyPhoneNumberMask ? '' : employment?.phone_number,
    },
  };

  if (!requirePreviousAddress(cleanedCustomer.address?.years_at_home)) {
    delete cleanedCustomer.prev_address;
  }

  if (!requirePreviousEmployment(cleanedCustomer.employment?.years_at_job)) {
    delete cleanedCustomer.prev_employment;
  }

  return cleanedCustomer;
};

type CleanContactProps = {
  contact: NonNullable<CreditAppOnDeal['contact']>;
};

const cleanContact = ({ contact }: CleanContactProps) => {
  const { phone_number, ...rest } = cloneDeep(contact);
  const cleanedContact: DealContact = {
    ...rest,
    phone_number: phone_number === emptyPhoneNumberMask ? '' : phone_number,
  };

  return cleanedContact;
};

type CleanOriginalLesseeProps = {
  original_lessee: NonNullable<CreditAppOnDeal['original_lessee']>;
};

const cleanOriginalLessee = ({ original_lessee }: CleanOriginalLesseeProps) => {
  const { phone_number, ssn, ...rest } = cloneDeep(original_lessee);
  const cleanedOriginalLessee: OriginalLessee = {
    ...rest,
    phone_number: phone_number === emptyPhoneNumberMask ? '' : phone_number,
    ssn: ssn === emptySsnFullMask ? '' : ssn,
  };

  return cleanedOriginalLessee;
};

type CleanDealProps = {
  values: CreditAppOnDeal;
};

export const cleanCreditAppValues = ({ values }: CleanDealProps) => {
  const cleanedDeal = {
    id: values.id,
    // Cleaned Customer
    customer: cleanPerson({ values }),
    // Cleaned Financial Info
    financial_info: values.financial_info,
    // Cleaned Cobuyer
    ...(!!values.cobuyer &&
      values.addCobuyer && { cobuyer: cleanPerson({ values, isCobuyer: true }) }),
    // Cleaned Contact
    ...(!!values.contact &&
      values.addContact && { contact: cleanContact({ contact: values.contact }) }),
    // Cleaned Second Contact
    ...(!!values.second_contact &&
      values.addSecondContact && {
        second_contact: cleanContact({ contact: values.second_contact }),
      }),
    // Cleaned Original Lessee
    ...(!!values.original_lessee &&
      values.addOriginalLessee && {
        original_lessee: cleanOriginalLessee({ original_lessee: values.original_lessee }),
      }),
  };

  const valuesWithoutFailures = removeFailedValidation(cleanedDeal, validationSchema);
  return valuesWithoutFailures;
};
