import { object, string } from 'yup';

import { emailValidationRequired } from './email';
import { personNameValidation } from './personInfo';
import { phoneNumberValidationRequired } from './phoneNumber';

export const contactPersonValidation = (person?: string) =>
  object().shape({
    ...personNameValidation(person),
    phone_number: phoneNumberValidationRequired(person),
    email: emailValidationRequired(person),
    relation_to_buyer: string()
      .nullable()
      .required(`${person || ''}Relation To Buyer is required`),
  });
