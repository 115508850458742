import { useContext } from 'react';

import { Badge, Text, useDisclosure } from '@chakra-ui/react';

import { useOnTagsUpdatedSubscription } from '../../gql/generated/graphql';

import InfoCardV2 from '../InfoCard/InfoCardV2';

import EditTags from './EditTags';

import { DealActionsEnum, DealContext } from '../../libs/DealContext';

const TagInfo = () => {
  const { deal, dispatch } = useContext(DealContext);

  const disclosureProps = useDisclosure();

  useOnTagsUpdatedSubscription({
    skip: !deal.id,
    variables: { dealId: deal.id ?? -1 },
    onData: async ({ data: { data } }) => {
      if (!data?.onTagsUpdated) {
        return;
      }

      const updatedTags = data.onTagsUpdated.find(
        (updatedDeal) => Number(updatedDeal?.id) === Number(deal.id),
      )?.tags;

      dispatch({
        // Don't use `DeepUpdateDeal` here. It merges the arrays instead of replacing them.
        type: DealActionsEnum.UpdateDeal,
        payload: {
          tags: updatedTags ?? [],
        },
      });
    },
  });

  return (
    <>
      <InfoCardV2 name="Deal Tags" showEditable editAction={() => disclosureProps.onOpen()}>
        {!deal?.tags?.length ? (
          <Text>No Tags</Text>
        ) : (
          deal?.tags?.map((tag) => (
            <Badge
              key={tag.id}
              mx={1}
              rounded="md"
              textTransform="uppercase"
              bg={tag.color}
              color="white"
              fontSize={12}
            >
              {tag.display_name}
            </Badge>
          ))
        )}
      </InfoCardV2>
      <EditTags disclosureProps={disclosureProps} />
    </>
  );
};

export default TagInfo;
