import { boolean } from 'yup';

import {
  MaritalStatusEnum,
  RelationToBuyerEnum,
  StateAbbreviation,
} from '../../gql/generated/graphql';

import { conditionalEmailValidation } from './email';
import { conditionalPhoneNumberValidation, phoneNumberValidationOptional } from './phoneNumber';
import { fullSsnValidation } from './ssn';
import { nameValidation, optionalStringValidation } from './strings';

import { dobValidation } from '../../libs/yup-validators/dob';

export const personNameValidation = (person?: string) => ({
  first_name: nameValidation(person, 'First '),
  last_name: nameValidation(person, 'Last '),
});

const personContactValidation = (person?: string) => ({
  phone_number: conditionalPhoneNumberValidation(person),
  home_phone_number: phoneNumberValidationOptional(person),
  email: conditionalEmailValidation(person),
});

export const personInfoValidation = (person?: string) => ({
  ...personNameValidation(person),
  ...personContactValidation(person),
  ssn: fullSsnValidation(person),
  dob: dobValidation(person),
  marital_status: optionalStringValidation.test(
    'maritalStatusRequired',
    'Marital Status is required',
    (value, context) => {
      if (context?.parent?.address?.state === StateAbbreviation.Wi) {
        return !!value;
      }

      return true;
    },
  ),
  wi_notification_agreement: boolean().test(
    'cobuyer.wi_notification_agreement',
    'Cobuyer Credit Acknowledgement is required',
    function test(value) {
      const isCobuyer = person === 'Cobuyer ';
      if (!isCobuyer) {
        return true;
      }

      const { address, relation_to_buyer, marital_status } = this.options.context?.cobuyer || {};
      if (
        address?.state !== StateAbbreviation.Wi ||
        relation_to_buyer !== RelationToBuyerEnum.Spouse ||
        ![MaritalStatusEnum.Married, MaritalStatusEnum.Separated].includes(marital_status)
      ) {
        return true;
      }

      return !!value;
    },
  ),
});
