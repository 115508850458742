import { ChangeEvent, useState } from 'react';

import { useFormikContext } from 'formik';

import { AutoExplodeZip } from '../AutoExplodeZip';
import MaskedSsnInput from '../MaskedSsn/MaskedSsnInput';
import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import { GridFormColumn, GridFormRow } from '../shared/GridForm';
import Input from '../shared/Input';
import Select from '../shared/Select';

import { CreditAppOnDeal } from './useCreditAppInitialValues';

import { phoneNumberMask } from '../../constants/masks';
import SELECT_OPTIONS from '../../constants/selectOptions';

type OriginalLesseeProps = {
  customHandleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
};

const OriginalLesseeForm = ({ customHandleChange }: OriginalLesseeProps) => {
  const { handleBlur, values } = useFormikContext<CreditAppOnDeal>();
  const [isAddressLineFocused, setAddressLineFocused] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);

  return (
    <Card variant="rounded">
      <CardHeaderV2 title="Original Lessee" variant="square" />
      <GridFormColumn>
        <GridFormRow minChildWidth={270}>
          <Input
            label="First Name"
            name="original_lessee.first_name"
            onChange={customHandleChange}
          />
          <Input label="Last Name" name="original_lessee.last_name" onChange={customHandleChange} />
        </GridFormRow>
        <GridFormRow minChildWidth={270}>
          <Input
            label="Phone Number"
            name="original_lessee.phone_number"
            onChange={customHandleChange}
            mask={phoneNumberMask}
            copyButton
          />
          <Input
            label="Email"
            name="original_lessee.email"
            onChange={customHandleChange}
            copyButton
          />
        </GridFormRow>
        <GridFormRow minChildWidth={270}>
          <Input
            label="Address"
            name="original_lessee.address_line"
            onChange={customHandleChange}
            onFocus={() => setAddressLineFocused(true)}
            onBlur={(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
              setAddressLineFocused(false);
              handleBlur(e);
            }}
          />
          <MaskedSsnInput
            name="original_lessee.ssn"
            customHandleChange={customHandleChange}
            replaceFixedCharacters
            needsHidden={!!values.original_lessee?.ssn}
          />
        </GridFormRow>
        <GridFormRow minChildWidth={180}>
          <Input label="Zip" name="original_lessee.zip" onChange={customHandleChange} />
          <Input
            label="City"
            name="original_lessee.city"
            onChange={customHandleChange}
            isLoading={zipLoading}
          />
          <Select
            label="State"
            name="original_lessee.state"
            onChange={customHandleChange}
            options={SELECT_OPTIONS.US_STATES}
            isLoading={zipLoading}
          />
          <Input
            label="County"
            name="original_lessee.county"
            onChange={customHandleChange}
            isLoading={zipLoading}
          />
          <AutoExplodeZip
            name="original_lessee"
            isAddressLineFocused={isAddressLineFocused}
            setZipLoading={setZipLoading}
            isOriginalLessee
          />
        </GridFormRow>
      </GridFormColumn>
    </Card>
  );
};

export default OriginalLesseeForm;
