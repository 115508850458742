/* eslint-disable max-classes-per-file */
import { gql } from '@apollo/client/core';

import {
  Consent,
  DealType,
  DocumentProgressStatus,
  FollowUp,
  Maybe,
  NotificationSubscriber,
  OriginalLessee,
  Payoff,
  ReferralSource,
  Tag,
  TemporaryData,
} from './generated/graphql';

import { PayoffCheckInformationFormValues } from '../components/PayoffInfo/PayoffCheckInformationModal';
import { Option } from '../components/shared/types';
import { Notification } from '../constants/notifications';
import { DealDates } from '../types/dealDates';
import { User } from '../types/user';

import { Car } from './carGql';
import { ConditionReport } from './conditionReportGql';
import { Cobuyer, Customer } from './customerGql';
import { DealContact } from './dealContactGql';
import { ReferralSourceFragment } from './dealReferralSourceGql';
import { DealStates } from './dealStatesGql';
import { DocumentInfo } from './documentInfoGql';
import { FinancialInfoAcquisition } from './financialInfoAcquisitionGql';
import { FinancialInfo } from './financialInfoGql';
import { Note } from './noteGql';
import { Pod } from './podGql';

export enum DealSourceEnum {
  CallIn = 'call-in',
  D2D = 'd2d',
  Web = 'web',
  Outbound = 'outbound',
}

export const DealSourceLabelMap: {
  [key in DealSourceEnum]: string;
} = {
  [DealSourceEnum.CallIn]: 'Call-In',
  [DealSourceEnum.D2D]: 'D2D',
  [DealSourceEnum.Web]: 'Web',
  [DealSourceEnum.Outbound]: 'Outbound',
};

export const dealSourceOptions: Option[] = Object.values(DealSourceEnum).map((value) => ({
  label: DealSourceLabelMap[value],
  value,
}));

// This needs to stay in sync with the variable of the same name on the backend 👀
export enum DealStateEnum {
  // Setters/Closers
  Estimate = 'estimate',
  Floor = 'floor',
  SoftClose = 'soft_close',
  StructuringInProgress = 'structuring_in_progress',
  Structuring = 'structuring',
  Closing = 'closing',
  Closed = 'closed',

  // Funding/Accounting Clerks
  SentForSignatures = 'signatures',
  Signed = 'signed',
  Funded = 'funded',

  // Title Clerks
  SendPayoff = 'send_payoff',
  WaitingForTitle = 'waiting_for_title', // Now called Create Packet
  TitleReceived = 'title_received', // Now called Ready for Clerk
  SentToProcessor = 'sent_to_processor',

  // Logistics Coordinators
  ReadyForPickup = 'ready_for_pickup',
  PaidOff = 'paid_off',
  AtAuction = 'at_auction',
  Sold = 'sold',
  TitleSent = 'title_sent',

  // Dead Deals
  Booted = 'booted',
  Finalized = 'finalized',
}

// Setters/Closers
export const NEW_ESTIMATES = 'New Estimates';
export const ON_THE_FLOOR = 'On The Floor';
export const SOFT_CLOSE = 'Soft Close';
export const STRUCTURING = 'Structuring';
export const CLOSING = 'Closing';
export const CLOSED = 'Closed';

// Funding/Accounting Clerks
export const SIGNING = 'Signing';
export const SENT = 'Sent For Signatures';
export const SIGNED = 'Signed';
export const FUNDED = 'Funded';

// Title Clerks
export const SEND_PAYOFF = 'Send Payoff';
export const WAITING_FOR_TITLE = 'Waiting For Title';
export const TITLE_RECEIVED = 'Title Received';
export const SENT_TO_PROCESSOR = 'Sent To Processor';

// Logistics Coordinators
export const READY_FOR_PICKUP = 'Ready For Pickup';
export const PAID_OFF = 'Paid Off';
export const AT_AUCTION = 'At Auction';
export const SOLD = 'Sold';
export const TITLE_SENT = 'Title Sent';

// Dead Deals
export const BOOTED = 'Booted';
export const FINALIZED = 'Finalized';

export const KEY_METRICS = 'Key Metrics';

export const WEB_DEALS = 'Web Deals';
export const WEB_LEADS_SEARCH = 'Web Leads Search';

export const STRUCTURING_IN_PROGRESS_LABEL = 'Structuring in Progress';
export const WAITING_FOR_TITLE_LABEL = 'Create Packet';
export const TITLE_RECEIVED_LABEL = 'Ready for Clerk';
export const SENT_TO_PROCESSOR_LABEL = 'Sent to Processor/DMV';

export const DealStateLabelMap: Record<DealStateEnum, string> = {
  // Setters/Closers
  [DealStateEnum.Estimate]: NEW_ESTIMATES,
  [DealStateEnum.Floor]: ON_THE_FLOOR,
  [DealStateEnum.SoftClose]: SOFT_CLOSE,
  [DealStateEnum.StructuringInProgress]: STRUCTURING_IN_PROGRESS_LABEL,
  [DealStateEnum.Structuring]: STRUCTURING,
  [DealStateEnum.Closing]: CLOSING,
  [DealStateEnum.Closed]: CLOSED,

  // Funding/Accounting Clerks
  [DealStateEnum.SentForSignatures]: SENT,
  [DealStateEnum.Signed]: SIGNED,
  [DealStateEnum.Funded]: FUNDED,

  // Title Clerks
  [DealStateEnum.SendPayoff]: SEND_PAYOFF,
  [DealStateEnum.WaitingForTitle]: WAITING_FOR_TITLE_LABEL,
  [DealStateEnum.TitleReceived]: TITLE_RECEIVED_LABEL,
  [DealStateEnum.SentToProcessor]: SENT_TO_PROCESSOR_LABEL,

  // Logistics Coordinators
  [DealStateEnum.ReadyForPickup]: READY_FOR_PICKUP,
  [DealStateEnum.PaidOff]: PAID_OFF,
  [DealStateEnum.AtAuction]: AT_AUCTION,
  [DealStateEnum.Sold]: SOLD,
  [DealStateEnum.TitleSent]: TITLE_SENT,

  // Dead Deals
  [DealStateEnum.Booted]: BOOTED,
  [DealStateEnum.Finalized]: FINALIZED,
};

export const DealTypeLabelMap: Record<DealType, string> = {
  [DealType.Buyout]: 'Buyout',
  [DealType.Refi]: 'Refi',
  [DealType.Acquisition]: 'Acquisition',
};

export const dealTypeOptions: Option[] = Object.values(DealType).map((value) => ({
  label: DealTypeLabelMap[value],
  value,
}));

export enum BootReasonsEnum {
  BadContactInfo = 'Bad contact info',
  CashBuyer = 'Cash buyer',
  CustomerDoesNotQualify = 'Customer does not qualify',
  CustomerDoesNotWantToGiveInformation = 'Customer does not want to give information to proceed',
  CustomerWaitingEndOfLease = 'Customer waiting until end of lease',
  DirectPayState = 'Direct pay state',
  DocFeeTooHigh = 'Doc fee too high',
  DuplicateDeal = 'Duplicate deal',
  ExtendedLease = 'Extended Lease',
  LienholderNotApproved = 'Lienholder not approved',
  ManufacturerNotApproved = 'Manufacturer not approved',
  NoResponse = 'No response',
  PaymentOnApprovalTooHigh = 'Payment on approval too high',
  PayoffAmountTooHigh = 'Payoff amount too high for financing (car is too new)',
  QuoteTooHigh = 'Quote too high (monthly payment)',
  RateTooHigh = 'Rate too high',
  BoughtOutThroughDifferentSource = 'Bought out through different source',
  SalesTaxIssue = 'Sales Tax Issue',
  ThoughtWeWereManufacturer = 'Thought we were manufacturer',
  TurnedVehicleBackInToDealership = 'Turned vehicle back in to dealership',
  VehicleDoesNotQualify = 'Vehicle does not qualify for our financing (mileage too high/financing too low)',
  Unwound = 'Deal was unwound',
  Other = 'Other (please leave reason in notes)',
}

export class BootReason {
  reason: BootReasonsEnum;
  comment: string;
  author_id?: string;

  constructor(reason: BootReasonsEnum, comment = '', author_id = '') {
    this.reason = reason;
    this.comment = comment;
    this.author_id = author_id;
  }
}

export class Deal {
  id?: number;
  duplicated_from_deal_id?: number;
  type?: DealType;
  source?: string;
  state: DealStateEnum;
  setter_id?: string;
  closer_id?: string;
  closer2_id?: string;
  structuring_manager_id?: string;
  funding_clerk_id?: string;
  created_at?: string;
  creation_date_tz?: string;
  creation_date_utc?: string;
  set_date?: string;
  completion_date_utc?: string;
  completion_date_tz?: string;
  customer: Customer;
  cobuyer?: Customer | null;
  consents?: Consent[];
  contact?: DealContact | null;
  second_contact?: DealContact | null;
  original_lessee?: OriginalLessee | null;
  car: Car;
  financial_info: FinancialInfo;
  financial_info_acquisition: FinancialInfoAcquisition;
  setter?: User;
  closer?: User;
  closer2?: User;
  structuring_manager?: User;
  funding_clerk?: User;
  has_problem?: boolean;
  lane_arrival_utc?: string;
  default_sort_date?: string;
  notes: Note[];
  paperwork_type?: string;
  condition_report?: ConditionReport;
  request_boot: boolean;
  boot_reason?: BootReason;
  document_info?: DocumentInfo;
  deal_states: DealStates[];
  deal_dates?: DealDates;
  pod_id?: string;
  pod?: Pod;
  finalized_date?: string;
  sold_date?: string;
  tags?: Tag[];
  titling_pod_id?: string;
  titling_pod?: Pod;
  title_clerk_id?: string;
  title_clerk?: User;
  title_clerk2_id?: string;
  title_clerk2?: User;
  temporary_registration_tags_user_id?: string;
  temporary_registration_tags_user?: User;
  notifications?: Notification[];
  follow_up: Maybe<FollowUp>;

  r1_jacket_id?: string;
  r1_jacket_id_created_date_utc?: string;
  document_progress_status?: DocumentProgressStatus;
  r1_contract_validation_errors_object?: { errors: string[] };
  r1_contract_validation_warnings_object?: { warnings: string[] };
  r1_contract_worksheet_created_date_utc?: string;
  r1_contract_manually_validated?: boolean;

  signing_on_com?: boolean;
  called_by_financial_specialist?: string;
  needs_electronic_signature_verification?: boolean;

  referral_source?: ReferralSource;
  notification_subscribers?: NotificationSubscriber[];

  // eslint-disable-next-line no-undef, @typescript-eslint/no-explicit-any
  [x: string]: any;

  constructor() {
    this.type = DealType.Buyout;
    this.source = DealSourceEnum.CallIn;
    this.state = DealStateEnum.Estimate;
    this.customer = new Customer();
    this.cobuyer = new Cobuyer();
    this.car = new Car();
    this.financial_info = new FinancialInfo();
    this.financial_info_acquisition = new FinancialInfoAcquisition();
    this.notes = [];
    this.request_boot = false;
    this.deal_states = [];
    this.needs_electronic_signature_verification = false;
  }
}

export class CleanDeal {
  id?: number;
  type?: DealType;
  source?: string;
  state: DealStateEnum;
  setter_id?: string;
  closer_id?: string;
  closer2_id?: string;
  funding_clerk_id?: string;
  created_at?: string;
  creation_date_tz?: string;
  completion_date_utc?: string;
  completion_date_tz?: string;
  car: Car;
  financial_info: FinancialInfo;
  financial_info_acquisition: FinancialInfoAcquisition;
  lane_arrival_utc?: string;
  default_sort_date?: string;
  notes: Note[];
  paperwork_type?: string;
  condition_report?: ConditionReport;
  request_boot: boolean;
  boot_reason?: BootReason;
  document_info?: DocumentInfo;
  deal_states: DealStates[];
  deal_dates?: DealDates;
  pod?: Pod;
  finalized_date?: string;
  sold_date?: string;

  r1_jacket_id?: string;
  r1_jacket_id_created_date_utc?: string;
  document_progress_status?: DocumentProgressStatus;
  r1_contract_validation_errors_object?: { errors: string[] };
  r1_contract_validation_warnings_object?: { warnings: string[] };
  r1_contract_worksheet_created_date_utc?: string;
  r1_contract_manually_validated?: boolean;

  signing_on_com?: boolean;
  called_by_financial_specialist?: string;
  needs_electronic_signature_verification?: boolean;

  constructor(deal: Deal) {
    this.id = deal.id;
    this.type = deal.type;
    this.source = deal.source;
    this.state = deal.state;
    this.setter_id = deal.setter_id;
    this.closer_id = deal.closer_id;
    this.closer2_id = deal.closer2_id;
    this.funding_clerk_id = deal.funding_clerk_id;
    this.creation_date_tz = deal.creation_date_tz;
    this.created_at = deal.created_at;
    this.completion_date_tz = deal.completion_date_tz;
    this.completion_date_utc = deal.completion_date_utc;
    this.car = deal.car;
    this.financial_info = deal.financial_info;
    this.financial_info_acquisition = deal.financial_info_acquisition;
    this.lane_arrival_utc = deal.lane_arrival_utc;
    this.default_sort_date = deal.default_sort_date;
    this.notes = deal.notes;
    this.paperwork_type = deal.paperwork_type;
    this.condition_report = deal.condition_report;
    this.request_boot = deal.request_boot;
    this.boot_reason = deal.boot_reason;
    this.document_info = deal.document_info;
    this.deal_states = deal.deal_states;
    this.pod = deal.pod;
    this.finalized_date = deal.finalized_date;
    this.sold_date = deal.sold_date;
    this.deal_dates = deal.deal_dates;

    this.r1_jacket_id = deal.r1_jacket_id;
    this.r1_jacket_id_created_date_utc = deal.r1_jacket_id_created_date_utc;
    this.document_progress_status = deal.document_progress_status;
    this.r1_contract_validation_errors_object = deal.r1_contract_validation_errors_object;
    this.r1_contract_validation_warnings_object = deal.r1_contract_validation_warnings_object;
    this.r1_contract_worksheet_created_date_utc = deal.r1_contract_worksheet_created_date_utc;
    this.r1_contract_manually_validated = deal.r1_contract_manually_validated;
    this.called_by_financial_specialist = deal.called_by_financial_specialist;
    this.needs_electronic_signature_verification = deal.needs_electronic_signature_verification;
  }
}

export const dealPodAndUserIdKeys: (keyof Deal)[] = [
  'pod_id',
  'setter_id',
  'closer_id',
  'closer2_id',
  'funding_clerk_id',
  'titling_pod_id',
  'title_clerk_id',
  'title_clerk2_id',
];

export const TITLING_STATES = [
  DealStateEnum.Signed,
  DealStateEnum.Funded,
  DealStateEnum.SendPayoff,
  DealStateEnum.WaitingForTitle,
  DealStateEnum.TitleReceived,
  DealStateEnum.SentToProcessor,
  DealStateEnum.Finalized,
];

export const isTitling = (state: DealStateEnum) => TITLING_STATES.includes(state);

const DEFAULT_TO_TITLE_INFO_STATES = [
  DealStateEnum.WaitingForTitle,
  DealStateEnum.TitleReceived,
  DealStateEnum.SentToProcessor,
  DealStateEnum.Finalized,
];

export const defaultToTitleInfo = (state: DealStateEnum) =>
  DEFAULT_TO_TITLE_INFO_STATES.includes(state);

export const IS_FUNDED_STATES = [
  DealStateEnum.Funded,
  DealStateEnum.SendPayoff,
  DealStateEnum.WaitingForTitle,
  DealStateEnum.TitleReceived,
  DealStateEnum.SentToProcessor,
  DealStateEnum.Finalized,
  DealStateEnum.ReadyForPickup,
  DealStateEnum.PaidOff,
  DealStateEnum.AtAuction,
  DealStateEnum.Sold,
  DealStateEnum.TitleSent,
];

export const isDealInOrPastFunded = (state: DealStateEnum) => IS_FUNDED_STATES.includes(state);

export const isBeforeClosing = (state: DealStateEnum) =>
  [
    DealStateEnum.Estimate,
    DealStateEnum.Floor,
    DealStateEnum.SoftClose,
    DealStateEnum.StructuringInProgress,
    DealStateEnum.Structuring,
  ].includes(state);

export const isClosed = (state: DealStateEnum) =>
  [
    DealStateEnum.Closed,
    DealStateEnum.SentForSignatures,
    DealStateEnum.Signed,
    DealStateEnum.Funded,
    DealStateEnum.Finalized,
    DealStateEnum.ReadyForPickup,
    DealStateEnum.PaidOff,
    DealStateEnum.AtAuction,
    DealStateEnum.Sold,
    DealStateEnum.TitleSent,
    DealStateEnum.SendPayoff,
    DealStateEnum.WaitingForTitle,
    DealStateEnum.TitleReceived,
    DealStateEnum.SentToProcessor,
  ].includes(state);

// keep in sync with backend: types/deal.ts
export const uneditableDealStates = [
  DealStateEnum.Closed,
  DealStateEnum.SentForSignatures,
  DealStateEnum.Signed,
  DealStateEnum.Funded,
  DealStateEnum.Finalized,
  DealStateEnum.ReadyForPickup,
  DealStateEnum.PaidOff,
  DealStateEnum.AtAuction,
  DealStateEnum.Sold,
  DealStateEnum.TitleSent,
  DealStateEnum.SendPayoff,
  DealStateEnum.WaitingForTitle,
  DealStateEnum.TitleReceived,
  DealStateEnum.SentToProcessor,
];

export const isUneditable = (state?: DealStateEnum) =>
  !!state && uneditableDealStates.includes(state);

export const isDeal = (
  obj: Deal | TemporaryData | Payoff | PayoffCheckInformationFormValues,
): obj is Deal => (obj as Deal)?.car?.payoff !== undefined;

// TODO: replace snippets with fragments.
// SNIPPETS
const addressSnippet = `
  id
  address_line
  address_line_2
  zip
  city
  state
  county
  address_type
  residence_type
  years_at_home
  months_at_home
  monthly_payment
  moved_states
`;

const employmentSnippet = `
  id
  status
  name
  job_title
  phone_number
  years_at_job
  months_at_job
  gross_income
  pay_frequency
`;

const customerSnippet = `
  id
  first_name
  middle_name
  last_name
  name_suffix
  phone_number
  home_phone_number
  email
  no_email
  ssn
  dob
  address {
    ${addressSnippet}
  }
  prev_address {
    ${addressSnippet}
  }
  employment {
    ${employmentSnippet}
  }
  prev_employment {
    ${employmentSnippet}
  }
  proof_of_insurance {
    id
    company_name
    policy_number
    expires
  }
  has_same_address_as_cobuyer
  marital_status
  prequalification {
    id
    input {
      first_name
      last_name
      ssn
      zip
      gross_income
      pay_frequency
      vehicle_payoff
      lending_book_value
      money_down
      income_sources
    }
    output {
      errors
    }
    credit_score
    ltv
    payment_to_income
    customer_id
    credit_score_tier
  }
  auth0_id
`;

const payoffSnippet = `
  id
  lienholder_name
  lienholder_slug
  lender_name
  account_number
  vehicle_payoff
  good_through_date
  lease_term
  remaining_payments
  msrp
  residual_percentage
  sales_price
  cap_reduction
  money_down
  money_factor
  termination_fees
  cap_cost
  residual_amount
  estimated_payoff
  old_lease_payment
  sales_tax_from_payoff
  payoff_includes_sales_tax
  sales_tax_from_payoff_entered_manually
  verification_status
  next_payment_date
  user_entered_total_payoff
  maturity_date
`;

const kbbSelectedOptionsSnippet = `
  id
  name
  type
  lendingOptionPrice
  retailOptionPrice
  removed
`;

const carSnippet = `
  id
  license_plate_number
  license_plate_state
  vin
  year
  make
  model
  mileage
  color
  book_value
  retail_book_value
  jdp_adjusted_clean_trade
  jdp_adjusted_clean_retail
  vehicle_type
  fuel_type
  kbb_trim_name
  mmr
  registration_expiration
  odometer_status
  kbb_selected_options_object {
    selected_options {
      ${kbbSelectedOptionsSnippet}
    }
  }
  payoff {
    ${payoffSnippet}
  }
`;

const assignedUsersSnippet = `
  pod_id
  pod {
    id
    name
    color
  }
  setter_id
  setter {
    id
    name
    pods {
      id
    }
  }
  closer_id
  closer {
    id
    name
    pods {
      id
    }
  }
  closer2_id
  closer2 {
    id
    name
    pods {
      id
    }
  }
  structuring_manager_id
  structuring_manager {
    id
    name
    pods {
      id
    }
  }
  funding_clerk_id
  funding_clerk {
    id
    name
    pods {
      id
    }
  }
`;

const titleUsersSnippet = `
  titling_pod_id
  titling_pod {
    id
    color
    name
    leads {
      id
      name
    }
  }
  title_clerk_id
  title_clerk {
    id
    name
    deleted_at
  }
  title_clerk2_id
  title_clerk2 {
    id
    name
    deleted_at
  }
`;

const dealStatesSnippet = `
  id
  deal_id
  state
  updated_date_utc
  user_id
  user {
    id
    name
  }
`;

const dealDatesSnippet = `
  id
  deal_id
  dates
  custom_dates
`;

const dealsSnippet = `
  id
  type
  source
  state
  created_at
  creation_date_utc
  creation_date_tz
  completion_date_utc
  lane_arrival_utc
  needs_electronic_signature_verification
  paperwork_type
  request_boot
  boot_reason
  has_problem
  car {
    ${carSnippet}
  }
  customer {
    ${customerSnippet}
  }
 ${assignedUsersSnippet}
 ${titleUsersSnippet}
  notifications {
    id
    message
    created_at
  }
  financial_info {
    id
    bank
    amount_financed
    processor
    title_registration_option
  }
  financial_info_acquisition {
    id
    transport_cost
    cash_to_customer
    auction_id
    auction_fees
    total_cost
    sell_price
    gross_profit
    kbb_lending
    appraised_value
  }
  deal_dates {
    ${dealDatesSnippet}
  }
`;

const titlingExportDealsSnippet = `
  id
  type
  source
  state
  has_problem
  car {
    id
    year
    make
    model
    mileage
    vin
    mmr
    payoff {
      id
      lienholder_name
      lienholder_slug
      lender_name
      vehicle_payoff
      good_through_date
      verification_status
      next_payment_date
    }
  }
  customer {
    id
    first_name
    middle_name
    last_name
    address {
      ${addressSnippet}
    }
  }
  financial_info {
    id
    bank
    amount_financed
    money_down
    bank_fees
    base_tax_amount
    warranty_tax_amount
    title_registration_option
    title_fee
    new_registration_fee
    registration_transfer_fee
    doc_fee
  }
  deal_dates {
    ${dealDatesSnippet}
  }
  notes {
    id
    text
    created_at
    creation_date_tz
    author {
      name
    }
  }
  ${titleUsersSnippet}
`;

const financialInfoSnippet = `
  id
  deal_id
  money_down
  bank_fees
  base_tax_amount
  warranty_tax_amount
  title_fee
  new_registration_fee
  registration_transfer_fee
  doc_fee
  sales_tax_rate
  vsc_price
  vsc_cost
  gap_price
  gap_cost
  days_to_payment
  first_payment_date
  sell_rate
  buy_rate
  term
  bank
  vsc_type
  vsc_term
  payment
  amount_financed
  profit
  commissionable_profit
  setter_commission
  closer_commission
  option_type
  plate_transfer
  pen_vsc_session_id
  pen_vsc_rate_id
  pen_vsc_form_id
  pen_gap_session_id
  pen_gap_rate_id
  pen_gap_form_id
  title_registration_option
  processor
  tt_transaction_id
  buyer_not_lessee
  reserve
  user_entered_reserve
  selected_credit_decision_id
  credit_card_payment_amount_limit
  quick_notes
  needs_temporary_registration_tags
  new_lienholder {
    name
    r1_fsid
    address
    city
    state
    zip
  }
`;

const financialInfoAcquisitionSnippet = `
  id
  deal_id
  kbb_lending
  appraised_value
  max_cash_to_customer
  max_total_cost
  cash_to_customer
  is_approved
  auction_fees
  transport_cost
  total_cost
  auction_id
  gross_profit
  sell_price
  overriding_user_id
`;

const dealDetailSnippet = `
  id
  duplicated_from_deal_id
  type
  source
  state
  has_problem
  set_date
  completion_date_utc
  completion_date_tz
  needs_electronic_signature_verification
  paperwork_type
  direct_pay_state
  request_boot
  boot_reason
  r1_jacket_id
  r1_jacket_id_created_date_utc
  document_progress_status
  sales_visibility
  import_type
  called_by_financial_specialist
  r1_contract_validation_errors_object {
    errors
  }
  r1_contract_validation_warnings_object {
    warnings
  }
  r1_contract_manually_validated
  signing_on_com
  consents {
    id
    type
  }
  ${assignedUsersSnippet}
  ${titleUsersSnippet}
  car {
    ${carSnippet}
  }
  customer {
    ${customerSnippet}
  }
  cobuyer {
    ${customerSnippet}
    relation_to_buyer
  }
  contact {
    id
    deal_id
    relation_to_buyer
    first_name
    middle_name
    last_name
    phone_number
    email
  }
  second_contact {
    id
    deal_id
    relation_to_buyer
    first_name
    middle_name
    last_name
    phone_number
    email
  }
  original_lessee {
    id
    first_name
    last_name
    phone_number
    email
    address_line
    ssn
    city
    state
    zip
    county
}
  financial_info {
    ${financialInfoSnippet}
  }
  financial_info_acquisition {
    ${financialInfoAcquisitionSnippet}
  }
  condition_report {
    id
    deal_id
    accidents
    tires
    exterior
    interior
    smoked_in
    lights_on_dash
    overall_condition
    score
  }
  document_info {
    id
    adobe_agreement_id
  }
  deal_states {
    ${dealStatesSnippet}
  }
  deal_dates {
    ${dealDatesSnippet}
  }
  notifications {
    id
    message
    seen
    customer_id
    deal_contact_id
  }
  referral_source {
    ...ReferralSourceFragment
  }
  tags {
    id
    slug
    color
    display_name
    is_active
  }
    notification_subscribers {
      id
      subscriber_id
    }
  `;

// QUERIES
export const titlingDashboardDealsQuery = gql`
  query titlingDashboardDeals(
    $sources: [String]!
    $types: [DealType]!
    $assigned_to_deleted_users_filter: Boolean
    $pod_id: ID
    $page: Int
    $itemsPerPage: Int
    $filters: FilterType
    $sorting: SortingType
  ) {
    titlingDashboardDeals(
      sources: $sources
      types: $types
      assigned_to_deleted_users_filter: $assigned_to_deleted_users_filter
      pod_id: $pod_id
      page: $page
      itemsPerPage: $itemsPerPage
      filters: $filters
      sorting: $sorting
    ) {
      deals {
        ${dealsSnippet}
        setter {
          id
        }
        closer {
          id
        }
        closer2 {
          id
        }
      }
      totalRecords
      dealsMaxAge
    }
  }
`;

export const exportDealsQuery = gql`
  query titlingDashboardDeals(
    $sources: [String]!,
    $types: [DealType]!,
    $pod_id: ID, $page: Int,
    $itemsPerPage: Int,
    $filters: FilterType,
    $sorting: SortingType
    $includeTitleNotes: Boolean
  ) {
    titlingDashboardDeals(
      sources: $sources,
      types: $types,
      pod_id: $pod_id,
      page: $page,
      itemsPerPage: $itemsPerPage,
      filters: $filters,
      sorting: $sorting
      includeTitleNotes: $includeTitleNotes
    ) {
      deals { ${titlingExportDealsSnippet} }
    }
  }
`;

export const dealQuery = gql`
  query deal($id: ID!, $sources: [String]!, $types: [DealType]!) {
    deal(id: $id, sources: $sources, types: $types) {
      ${dealDetailSnippet}
    }
  }
  ${ReferralSourceFragment}
`;

export const bootedDealsQuery = gql`
  query bootedDeals(
    $assigned_to_me_filter: Boolean,
    $assigned_to_deleted_users_filter: Boolean,
    $start_date: DateTime!,
    $end_date: DateTime!,
    $sources: [String]!,
    $types: [DealType]!,
    $pod_id: ID,
    $unread_messages_filter: Boolean
    $showActiveTags: Boolean
    $showMatureFollowUps: Boolean
  ) {
    bootedDeals(
      assigned_to_me_filter: $assigned_to_me_filter,
      assigned_to_deleted_users_filter: $assigned_to_deleted_users_filter,
      start_date: $start_date,
      end_date: $end_date,
      sources: $sources,
      types: $types,
      pod_id: $pod_id,
      unread_messages_filter: $unread_messages_filter
      showActiveTags: $showActiveTags
      showMatureFollowUps: $showMatureFollowUps
    ) {
      ${dealsSnippet}
      setter {
        id
      }
      closer {
        id
      }
      closer2 {
        id
      }
    }
  }
`;

// MUTATIONS
export const estimateUpsert = gql`
  mutation estimateUpsert($deal: dealInput) {
    estimateUpsert(deal: $deal) {
      ${dealDetailSnippet}
    }
  }
  ${ReferralSourceFragment}
`;

export const customerInfoUpsert = gql`
  mutation customerInfoUpsert($deal: dealInput) {
    customerInfoUpsert(deal: $deal) {
      id
    }
  }
`;

export const creditAppUpsert = gql`
  mutation creditAppUpsert($deal: dealInput) {
    creditAppUpsert(deal: $deal) {
      ${dealDetailSnippet}
    }
  }
  ${ReferralSourceFragment}
`;

export const creditAppAutomate = gql`
  mutation automateCreditApplication($deal_id: ID!) {
    automateCreditApplication(deal_id: $deal_id) {
      id
    }
  }
`;

export const dealUpdateType = gql`
  mutation dealUpdateType($id: ID!, $type: DealType!) {
    dealUpdateType(id: $id, type: $type) {
      id
    }
  }
`;

export const dealUpdateStateAndSendEmails = gql`
  mutation dealUpdateStateAndSendEmails(
    $id: ID!
    $state: String!
    $date: DateTime
    $isR1ContractValidated: Boolean
    $isR1ContractUploaded: Boolean
    $dealIsSigningOnCom: Boolean
  ) {
    dealUpdateStateAndSendEmails(
      id: $id
      state: $state
      date: $date
      isR1ContractValidated: $isR1ContractValidated
      isR1ContractUploaded: $isR1ContractUploaded
      dealIsSigningOnCom: $dealIsSigningOnCom
    ) {
      id
      state
      signing_on_com
    }
  }
`;

export const resendEmails = gql`
  mutation resendEmails($dealId: ID!) {
    resendEmails(dealId: $dealId)
  }
`;

export const dealCompleted = gql`
  mutation dealCompleted($id: ID!, $state: String!) {
    dealCompleted(id: $id, state: $state) {
      id
    }
  }
`;

export const dealUnwound = gql`
  mutation dealUnwound($id: ID!, $boot_reason: bootReasonInput!) {
    dealUnwound(id: $id, boot_reason: $boot_reason) {
      id
    }
  }
`;

export const dealSeen = gql`
  mutation dealSeen($dealId: ID!) {
    dealSeen(dealId: $dealId)
  }
`;

export const dealInfoUpsert = gql`
  mutation dealInfoUpsert(
    $financialInfo: financialInfoInput
    $car: carInput
    $customer: customerInput
  ) {
    dealInfoUpsert(financialInfo: $financialInfo, car: $car, customer: $customer) {
      ${dealDetailSnippet}
    }
  }
  ${ReferralSourceFragment}
`;

export const acquisitionDealInfoUpsert = gql`
  mutation acquisitionDealInfoUpsert($deal: dealInput) {
    acquisitionDealInfoUpsert(deal: $deal) {
      id
    }
  }
`;

export const buyerCobuyerSwap = gql`
  mutation buyerCobuyerSwap($deal_id: ID!, $customer_id: ID!, $cobuyer_id: ID!) {
    buyerCobuyerSwap(deal_id: $deal_id, customer_id: $customer_id, cobuyer_id: $cobuyer_id) {
      id
    }
  }
`;

export const submitToRouteOne = gql`
  mutation submitToRouteOne($deal: dealInput, $lenderIds: [String]) {
    submitToRouteOne(deal: $deal, lenderIds: $lenderIds) {
      r1_jacket_id
      r1_jacket_id_created_date_utc
      financial_info {
        id
      }
    }
  }
`;

export const dealFinalizeAcquisition = gql`
  mutation dealFinalizeAcquisition(
    $id: ID!
    $address_line: String!
    $address_line_2: String
    $city: String!
    $state: String!
    $zip: String!
    $county: String!
    $dob: Date!
    $cash_to_customer: Float!
    $mileage: Float
    $vehicle_payoff: Float
  ) {
    dealFinalizeAcquisition(
      id: $id
      address_line: $address_line
      address_line_2: $address_line_2
      city: $city
      state: $state
      zip: $zip
      county: $county
      dob: $dob
      cash_to_customer: $cash_to_customer
      mileage: $mileage
      vehicle_payoff: $vehicle_payoff
    )
  }
`;

export const dealHasProblemSwitch = gql`
  mutation dealHasProblemSwitch($id: ID!, $has_problem: Boolean!, $note: noteInput) {
    dealHasProblemSwitch(id: $id, has_problem: $has_problem, note: $note) {
      id
      has_problem
    }
  }
`;

export const skipTimelineStep = gql`
  mutation skipTimelineStep($jacketId: String, $documentProgressStatus: DocumentProgressStatus) {
    skipTimelineStep(jacketId: $jacketId, documentProgressStatus: $documentProgressStatus) {
      document_progress_status
      r1_contract_validation_errors_object {
        errors
      }
      r1_contract_validation_warnings_object {
        warnings
      }
      r1_contract_manually_validated
    }
  }
`;

export const createNewEstimateAndSetFollowUp = gql`
  mutation createNewEstimateAndSetFollowUp(
    $first_name: String!
    $last_name: String!
    $phone_number: String!
    $email: String
    $no_email: Boolean
    $address_line: String
    $address_line_2: String
    $zip: String
    $city: String
    $state: String
    $county: String
    $date_utc: DateTime!
    $note: String
  ) {
    createNewEstimateAndSetFollowUp(
      first_name: $first_name
      last_name: $last_name
      phone_number: $phone_number
      email: $email
      no_email: $no_email
      address_line: $address_line
      address_line_2: $address_line_2
      zip: $zip
      city: $city
      state: $state
      county: $county
      date_utc: $date_utc
      note: $note
    ) {
      id
    }
  }
`;
