/* eslint-disable func-names */
import { string } from 'yup';

// validate for US phone numbers
export const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const phoneNumberValidationOptional = (person?: string) =>
  string()
    .nullable()
    .matches(phoneRegExp, `Please input a valid ${person || ''}phone number`);

export const phoneNumberValidationRequired = (person?: string) =>
  phoneNumberValidationOptional(person).required(`${person || ''} phone number is required`);
export const conditionalPhoneNumberValidation = (person?: string) =>
  string()
    .nullable()
    .matches(phoneRegExp, {
      message: `Please input a valid ${person || ''}phone number`,
      excludeEmptyString: true,
    })
    .test(`phone_test`, `${person || ''}Phone number is required`, function () {
      const { home_phone_number, phone_number } =
        this.options.context?.[person ? 'cobuyer' : 'customer'] || {};

      const phoneNumberRequired = !home_phone_number;
      if (phoneNumberRequired) {
        return !!phone_number;
      }
      return true;
    });
