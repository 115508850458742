import { useEffect, useState } from 'react';

import { HStack } from '@chakra-ui/react';
import { isSameDay } from 'date-fns';
import { useFormikContext } from 'formik';

import DatePicker from '../shared/DatePicker';
import Select from '../shared/Select';
import TextArea from '../shared/TextArea';

import { followUpTypeOptions } from '../../utils/followUps';
import { FollowUpFormType } from '../../utils/validation/followUp';

type FollowUpFormProps = {
  setTypeAvailable?: boolean;
  needsNamePrefix?: boolean;
};

const FollowUpForm = ({ setTypeAvailable = true, needsNamePrefix = false }: FollowUpFormProps) => {
  const namePrefix = needsNamePrefix ? 'follow_up.' : '';

  const { values: formikValues } = useFormikContext<
    FollowUpFormType | { follow_up: FollowUpFormType }
  >();
  const values = (
    needsNamePrefix && 'follow_up' in formikValues ? formikValues.follow_up : formikValues
  ) as FollowUpFormType;

  const currentDate = new Date();
  const [minTime, setMinTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const minHour = currentDate.getHours();
    const minMinute = currentDate.getMinutes();
    const isToday = values.date ? isSameDay(currentDate, values.date) : false;

    if (isToday) {
      setMinTime(new Date(0, 0, 0, minHour, minMinute));
    } else {
      setMinTime(undefined);
    }
  }, [values.date]);

  return (
    <>
      <HStack w="100%" mt={3} alignItems="start">
        <DatePicker
          topLabel="Date"
          name={`${namePrefix}date`}
          minDate={currentDate}
          boxStyles={{ width: '100%' }}
        />
        <DatePicker
          topLabel="Time"
          name={`${namePrefix}time`}
          dateFormat="h:mm aa"
          timeFormat="h:mm aa"
          minTime={minTime}
          maxTime={minTime ? new Date(0, 0, 0, 23, 59) : undefined}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption=""
          boxStyles={{ width: '100%' }}
          // Prevents the user from typing in the input.
          onKeyDown={(e) => e.preventDefault()}
        />
      </HStack>
      {setTypeAvailable ? (
        <HStack w="100%" mt={3} alignItems="start">
          <Select
            name={`${namePrefix}type`}
            label="Type"
            emptyOption
            options={followUpTypeOptions}
          />
        </HStack>
      ) : null}
      <HStack w="100%" mt={3} alignItems="start">
        <TextArea
          name={`${namePrefix}note`}
          label="Note"
          minH="6em"
          maxH="12em"
          resize="vertical"
          maxLength={300}
        />
      </HStack>
    </>
  );
};

export default FollowUpForm;
