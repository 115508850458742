import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { DealType, PayoffRequest, ReferralSourceEnum } from '../../gql/generated/graphql';
import { activePayoffRequestStatuses } from '../../gql/payoffRequestGql';
import { CustomerInfo } from '../../gql/prs/types';

import { validateFuture } from '../../libs/yup-validators/dates';
import { testIsAdult, testIsOldEnough } from '../../libs/yup-validators/dob';
import { MAX_PAYOFF_AMOUNT } from '../../libs/yup-validators/payoff';
import { get24HoursAgo } from '../../utils/dates';
import { zipRegExp } from '../../utils/validation/address';
import { fourNumberExp } from '../../utils/validation/number';
import { phoneRegExp } from '../../utils/validation/phoneNumber';
import { ssnRegExpFull, ssnRegExpLast4Min, ssnRegExpLast6Min } from '../../utils/validation/ssn';
import { optionalStringValidation, requiredStringValidation } from '../../utils/validation/strings';

const getValidationUsingRequiredCombinations = (
  type: CustomerInfo,
  defaultValidation: boolean,
  context?: AnyObject,
) => {
  const requiredCombinations: CustomerInfo[][] = context?.requiredCombinations ?? [];

  const combinationsWithField = requiredCombinations.filter((combination) =>
    combination.includes(type),
  );

  if (!combinationsWithField.length) {
    return true;
  }

  const passesValidation = requiredCombinations
    .filter((combination) => !combination.includes(type))
    .some((combination) =>
      combination.every((field) => {
        switch (field) {
          case CustomerInfo.ACCOUNT_NUMBER:
            return !!context?.car?.payoff?.account_number;
          case CustomerInfo.DOB:
            return !!context?.customer?.dob;
          case CustomerInfo.SSN:
            return ssnRegExpFull.test(context?.customer?.ssn);
          case CustomerInfo.SSN_LAST_4:
            return ssnRegExpLast4Min.test(context?.customer?.ssn_last_4);
          case CustomerInfo.SSN_LAST_6:
            return ssnRegExpLast6Min.test(context?.customer?.ssn_last_6);
          default:
            return false;
        }
      }),
    );

  return passesValidation ? true : defaultValidation;
};

export const payoffValidationReq = (isRefi: boolean) =>
  Yup.object().shape({
    lienholder_name: optionalStringValidation,
    lienholder_slug: isRefi ? optionalStringValidation : requiredStringValidation('Lienholder'),
    lender_name: isRefi ? requiredStringValidation('Lender') : optionalStringValidation,
    account_number: Yup.string()
      .nullable()
      .test(
        'payoff.account_number',
        'Please input a valid account number',
        (account_number, { options: { context } }) =>
          getValidationUsingRequiredCombinations(
            CustomerInfo.ACCOUNT_NUMBER,
            !!account_number,
            context,
          ),
      ),
    vehicle_payoff: Yup.number().optional().nullable(),

    doubleTaxApplied: Yup.boolean().optional().nullable(),
    previousTotalPayoff: Yup.number().optional().nullable(),
    totalPayoff: Yup.number()
      .nullable()
      .lessThan(MAX_PAYOFF_AMOUNT, 'Must be less than 1 million')
      .test(
        'payoff.totalPayoff',
        'Payoff Amount is required',
        (totalPayoff, { options: { context } }) => {
          const payoffRequest: PayoffRequest = context?.payoffRequest ?? {};

          if (payoffRequest?.status && activePayoffRequestStatuses.includes(payoffRequest.status)) {
            return true;
          }

          return !!totalPayoff;
        },
      ),

    good_through_date: Yup.date()
      .nullable(true)
      .test(
        'payoff.good_through_date',
        'Good Through Date is required',
        (good_through_date, { options: { context } }) => {
          const payoffRequest: PayoffRequest = context?.payoffRequest ?? {};

          if (payoffRequest?.status && activePayoffRequestStatuses.includes(payoffRequest.status)) {
            return true;
          }

          return !!good_through_date;
        },
      )
      .test('futureDate', 'Good Through Date should be in the future', validateFuture),
    payoff_includes_sales_tax: Yup.boolean(),
    sales_tax_from_payoff_entered_manually: Yup.boolean(),
    sales_tax_from_payoff: Yup.number()
      .nullable()
      .when('sales_tax_from_payoff_entered_manually', {
        is: true,
        then: Yup.number().required('Please enter a value').nullable(),
      }),
    next_payment_date: Yup.date().optional().nullable(true),
    user_entered_total_payoff: Yup.number().optional().nullable(true),
  });

export const carValidationReq = (payoff: boolean, isRefi: boolean) =>
  Yup.object().shape({
    vin: Yup.string()
      .required('Please enter a valid VIN')
      .min(17, 'Please enter a valid VIN')
      .max(17, 'Please enter a valid VIN'),
    color: Yup.string().nullable().required('Please select a Color'),
    mileage: Yup.number()
      .integer('Please enter a whole number')
      .nullable()
      .required('Please enter a valid Mileage')
      .min(1, 'Please enter a valid Mileage'),
    year: Yup.string()
      .nullable()
      .required('Year is required')
      .min(4, 'Please enter a valid year')
      .max(4, 'Please enter a valid year')
      .matches(fourNumberExp, { message: 'Please enter a valid year', excludeEmptyString: true }),
    make: Yup.string().nullable().required('Make is required'),
    model: Yup.string().nullable().required('Model is required'),
    book_value: Yup.number().nullable().required('KBB Lending is required'),
    retail_book_value: Yup.number().nullable().required('KBB Retail is required'),
    jdp_adjusted_clean_trade: Yup.number().nullable().required('JD Power Lending is required'),
    jdp_adjusted_clean_retail: Yup.number().nullable().required('JD Power Retail is required'),
    payoff: payoff ? payoffValidationReq(isRefi) : Yup.object().shape({}),
    license_plate_number: Yup.string()
      .optional()
      .nullable()
      .max(8, 'Must be less than 8 characters'),
    license_plate_state: Yup.string().optional().nullable(),
    registration_expiration: Yup.date()
      .nullable()
      .min(get24HoursAgo(), 'Registration Expiration should be in the future'),
  });

export const getValidationSchema = (isInboundTeamOrAdmin: boolean, isRefi: boolean) =>
  Yup.object().shape({
    type: Yup.string()
      .oneOf(Object.values(DealType), 'Invalid Deal Type')
      .required('Deal Type is required'),

    car: carValidationReq(true, isRefi),
    customer: Yup.object().shape({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      phone_number: Yup.string()
        .nullable()
        .matches(phoneRegExp, {
          message: 'Please input a valid phone number',
          excludeEmptyString: true,
        })
        .test(
          'phone_test',
          'Phone number is required',
          (phone_number, { options: { context } }) => {
            const { home_phone_number } = context?.customer;
            const phoneNumberRequired = !home_phone_number;

            return phoneNumberRequired ? !!phone_number : true;
          },
        ),
      home_phone_number: Yup.string()
        .nullable()
        .matches(phoneRegExp, {
          message: 'Please input a valid phone number',
          excludeEmptyString: true,
        })
        .test(
          'phone_test',
          'Phone number is required',
          (home_phone_number, { options: { context } }) => {
            const { phone_number } = context?.customer;
            const homePhoneNumberRequired = !phone_number;

            return homePhoneNumberRequired ? !!home_phone_number : true;
          },
        ),
      email: Yup.string()
        .email('Please input a valid email address')
        .lowercase()
        .notOneOf(['idk@email.com', 'no@email.com'], 'If no email, please leave field blank')
        .test(
          'customer.no_email',
          'Email or checkbox required',
          (email, { options: { context } }) => {
            const { no_email } = context?.customer;
            const emailIsRequired = !no_email;

            return emailIsRequired ? !!email : true;
          },
        ),
      ssn: Yup.string().test(
        'customer.ssn',
        'Please input a valid SSN',
        (ssn, { options: { context } }) =>
          getValidationUsingRequiredCombinations(
            CustomerInfo.SSN,
            ssnRegExpFull.test(ssn ?? ''),
            context,
          ),
      ),
      ssn_last_4: Yup.string().test(
        'customer.ssn_last_4',
        'Please input last 4 of SSN',
        (ssn_last_4, { options: { context } }) =>
          getValidationUsingRequiredCombinations(
            CustomerInfo.SSN_LAST_4,
            ssnRegExpLast4Min.test(ssn_last_4 ?? ''),
            context,
          ),
      ),
      ssn_last_6: Yup.string().test(
        'customer.ssn_last_6',
        'Please input last 6 of SSN',
        (ssn_last_6, { options: { context } }) =>
          getValidationUsingRequiredCombinations(
            CustomerInfo.SSN_LAST_6,
            ssnRegExpLast6Min.test(ssn_last_6 ?? ''),
            context,
          ),
      ),
      dob: Yup.date()
        .nullable()
        .test('customer.dob', 'Please input a valid DOB', (dob, { options: { context } }) => {
          const dobDate = dob ?? new Date();
          return getValidationUsingRequiredCombinations(
            CustomerInfo.DOB,
            testIsAdult(dobDate) && testIsOldEnough(dobDate),
            context,
          );
        }),
      address: Yup.object().shape({
        address_line: Yup.string().optional().nullable(),
        address_line_2: Yup.string().optional().nullable(),
        zip: Yup.string().matches(zipRegExp, {
          message: 'Please input a valid zip code',
          excludeEmptyString: true,
        }),
        state: Yup.string().test(
          'len',
          'Please select a valid State',
          (value) => value?.length === 2,
        ),
      }),
    }),
    financial_info: Yup.object()
      .shape({
        buyer_not_lessee: Yup.boolean().optional().nullable(),
      })
      .optional()
      .nullable(),
    ...(isInboundTeamOrAdmin
      ? {
          referral_source: Yup.object()
            .shape({
              source_name: Yup.string()
                .oneOf(Object.values(ReferralSourceEnum), 'Invalid How Did You Hear About Us?')
                .required('How Did You Hear About Us? is required'),
              other_source_description: Yup.string().when('source_name', {
                is: ReferralSourceEnum.Other,
                then: Yup.string().nullable().required('Description is required'),
                otherwise: Yup.string().nullable().optional(),
              }),
            })
            .optional()
            .nullable(),
        }
      : {}),
  });
