import { useEffect, useState } from 'react';

import {
  Button,
  RadioGroup as ChakraRadioGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useSaveNewCustomerCallOutcomeMutation } from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import { useCallsContext } from '../../libs/callsContext';
import { GotNewEstimateOption, NewCustomerCallOutcomeEnum } from '../../types/calls';

export const PostCallSurveyModal = () => {
  const { activeCall, current } = useCallsContext();

  const [needToShowSurvey, setNeedToShowSurvey] = useState<boolean>(false);

  const [callId, setCallId] = useState<string>('');
  const [gotNewEstimate, setGotNewEstimate] = useState<GotNewEstimateOption | undefined>('yes');
  const [outcome, setOutcome] = useState<NewCustomerCallOutcomeEnum | undefined>();
  const [outcomeDescription, setOutcomeDescription] = useState('');
  const [outcomeDescriptionError, setOutcomeDescriptionError] = useState('');

  const [saveNewCustomerCallOutcomeMutation] = useSaveNewCustomerCallOutcomeMutation();

  const handleGotNewEstimateChange = (value: GotNewEstimateOption) => {
    setGotNewEstimate(value);
    if (value === 'yes') {
      setOutcome(NewCustomerCallOutcomeEnum.NewEstimate);
    } else {
      setOutcome(undefined);
    }
  };

  const handleReasonChange = (value: string) => {
    setOutcome(value as NewCustomerCallOutcomeEnum);
    setOutcomeDescription('');
    setOutcomeDescriptionError('');
  };

  const handleSubmitSurvey = async () => {
    if (outcome === NewCustomerCallOutcomeEnum.Other && outcomeDescription.length < 10) {
      setOutcomeDescriptionError('Please provide more explanation');
      return;
    }

    await saveNewCustomerCallOutcomeMutation({
      variables: {
        call_id: callId,
        outcome,
        outcome_description:
          outcome === NewCustomerCallOutcomeEnum.Other ? outcomeDescription : null,
      },
    });
    setNeedToShowSurvey(false);

    setGotNewEstimate(undefined);
    setOutcome(undefined);
    setOutcomeDescription('');
    setOutcomeDescriptionError('');
  };

  useEffect(() => {
    // grab the callId before the call is answered
    if (current && !current.dealId && current.callId) {
      setCallId(current.callId);
    }
  }, [current]);

  useEffect(() => {
    // When a number isn't connected to a deal and the hunt_group is sales, we will need to show the survey after the call
    if (
      activeCall &&
      !activeCall.dealId &&
      activeCall.huntGroupSlug === 'sales' &&
      !activeCall.is_outbound
    ) {
      setNeedToShowSurvey(true);
    }
  }, [activeCall]);

  const canSubmit = gotNewEstimate === 'yes' || (gotNewEstimate === 'no' && outcome);

  return (
    <Modal
      title="Post Call Survey"
      size="xl"
      isOpen={needToShowSurvey && !activeCall}
      canDismiss={false}
      closeOnOverlayClick={false}
      onClose={() => {
        setNeedToShowSurvey(false);
      }}
      rightButtons={
        <Button isDisabled={!canSubmit} onClick={handleSubmitSurvey}>
          Submit
        </Button>
      }
    >
      <>
        <Text>Did this call result in a new estimate?</Text>

        <ChakraRadioGroup
          name="reason"
          onChange={handleGotNewEstimateChange}
          value={gotNewEstimate}
          m={3}
        >
          <Stack>
            <Radio mb={0} value="yes">
              <Text fontWeight="normal">Yes</Text>
            </Radio>
            <Radio value="no">
              <Text fontWeight="normal">No</Text>
            </Radio>
          </Stack>
        </ChakraRadioGroup>
      </>

      {gotNewEstimate === 'no' && (
        <>
          <Text>What was the reason given to not proceed?</Text>

          <ChakraRadioGroup name="reason" onChange={handleReasonChange} value={outcome} m={3}>
            <Stack>
              <Radio mb={0} value={NewCustomerCallOutcomeEnum.BankDMVProcessor}>
                <Text fontWeight="normal">Bank/DMV/Processor</Text>
              </Radio>
              <Radio value={NewCustomerCallOutcomeEnum.NewLeaseOrAcquisition}>
                <Text fontWeight="normal">Wanted a new lease or wanted us to buy their car</Text>
              </Radio>
              <Radio value={NewCustomerCallOutcomeEnum.CashBuyer}>
                <Text fontWeight="normal">Cash Buyer</Text>
              </Radio>
              <Radio mb={0} value={NewCustomerCallOutcomeEnum.DealershipOrLienholder}>
                <Text fontWeight="normal">Thought we were the dealership/lienholder</Text>
              </Radio>
              <Radio value={NewCustomerCallOutcomeEnum.NoLease}>
                <Text fontWeight="normal">Do not have a lease</Text>
              </Radio>
              <Radio value={NewCustomerCallOutcomeEnum.ComplaintRemoveMarketing}>
                <Text fontWeight="normal">Complaint/remove from marketing</Text>
              </Radio>
              <Radio value={NewCustomerCallOutcomeEnum.MisdirectedCall}>
                <Text fontWeight="normal">
                  Misdirected call (they were trying to reach another business)
                </Text>
              </Radio>
              <Flex alignItems="start">
                <Radio value={NewCustomerCallOutcomeEnum.Other}>
                  <Text fontWeight="normal">Other</Text>
                </Radio>
                <FormControl ml={2} isInvalid={!!outcomeDescriptionError}>
                  <Input
                    isDisabled={outcome !== NewCustomerCallOutcomeEnum.Other}
                    value={outcome === NewCustomerCallOutcomeEnum.Other ? outcomeDescription : ''}
                    onChange={(e) => {
                      setOutcomeDescriptionError('');
                      setOutcomeDescription(e.target.value);
                    }}
                  />
                  <FormErrorMessage>{outcomeDescriptionError}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Stack>
          </ChakraRadioGroup>
        </>
      )}
    </Modal>
  );
};

export default PostCallSurveyModal;
