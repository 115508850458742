import { useContext, useState } from 'react';

import { HStack, IconButton, Stack, StackProps, useBreakpointValue } from '@chakra-ui/react';
import { MdAddCircle, MdPhone, MdSmartphone } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import TransferCallModal from '../Calls/transferCallModal';
import GlobalFiltersMenu from '../GlobalFiltersMenu';
import WebLeadSuggest from '../Suggest/WebLeadSuggest';
import DynamicSizedButton from '../shared/DynamicSizedButton';

import config from '../../config';
import { PermissionEnum } from '../../constants/permissions';
import ROUTES from '../../constants/routes';
import { Can } from '../../libs/Can';
import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import { CurrentView } from '../../pages/Dashboard';

const ButtonsRow = ({ ...rest }: StackProps) => {
  const abilities = useContext(AbilityContext);
  const {
    filters: {
      global: { currentView },
    },
  } = useContext(FiltersContext);

  const [showOutboundCallModal, setShowOutboundCallModal] = useState(false);

  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <>
      <Stack
        direction="row"
        py={2}
        px={{ base: 2, sm: 8 }}
        bgColor="queenBlue"
        justify="space-between"
        wrap="wrap"
        {...rest}
      >
        <HStack>
          {(!abilities.has(PermissionEnum.TitleClerk) ||
            abilities.has(PermissionEnum.SuperUser)) && (
            <>
              <Can I={PermissionEnum.CreateEstimate}>
                <DynamicSizedButton
                  ariaLabel="new-estimate"
                  icon={<MdAddCircle size="20px" />}
                  label="New Estimate"
                  size="md"
                  onClick={() => history.push(ROUTES.NEW_ESTIMATE)}
                />
              </Can>
              <Can I={PermissionEnum.SeeAIChat}>
                <DynamicSizedButton
                  ariaLabel="engage-new-drivers"
                  icon={<MdSmartphone size="20px" />}
                  label="Engage New Drivers"
                  size="md"
                  onClick={() => window.open(config.urls.chatUiRoot, '_blank')}
                />
              </Can>
            </>
          )}
          <IconButton
            aria-label="Outbound Call"
            icon={<MdPhone size="20px" />}
            borderRadius="full"
            size="md"
            onClick={() => setShowOutboundCallModal(true)}
          />
        </HStack>
        {currentView !== CurrentView.PayoffRequestsView && isMobile && (
          <GlobalFiltersMenu isMobile />
        )}
        {!isMobile && (
          <HStack>
            <Can I={PermissionEnum.SeeWebLeadsSearch}>
              <WebLeadSuggest />
            </Can>
            {currentView !== CurrentView.PayoffRequestsView ? <GlobalFiltersMenu /> : null}
          </HStack>
        )}
      </Stack>
      <TransferCallModal
        isOutboundCallModal
        showModal={showOutboundCallModal}
        closeOutboundModal={setShowOutboundCallModal}
      />
    </>
  );
};

export default ButtonsRow;
