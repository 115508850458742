import { useState } from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';

import { Customer } from '../../gql/customerGql';

import MaskedSsnButton from './MaskedSsnButton';

type SsnMaskProps = {
  type: 'form' | 'payoff' | 'customerInfo';
};

export const SsnMask = ({ type }: SsnMaskProps) => {
  return (
    <Box mb={type === 'customerInfo' ? 1 : undefined}>
      <MdCircle size={8} />
      <MdCircle size={8} />
      <MdCircle size={8} style={{ marginRight: '5px' }} />
      <MdCircle size={8} />
      <MdCircle size={8} style={{ marginRight: '5px' }} />
      <MdCircle size={8} />
      <MdCircle size={8} />
      <MdCircle size={8} />
      <MdCircle size={8} style={{ marginRight: type === 'payoff' ? '32px' : '14px' }} />
    </Box>
  );
};

type MaskedSSNProps = {
  customer: Customer;
};

const MaskedSsnCustomerInfo = ({ customer }: MaskedSSNProps) => {
  const [ssnHidden, setSsnHidden] = useState(true);
  const ssn = customer?.ssn;
  return ssn ? (
    <HStack maxWidth="100%" spacing="auto">
      {ssnHidden ? <SsnMask type="customerInfo" /> : <Text mr="17px">{ssn}</Text>}
      <MaskedSsnButton type="customerInfo" customerId={customer.id} onClick={setSsnHidden} />
    </HStack>
  ) : null;
};

export default MaskedSsnCustomerInfo;
