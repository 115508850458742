import { useContext, useRef } from 'react';

import { Button, useDisclosure } from '@chakra-ui/react';
import { set } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';

import { FollowUpTypeEnum, useFollowUpInsertMutation } from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import FollowUpButton from './FollowUpButton';
import FollowUpForm from './FollowUpForm';
import { APPOINTMENT_ALREADY_SCHEDULED_ERROR_MESSAGE } from './constants';

import { DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';
import { FollowUpFormType, followUpValidationSchema } from '../../utils/validation/followUp';

const AddFollowUp = () => {
  const { deal } = useContext(DealContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [insertFollowUp, { loading }] = useFollowUpInsertMutation();

  const initialValues: FollowUpFormType = {
    date: undefined,
    time: undefined,
    type: undefined,
    note: null,
  };
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const handleSubmit = async (values: FollowUpFormType) => {
    if (!deal.id || !values.date || !values.time || !values.type) {
      return;
    }

    const hours = values.time.getHours();
    const minutes = values.time.getMinutes();

    try {
      await insertFollowUp({
        variables: {
          deal_id: deal.id,
          date_utc: set(values.date, {
            hours,
            minutes,
            seconds: 0,
            milliseconds: 0,
          }),
          tz: getTimezoneStr(),
          type: values.type as FollowUpTypeEnum,
          note: values.note,
        },
      });
      toast.success('Follow-Up added');
      onClose();
    } catch (e) {
      const error = e as Error;
      if (error.message === APPOINTMENT_ALREADY_SCHEDULED_ERROR_MESSAGE) {
        formikRef.current?.setFieldError('time', error.message);
        return;
      }

      logger.error('AddFollowUp.tsx', 'insertFollowUp', error);
      toast.error(`Error adding Follow-Up: ${error.message || error}`);
    }
  };

  return (
    <>
      <FollowUpButton onOpen={onOpen} />

      <Formik
        initialValues={initialValues}
        validationSchema={followUpValidationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ isValid }) => (
          <Modal
            title="Add Follow-Up"
            hasForm
            isOpen={isOpen}
            onClose={onClose}
            leftButtons={
              <Button variant="warning" onClick={onClose}>
                Cancel
              </Button>
            }
            rightButtons={
              <Button
                type="submit"
                isLoading={loading}
                loadingText="Add Follow-Up"
                isDisabled={!isValid}
              >
                Add Follow-Up
              </Button>
            }
            scrollBehavior="outside"
          >
            <FollowUpForm />
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddFollowUp;
