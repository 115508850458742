import { useContext, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';

import { useKeyMetricsQuery } from '../../gql/generated/graphql';

import Loader from '../Loader';

import DateForm from './DateForm';
import MetricsTable from './MetricsTable';

import { MetricType } from '../../constants/metrics';
import { useUserAbilityAndVisibleStates } from '../../hooks/useUserAbilityAndVisibleStates';
import { FiltersContext } from '../../libs/contextLib';
import { isNullOrUndefined } from '../../utils';

export interface KeyMetric {
  keyMetric: MetricType;
  value: {
    buyout: number;
    refi: number;
    total: number;
  };
}

const KeyMetricsContainer = () => {
  const {
    filters: {
      global: { selectedPodId },
    },
  } = useContext(FiltersContext);

  const [startDate, setStartDate] = useState<Date>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [keyMetrics, setKeyMetrics] = useState<KeyMetric[]>([]);

  const { loggedUserSalesPods } = useUserAbilityAndVisibleStates();

  const salesPodsIds = selectedPodId ? [selectedPodId] : loggedUserSalesPods.map((pod) => pod?.id);
  const {
    data: keyMetricsGql,
    loading,
    refetch: keyMetricsRefetch,
  } = useKeyMetricsQuery({
    variables: {
      start_date: startOfDay(startDate),
      end_date: endOfDay(endDate),
      pod_ids: salesPodsIds.reduce(
        (acc, id) => (id ? [...acc, id.toString()] : acc),
        [] as string[],
      ),
    },
    fetchPolicy: 'cache-and-network',
    // skip is broken in @apollo/client: "^3.3.15"
    // even with the skip it still sends 1 request to the server
    // with [null], however without the skip it sends 2 requests
    // with [null] to the server
    skip: salesPodsIds.every((id) => isNullOrUndefined(id)),
  });

  useEffect(() => {
    keyMetricsRefetch();
  }, [startDate, endDate]);

  useEffect(() => {
    if (keyMetricsGql?.keyMetrics) {
      setKeyMetrics(keyMetricsGql.keyMetrics as KeyMetric[]);
    }
  }, [keyMetricsGql]);

  return (
    <>
      <Box textAlign="center" py="10px" bg="brightGray" border="1px" borderColor="gray.300">
        Key Metrics
      </Box>
      <Box p="0.5rem" overflowX="hidden" overflowY="auto">
        <Loader isLoading={loading} />
        <DateForm
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
        <MetricsTable keyMetrics={keyMetrics} />
      </Box>
    </>
  );
};

export default KeyMetricsContainer;
