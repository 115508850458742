import { useContext } from 'react';

import { Box, Flex, FlexboxProps, LayoutProps } from '@chakra-ui/react';
import { endOfToday, subDays } from 'date-fns/esm';

import CompletedDealsTable from '../CompletedDealsTables/CompletedDealsTable';
import Inbox from '../Inbox';

import { PermissionEnum } from '../../constants/permissions';
import { DashboardInboxProvider } from '../../libs/DashboardInboxContext';
import { AbilityContext } from '../../libs/contextLib';

interface BottomSectionProps {
  minW?: LayoutProps['minW'];
  maxW?: LayoutProps['maxW'];
  flexDir?: FlexboxProps['flexDir'];
}

const BottomSection = ({
  minW = '620px',
  maxW = '1530px',
  flexDir = 'column',
}: BottomSectionProps) => {
  const ability = useContext(AbilityContext);

  const today = endOfToday();
  const fiveDaysAgo = subDays(today, 5);

  return (
    <Flex gap={2} flexDir={flexDir} minW={minW} maxW={maxW} flexGrow={1}>
      <Box>
        <DashboardInboxProvider>
          <Inbox />
        </DashboardInboxProvider>
      </Box>
      <Box>
        {(ability.has(PermissionEnum.SeeCompletedBuyoutDeals) ||
          ability.has(PermissionEnum.SeeCompletedRefiDeals)) &&
          !ability.has(PermissionEnum.SuperUser) && (
            <CompletedDealsTable startDate={fiveDaysAgo} endDate={today} />
          )}
      </Box>
    </Flex>
  );
};

export default BottomSection;
