import { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

import { Deal } from '../../gql/dealGql';
import {
  Address,
  Customer,
  OriginalLessee,
  useAddressDetailLazyQuery,
} from '../../gql/generated/graphql';

import { zipRegExp } from '../../utils/validation/address';

type AutoExplodeZipProps = {
  name: string;
  setZipLoading: (loading: boolean) => void;
  isAddressLineFocused?: boolean | undefined;
  isPrevAddress?: boolean;
  isOriginalLessee?: boolean;
};

export const AutoExplodeZip = ({
  name,
  setZipLoading,
  isAddressLineFocused = false,
  isPrevAddress = false,
  isOriginalLessee = false,
}: AutoExplodeZipProps) => {
  const objectName: keyof Pick<Customer, 'address' | 'prev_address'> = isPrevAddress
    ? 'prev_address'
    : 'address';

  const { setFieldValue, values } = useFormikContext<Deal>();
  const valuesObject = !isOriginalLessee
    ? ((values?.[name as string]?.[objectName] || {}) as Address) // customer/co-buyer
    : (values?.original_lessee as OriginalLessee); // original lessee

  const { address_line, zip, city, state, county } = valuesObject;

  const [zipChanged, setZipChanged] = useState(false);
  const [initialZip] = useState(zip ?? '');
  const [addressLineChanged, setAddressLineChanged] = useState(false);
  const [initialAddressLine] = useState(address_line ?? '');

  const [loadAddressDetails, { loading }] = useAddressDetailLazyQuery({
    onCompleted: (data) => {
      if (
        !data?.addressDetail ||
        (!data?.addressDetail?.city && !data?.addressDetail?.state && !data?.addressDetail?.county)
      ) {
        return;
      }
      const fieldName = !isOriginalLessee ? `${name}.${objectName}` : 'original_lessee';

      setFieldValue(`${fieldName}.city`, data.addressDetail.city ?? '', true);
      setFieldValue(`${fieldName}.state`, data.addressDetail.state ?? '', true);
      setFieldValue(`${fieldName}.county`, data.addressDetail.county ?? '', true);
    },
    onError: () => {
      toast.error('Failed to get location');
    },
  });

  useEffect(() => {
    setZipLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (
      zip?.match(zipRegExp) &&
      !isAddressLineFocused &&
      !loading &&
      (zipChanged || addressLineChanged)
    ) {
      loadAddressDetails({
        variables: { address: address_line, zipCode: zip },
      });
    }
  }, [zip, address_line, isAddressLineFocused]);

  useEffect(() => {
    if (zip?.match(zipRegExp) && (!city || !state || !county)) {
      loadAddressDetails({
        variables: { address: address_line, zipCode: zip },
      });
    }
  }, []);

  useEffect(() => {
    if (!zipChanged) {
      setZipChanged(zip !== initialZip);
    }
    if (!addressLineChanged) {
      setAddressLineChanged(address_line !== initialAddressLine);
    }
  }, [zip, address_line]);

  return null;
};
