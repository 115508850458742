import { Dispatch, SetStateAction, useContext, useState } from 'react';

import { Box, BoxProps, FormLabel, HStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import { TotalPayoff } from '../TotalPayoff/TotalPayoff';
import CardSubHeaderV2 from '../shared/Card/components/CardSubHeaderV2';
import DatePicker from '../shared/DatePicker';
import { GridFormColumn, GridFormRow } from '../shared/GridForm';
import LenderAutocomplete from '../shared/LenderAutocomplete';
import LienholderSelects from '../shared/LienholderSelects';
import NumberInput from '../shared/NumberInput';
import RadioButtons from '../shared/RadioButtons';
import { RadioGroup } from '../shared/RadioGroup';
import Tooltip from '../shared/Tooltip';

import { LienholderLenderDetails } from './LienholderLenderDetails';

import SELECT_OPTIONS from '../../constants/selectOptions';
import { DealContext } from '../../libs/DealContext';
import { getFieldLabel } from '../../utils/deals';

interface PayoffFormProps extends BoxProps {
  requiredCombinations: string[][];
  setRequiredCombinations: Dispatch<SetStateAction<string[][]>>;
  totalPayoffFieldErrors: (string | undefined)[];
  isPayoffFieldDisabled: boolean;
  isRecalculatingPayoff: boolean;
  setIsRecalculatingPayoff: Dispatch<SetStateAction<boolean>>;
  isPayoffRequestLoading: boolean;
  setIsPayoffRequestLoading: Dispatch<SetStateAction<boolean>>;
  isSaving: boolean;
  setDuplicateDeals?: Dispatch<SetStateAction<Deal[]>>;
}

export const PayoffForm = ({
  requiredCombinations,
  setRequiredCombinations,
  totalPayoffFieldErrors,
  isPayoffFieldDisabled,
  isRecalculatingPayoff,
  setIsRecalculatingPayoff,
  isPayoffRequestLoading,
  setIsPayoffRequestLoading,
  isSaving,
  ...rest
}: PayoffFormProps) => {
  const { isPayoffRequested } = useContext(DealContext);

  const { values, setFieldValue } = useFormikContext<Deal>();

  const [showVirtualLienholderSelect, setShowVirtualLienholderSelect] = useState<boolean>(false);

  const isRefi = values.type === DealType.Refi;

  return (
    <Box {...rest}>
      <CardSubHeaderV2 title="Payoff Information" />
      <GridFormColumn>
        <GridFormRow minChildWidth={135}>
          <Box gridColumn="span 1">
            <RadioButtons name="type" label="Deal Type" options={SELECT_OPTIONS.DEAL_TYPES} />
          </Box>
          {isRefi ? (
            <Box gridColumn="span 2">
              <LenderAutocomplete />
            </Box>
          ) : (
            <Box gridColumn={showVirtualLienholderSelect ? 'span 2' : 'span 1'}>
              <LienholderSelects
                showVirtualLienholderSelect={showVirtualLienholderSelect}
                setShowVirtualLienholderSelect={setShowVirtualLienholderSelect}
              />
            </Box>
          )}
          <Box />
        </GridFormRow>
        <HStack w="100%">
          <LienholderLenderDetails
            requiredCombinations={requiredCombinations}
            setRequiredCombinations={setRequiredCombinations}
            isPayoffRequestLoading={isPayoffRequestLoading}
            setIsPayoffRequestLoading={setIsPayoffRequestLoading}
            isSaving={isSaving}
          />
        </HStack>
        <GridFormRow minChildWidth={135}>
          <Tooltip errors={totalPayoffFieldErrors}>
            <TotalPayoff
              topLabel
              isDisabled={isPayoffFieldDisabled}
              isRecalculating={isRecalculatingPayoff}
              setIsRecalculating={setIsRecalculatingPayoff}
            />
          </Tooltip>
          <DatePicker
            name="car.payoff.good_through_date"
            topLabel="Good Through Date"
            isDisabled={isPayoffRequested}
            valueFormat="dateUTC"
          />
          <DatePicker
            topLabel="Next Payment Date"
            name="car.payoff.next_payment_date"
            valueFormat="dateUTC"
          />
          <NumberInput
            label={getFieldLabel('oldPayment', values.type)}
            name="car.payoff.old_lease_payment"
            isMoney
            showThousandSeparator
          />
        </GridFormRow>
        <GridFormRow minChildWidth={135}>
          <Box>
            <FormLabel fontSize="sm" mb={3}>
              Is sales tax included in total payoff?
            </FormLabel>
            <RadioGroup
              onChange={(e) => {
                setFieldValue('car.payoff.payoff_includes_sales_tax', e === 'true');

                if (e === 'false') {
                  setFieldValue('car.payoff.sales_tax_from_payoff_entered_manually', false);
                }
              }}
              value={values.car.payoff?.payoff_includes_sales_tax}
              isDisabled={isPayoffFieldDisabled}
            />
          </Box>
          {values.car.payoff?.payoff_includes_sales_tax ? (
            <>
              <Box>
                <FormLabel fontSize="sm" mb={3}>
                  Do you know the sales tax amount?
                </FormLabel>
                <RadioGroup
                  onChange={(e) => {
                    setFieldValue(
                      'car.payoff.sales_tax_from_payoff_entered_manually',
                      e === 'true',
                    );
                  }}
                  value={values.car.payoff?.sales_tax_from_payoff_entered_manually}
                  isDisabled={isPayoffFieldDisabled}
                />
              </Box>
              {values.car.payoff?.sales_tax_from_payoff_entered_manually ? (
                <>
                  <NumberInput
                    label="Included Sales Tax"
                    name="car.payoff.sales_tax_from_payoff"
                    isMoney
                    showThousandSeparator
                    isDisabled={isPayoffFieldDisabled}
                  />
                  <Box />
                </>
              ) : (
                <>
                  <Box />
                  <Box />
                </>
              )}
            </>
          ) : (
            <>
              <Box />
              <Box />
              <Box />
            </>
          )}
        </GridFormRow>
      </GridFormColumn>
    </Box>
  );
};
