import { optionalStringValidation, requiredStringValidation } from './strings';

import { isLocal, isStaging } from '../../config';

export const ssnRegExpLast4Min = /^(?!(000|666|9))[X_0-9]{3}-(?!00)[X_0-9]{2}-(?!0000)\d{4}$/;
export const ssnRegExpLast6Min = /^(?!(000|666|9))[X_0-9]{3}-(?!00)[0-9]{2}-(?!0000)\d{4}$/;
export const ssnRegExpFull = /^(?!(000|666|9))[0-9]{3}-(?!00)[0-9]{2}-(?!0000)\d{4}$/;

export const optionalSsnValidation =
  isLocal || isStaging
    ? optionalStringValidation
    : optionalStringValidation.matches(ssnRegExpFull, `Please input a valid SSN`);

export const fullSsnValidation = (person?: string) =>
  isLocal || isStaging
    ? requiredStringValidation(`${person || ''}SSN is required`)
    : requiredStringValidation(`${person || ''}SSN is required`).matches(
        ssnRegExpFull,
        `Please input a valid SSN`,
      );
