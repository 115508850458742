import { EmploymentStatusType, IncomeIntervalEnumType } from './generated/graphql';

import { capitalizeFirstLetter } from '../utils/text';

// `Weekly` and `BiWeekly` are deprecated but keeping them for backwards compatibility.
export const getPayFrequencyOptions = (isUneditable: boolean) =>
  Object.values(IncomeIntervalEnumType)
    .filter(
      (frequency) =>
        isUneditable ||
        (frequency !== IncomeIntervalEnumType.Weekly &&
          frequency !== IncomeIntervalEnumType.Biweekly),
    )
    .map((frequency: IncomeIntervalEnumType) => ({
      label: capitalizeFirstLetter(frequency),
      value: frequency,
    }));

export enum EmploymentStatusDisplay {
  employed = 'Employed',
  not_employed = 'Not Employed',
  retired = 'Retired',
}

export class Employment {
  id?: number;
  status: EmploymentStatusType;
  name?: string;
  job_title?: string;
  phone_number?: string;
  years_at_job?: number;
  months_at_job?: number;
  gross_income?: number;
  pay_frequency?: IncomeIntervalEnumType;

  constructor() {
    this.status = EmploymentStatusType.Employed;
    this.name = '';
    this.job_title = '';
    this.phone_number = '';
    this.pay_frequency = IncomeIntervalEnumType.Annually;
  }
}
