/* eslint-disable func-names */
import { string } from 'yup';

export const emailValidationOptional = (person?: string) =>
  string()
    .nullable()
    .email(`Please input a valid ${person || ''}email address`)
    .lowercase()
    .notOneOf(['idk@email.com', 'no@email.com'], `If no email, please leave field blank`);

export const emailValidationRequired = (person?: string) =>
  emailValidationOptional(person).required(`${person || ''}Email is required`);

export const conditionalEmailValidation = (person?: string) =>
  string()
    .email(`Please input a valid ${person || ''}email address`)
    .lowercase()
    .notOneOf(['idk@email.com', 'no@email.com'], `If no email, please leave field blank`)
    .test('customer.no_email', `${person || ''} Email or checkbox required`, function () {
      const p =
        (person === 'Cobuyer ' ? this.options.context?.cobuyer : this.options.context?.customer) ||
        {};
      const { email, no_email } = p;

      const emailIsRequired = !no_email;
      if (emailIsRequired) {
        return !!email;
      }
      return true;
    })
    .nullable();
