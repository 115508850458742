/* eslint-disable func-names */
import { useContext, useState } from 'react';

import { Box, Button, Input as ChakraInput, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { Deal } from '../../gql/dealGql';

import MaskedSsnInput from '../MaskedSsn/MaskedSsnInput';
import CardSubHeaderV2 from '../shared/Card/components/CardSubHeaderV2';
import Input from '../shared/Input';
import Select from '../shared/Select';

import { phoneNumberMask } from '../../constants/masks';
import SELECT_OPTIONS from '../../constants/selectOptions';
import { DealContext } from '../../libs/DealContext';
import { passValuesToSchema } from '../../libs/utils';
import { dobValidation } from '../../libs/yup-validators/dob';
import { zipRegExp } from '../../utils/validation/address';
import { phoneRegExp } from '../../utils/validation/phoneNumber';

interface CustomerInfoAcquisitionFormProps {
  onSubmit: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
}

const vSchema = Yup.object().shape({
  customer: Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_number: Yup.string()
      .matches(phoneRegExp, {
        message: 'Please input a valid phone number',
        excludeEmptyString: true,
      })
      .test('phone_test', 'Phone number is required', function () {
        const { home_phone_number, phone_number } = this.options.context?.customer;

        const phoneNumberRequired = !home_phone_number;
        if (phoneNumberRequired) {
          return !!phone_number;
        }
        return true;
      }),
    home_phone_number: Yup.string().matches(phoneRegExp, {
      message: 'Please input a valid phone number',
      excludeEmptyString: true,
    }),
    email: Yup.string().optional().email('Please input a valid email address'),
    dob: dobValidation(),
    address: Yup.object().shape({
      address_line: Yup.string().required('Address is required'),
      address_line_2: Yup.string().optional().nullable(),
      zip: Yup.string().matches(zipRegExp, 'Please enter a valid zip code'),
    }),
  }),
});

const CustomerPersonalInfo = () => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Deal>();

  const name = 'customer';
  const cust = values.customer;
  const touchedCust = touched.customer;
  const errCust = errors.customer;

  return (
    <>
      <CardSubHeaderV2 title="Personal Info" />
      <Box p={4}>
        <HStack>
          <Input
            name={`${name}.first_name`}
            label="First Name"
            value={cust.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.first_name && !!errCust?.first_name}
          />
          <Input
            name={`${name}.middle_name`}
            label="Middle Name"
            value={cust.middle_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.middle_name && !!errCust?.middle_name}
          />
          <Input
            name={`${name}.last_name`}
            label="Last Name"
            value={cust.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.last_name && !!errCust?.last_name}
          />
        </HStack>
        <HStack>
          <Input
            label="Cell Phone Number"
            name={`${name}.phone_number`}
            value={cust.phone_number}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.phone_number && !!errCust?.phone_number}
            mask={phoneNumberMask}
          />
          <Input
            label="Home Phone Number"
            name={`${name}.home_phone_number`}
            value={cust.home_phone_number}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.home_phone_number && !!errCust?.home_phone_number}
            mask={phoneNumberMask}
          />
          <Input
            label="Email"
            name={`${name}.email`}
            value={cust.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.email && !!errCust?.email}
          />
        </HStack>
        <HStack>
          <Input
            label="Address Line 1"
            name={`${name}.address.address_line`}
            value={cust.address?.address_line}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.address_line && !!errCust?.address?.address_line}
          />
          <Input
            label="Address Line 2"
            name={`${name}.address.address_line_2`}
            value={cust.address?.address_line_2}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.address_line_2 && !!errCust?.address?.address_line_2}
          />
        </HStack>
        <HStack>
          <Input
            label="Zip Code"
            name={`${name}.address.zip`}
            value={cust.address?.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.zip && !!errCust?.address?.zip}
          />
          <Input
            label="City"
            name={`${name}.address.city`}
            value={cust.address?.city}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.city && !!errCust?.address?.city}
          />
          <Select
            label="State"
            id="customer.address.state"
            name={`${name}.address.state`}
            defaultValue={cust.address?.state}
            options={SELECT_OPTIONS.US_STATES}
            isRequired
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.state && !!errCust?.address?.state}
          />
          <Input
            label="County"
            name={`${name}.address.county`}
            value={cust.address?.county}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touchedCust?.address?.county && !!errCust?.address?.county}
          />
        </HStack>
        <HStack>
          <MaskedSsnInput name={`${name}.ssn`} customHandleChange={handleChange} needsHidden />
          <Stack>
            <Text fontSize="sm">Date of Birth</Text>
            <ChakraInput
              type="date"
              name={`${name}.dob`}
              value={cust?.dob}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touchedCust?.dob && !!errCust?.dob}
            />
          </Stack>
        </HStack>
      </Box>
    </>
  );
};

const CustomerInfoAcquisitionForm = ({ onSubmit }: CustomerInfoAcquisitionFormProps) => {
  const { deal } = useContext(DealContext);
  const [isSaving, setSaving] = useState<boolean>(false);

  return (
    <Formik
      validate={(value) => passValuesToSchema(value, vSchema)}
      onSubmit={(values: Deal, { setSubmitting }: FormikHelpers<Deal>) => {
        onSubmit({ ...values }, setSubmitting);
      }}
      initialValues={deal}
      enableReinitialize
    >
      {({ handleSubmit, values, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <CustomerPersonalInfo />
          <Flex justify="right" m={4}>
            <Button
              isLoading={isSaving}
              loadingText="SAVE"
              onClick={() => {
                if (!isValid) {
                  return;
                }

                setSaving(true);
                onSubmit(values, setSaving);
              }}
            >
              SAVE
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerInfoAcquisitionForm;
