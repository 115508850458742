/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext } from 'react';

import {
  ModalProps,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useExperianCreditReportQuery } from '../../../../gql/generated/graphql';

import Modal from '../../../shared/Modal';

import CreditReport from './CreditReport';

import { DealContext } from '../../../../libs/DealContext';
import { PrequalCheckStatusType } from '../../../../utils/prequalification';

type CreditInfo = { creditScore?: number; creditStatus?: PrequalCheckStatusType };
export type PrequalificationCreditReportModalProps = Omit<ModalProps, 'children'> & {
  buyerCreditInfo?: CreditInfo;
  cobuyerCreditInfo?: CreditInfo;
};

const PrequalificationCreditReportModal = ({
  buyerCreditInfo,
  cobuyerCreditInfo,
  ...rest
}: PrequalificationCreditReportModalProps) => {
  // TODO: This may need to change if we add the prequal button to the Structuring Manager Page
  // because that page will not be in a DealContext
  const {
    deal: { id: dealId },
  } = useContext(DealContext);

  const {
    data: creditReportData,
    loading: creditReportLoading,
    error: creditReportError,
  } = useExperianCreditReportQuery({
    skip: !dealId || !rest.isOpen,
    variables: { dealId: dealId! },
    nextFetchPolicy: 'cache-and-network',
  });

  const customerCreditReport = creditReportData?.experianCreditReport?.customer;
  const cobuyerCreditReport = creditReportData?.experianCreditReport?.cobuyer;

  return (
    <Modal title="Full Credit Report" size="6xl" {...rest}>
      {creditReportLoading ? (
        <VStack w="full" justifyContent="center" alignItems="center">
          <Text>Fetching the Credit Report now...</Text>
          <Spinner size="lg" />
        </VStack>
      ) : creditReportError ? (
        <Text>There was an error! Try again later</Text>
      ) : cobuyerCreditInfo && cobuyerCreditReport ? (
        <Tabs>
          <TabList>
            <Tab>Customer</Tab>
            <Tab>Cobuyer</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <CreditReport
                creditReport={customerCreditReport}
                creditScore={buyerCreditInfo?.creditScore}
                creditStatus={buyerCreditInfo?.creditStatus}
              />
            </TabPanel>
            <TabPanel>
              <CreditReport
                creditReport={cobuyerCreditReport}
                creditScore={cobuyerCreditInfo.creditScore}
                creditStatus={cobuyerCreditInfo.creditStatus}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <CreditReport
          creditReport={customerCreditReport}
          creditScore={buyerCreditInfo?.creditScore}
          creditStatus={buyerCreditInfo?.creditStatus}
        />
      )}
    </Modal>
  );
};

export default PrequalificationCreditReportModal;
