import { Flex, SystemStyleObject } from '@chakra-ui/react';

import { Customer, getFullName } from '../../../gql/customerGql';

import NotificationsAvatar from '../../NotificationBadge/NotificationAvatar';

type CustomerBubbleProps = {
  customer: Customer;
  handleClick: () => void;
  isActive: boolean;
  unreadCount?: number;
};
const activeTriangle: SystemStyleObject = {
  content: '""',
  position: 'absolute',
  bottom: '-12px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '0',
  height: '0',
  borderLeft: '12px solid transparent',
  borderRight: '12px solid transparent',
  borderTop: '12px solid',
  borderTopColor: 'gray.300',
  zIndex: 1,
};

const ContactBox = ({ customer, handleClick, isActive, unreadCount = 0 }: CustomerBubbleProps) => (
  <Flex
    bgColor={isActive ? 'gray.300' : 'white'}
    w="175px"
    cursor="pointer"
    onClick={handleClick}
    align="center"
    justify="center"
    px={4}
    gap={2}
    borderBottom={isActive ? '2px solid' : 'none'}
    borderColor={isActive ? 'gray.300' : 'transparent'}
    position="relative"
    _after={isActive ? activeTriangle : {}}
  >
    {getFullName(customer).substring(0, 18)}
    <NotificationsAvatar count={unreadCount} />
  </Flex>
);

export default ContactBox;
