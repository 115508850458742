import { useMemo, useRef } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Box, Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { set } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { BiEdit } from 'react-icons/bi';
import { toast } from 'react-toastify';

import {
  FollowUp,
  FollowUpTypeEnum,
  GetFollowUpDealsPaginatedQuery,
  useFollowUpUpdateMutation,
} from '../../gql/generated/graphql';

import Modal from '../shared/Modal';

import DeleteFollowUp from './DeleteFollowUp';
import FollowUpButton from './FollowUpButton';
import FollowUpForm from './FollowUpForm';
import { APPOINTMENT_ALREADY_SCHEDULED_ERROR_MESSAGE } from './constants';

import { logger } from '../../libs/Logger';
import { getTimezoneStr } from '../../libs/utils';
import { FollowUpFormType, followUpValidationSchema } from '../../utils/validation/followUp';

type EditFollowUpProps = {
  followUp: FollowUp;
  refetch?: () => Promise<ApolloQueryResult<GetFollowUpDealsPaginatedQuery>>;
  showIconButton?: boolean;
};

const EditFollowUp = ({ followUp, refetch, showIconButton = false }: EditFollowUpProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateFollowUp, { loading }] = useFollowUpUpdateMutation();

  const initialValues: FollowUpFormType = useMemo(
    () => ({
      date: new Date(followUp.date_utc),
      time: new Date(followUp.date_utc),
      type: followUp.type ?? undefined,
      note: followUp.note,
    }),
    [followUp],
  );
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const handleSubmit = async (values: FollowUpFormType) => {
    if (!followUp.id || !followUp.deal_id || !values.date || !values.time || !values.type) {
      return;
    }

    const hours = values.time.getHours();
    const minutes = values.time.getMinutes();

    try {
      await updateFollowUp({
        variables: {
          id: followUp.id,
          deal_id: followUp.deal_id,
          date_utc: set(values.date, {
            hours,
            minutes,
            seconds: 0,
            milliseconds: 0,
          }),
          tz: getTimezoneStr(),
          type: values.type as FollowUpTypeEnum,
          note: values.note,
        },
      });
      toast.success('Follow-Up updated');
      await refetch?.();
      onClose();
    } catch (e) {
      const error = e as Error;
      if (error.message === APPOINTMENT_ALREADY_SCHEDULED_ERROR_MESSAGE) {
        formikRef.current?.setFieldError('time', error.message);
        return;
      }

      logger.error('EditFollowUp.tsx', 'updateFollowUp', error);
      toast.error(`Error updating Follow-Up: ${error.message || error}`);
    }
  };

  return (
    <>
      {showIconButton ? (
        <Box pos="relative">
          <IconButton
            aria-label="edit follow-up"
            size="xs"
            variant="iconHover"
            onClick={(e) => {
              e.stopPropagation();
              onOpen();
            }}
            icon={<BiEdit />}
          />
        </Box>
      ) : (
        <FollowUpButton hasFollowUp onOpen={onOpen} />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={followUpValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ isValid }) => (
          <Modal
            title="Edit Follow-Up"
            hasForm
            isOpen={isOpen}
            onClose={onClose}
            leftButtons={<DeleteFollowUp followUp={followUp} />}
            rightButtons={
              <Button
                type="submit"
                isLoading={loading}
                loadingText="Save Changes"
                isDisabled={!isValid}
              >
                Save Changes
              </Button>
            }
            scrollBehavior="outside"
          >
            <FollowUpForm />
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditFollowUp;
