import { useEffect, useState } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { MdPhoneDisabled, MdPhoneInTalk } from 'react-icons/md';

import { useUpdateUserCallStatusMutation } from '../../gql/generated/graphql';

import button from '../../chakra/components/button';
import ActiveMenuItem from '../Navbar/ActiveMenuItem';

import { useUser } from '../../hooks/useUser';

const options = [
  { label: 'ACTIVE', value: 'active' },
  { label: 'INACTIVE', value: 'inactive' },
];

export const getOptionColor = (option: string | null) => {
  const colors = {
    red: '#BE170E',
    green: '#8CC243',
  };

  switch (option) {
    case 'inactive':
      return colors.red;
    default:
      return colors.green;
  }
};

type UserStatusSwitcherProps = {
  isAuthenticated: boolean;
  isMobile?: boolean;
};

export const UserStatusSwitcher = ({
  isAuthenticated,
  isMobile = false,
}: UserStatusSwitcherProps) => {
  const [updateStatus] = useUpdateUserCallStatusMutation();
  const loggedUserData = useUser();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const optionColor = getOptionColor(selectedOption);
  // eslint-disable-next-line no-underscore-dangle
  const activeNavBarButtonVariant = button.variants.navBar._active;
  const styleProps = { ...activeNavBarButtonVariant, bg: optionColor };

  const handleChange = async (value: string) => {
    await updateStatus({
      variables: {
        status: value,
      },
      optimisticResponse: {
        updateUserCallStatus: {
          __typename: 'user',
          id: loggedUserData.id,
          call_status: value,
        },
      },
    });
  };

  const toggleSwitch = () => {
    const newStatus = selectedOption === 'active' ? 'inactive' : 'active';
    handleChange(newStatus);
  };

  useEffect(() => {
    const loggedUser = loggedUserData;
    if (!loggedUser?.call_status) {
      return;
    }

    if (loggedUser.call_status === 'direct') {
      handleChange('active');
      return;
    }

    setSelectedOption(loggedUser.call_status);
  }, [loggedUserData.call_status]);

  if (!isAuthenticated) {
    return null;
  }

  return isMobile ? (
    <IconButton
      aria-label="switch-call-status"
      icon={
        selectedOption === 'active' ? (
          <MdPhoneInTalk size="20px" />
        ) : (
          <MdPhoneDisabled size="20px" />
        )
      }
      w="70px"
      borderRadius="15px"
      variant="unset"
      color="white"
      bg={optionColor}
      size="sm"
      onClick={toggleSwitch}
    />
  ) : (
    <Menu>
      <MenuButton
        as={Button}
        variant="navBar"
        size={{ base: 'sm', sm: 'md' }}
        m={0}
        w={{ base: 'full', sm: '140px' }}
        rightIcon={<ChevronDownIcon w="2em" h="2em" />}
        _hover={styleProps}
        _active={styleProps}
        {...styleProps}
      >
        {selectedOption ?? 'Select an option'}
      </MenuButton>
      <MenuList zIndex="dropdown" fontSize={{ base: 'xs', sm: 'md' }}>
        {options.map(({ label, value }) => (
          <ActiveMenuItem
            key={value}
            isActive={selectedOption === value}
            onClick={() => handleChange(value)}
          >
            {label}
          </ActiveMenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
