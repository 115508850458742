import { useMemo } from 'react';

import { Deal } from '../gql/dealGql';
import {
  DealMediaTypeEnum,
  DealType,
  JurisdictionQueryVariables,
  Maybe,
  StateAbbreviation,
  TitleRegistrationOption,
  TtDocumentName,
  TtProductType,
  useJurisdictionQuery,
} from '../gql/generated/graphql';
import { TTValidationName, mapBankEnumToTTNewBankEnum } from '../gql/taterTitleGql';

import { BankEnum } from '../constants/bank';
import { isValidStateAbbreviation } from '../libs/states';
import { DealMedia, DriverLicenseMetadata } from '../types/media';
import { mapTTDocumentType } from '../utils/media';

const getMinDaysBeforeRegistrationExpires = (
  registrationValidations: TTValidationName[],
): number | null => {
  if (registrationValidations.includes(TTValidationName.RegistrationNotExpired90Days)) {
    return 90;
  }
  if (registrationValidations.includes(TTValidationName.RegistrationNotExpired60Days)) {
    return 60;
  }
  if (registrationValidations.includes(TTValidationName.RegistrationNotExpired)) {
    return 0;
  }
  return null;
};

type UseJurisdictionProps = {
  deal: Deal;
  media?: Maybe<Maybe<DealMedia>>[] | null;
};

export const useJurisdictionData = ({ deal, media }: UseJurisdictionProps) => {
  // We skip if no `media` so we don't query jurisdiction twice:  first with customer state and address,
  // and second with driver license state and address.
  const skip = useMemo(
    () =>
      !deal.customer?.address?.state ||
      !deal.car?.year ||
      deal.type === DealType.Acquisition ||
      !media,
    [deal.customer?.address?.state, deal.car?.year, deal.type, media],
  );

  const frontOfDriverLicenseMetadata = useMemo(
    () =>
      (media?.find((m) => m?.type === DealMediaTypeEnum.FrontOfDriversLicense)?.metadata ??
        {}) as DriverLicenseMetadata,
    [media],
  );

  const variables = useMemo(
    (): JurisdictionQueryVariables => ({
      state: deal.customer?.address?.state as StateAbbreviation,
      conditions: {
        // Send null if empty string. Empty string causes an error.
        lienholder: deal.car?.payoff?.lienholder_slug || null,
        hasCobuyer: !!deal.cobuyer?.first_name,
        driversLicenseAddress:
          frontOfDriverLicenseMetadata.address || deal.customer?.address?.address_line,
        currentAddress: deal.customer?.address?.address_line,
        vehicleAge: new Date().getFullYear() - parseInt(deal.car?.year || '0', 10),
        driversLicenseState: isValidStateAbbreviation(frontOfDriverLicenseMetadata.state)
          ? frontOfDriverLicenseMetadata.state
          : deal.customer?.address?.state,
        movedStateDuringLease: !!deal.customer?.address?.moved_states,
        transferringPlates: !!deal.financial_info?.plate_transfer,
        transferringRegistration:
          deal.financial_info?.title_registration_option ===
          TitleRegistrationOption.TitleAndRegistrationTransfer,
        jurisdictionState: deal.customer?.address?.state,
        canVerifyDigitalSignatures: !!deal.signing_on_com,
        newBank: mapBankEnumToTTNewBankEnum(deal.financial_info?.bank as Maybe<BankEnum>),
        buyerIsNotLessee: !!deal.financial_info?.buyer_not_lessee,
      },
      types:
        deal.financial_info?.title_registration_option === TitleRegistrationOption.TitleOnly
          ? [TtProductType.Payoff, TtProductType.Title]
          : [TtProductType.Payoff, TtProductType.Title, TtProductType.Registration],
    }),
    [
      deal.customer?.address?.state,
      deal.car?.payoff?.lienholder_slug,
      deal.cobuyer?.first_name,
      frontOfDriverLicenseMetadata.address,
      deal.customer?.address?.address_line,
      deal.car?.year,
      frontOfDriverLicenseMetadata.state,
      deal.customer?.address?.moved_states,
      deal.financial_info?.plate_transfer,
      deal.financial_info?.title_registration_option,
      deal.signing_on_com,
      deal.financial_info?.bank,
      deal.financial_info?.buyer_not_lessee,
    ],
  );

  const jurisdiction = useJurisdictionQuery({
    skip,
    variables,
  });

  const { requiredDocuments, validations, registrationValidations } = useMemo(
    () =>
      (jurisdiction.data?.jurisdiction?.products?.items ?? []).reduce(
        (acc, productItem) => {
          const productItemDocumentNames =
            productItem?.documents?.items
              ?.map((documentItem) =>
                mapTTDocumentType(documentItem?.type?.name as Maybe<TtDocumentName>),
              )
              .filter(Boolean) ?? [];

          // `License` and `Insurance` validations come at Product level.
          const productItemValidationNames =
            productItem?.validations
              ?.map((validation) => validation?.name as TTValidationName)
              .filter(Boolean) ?? [];

          // `Registration` validations come at Document level.
          const registrationValidationNames =
            productItem?.documents?.items
              ?.find((documentItem) => documentItem?.type?.name === TtDocumentName.Registration)
              ?.validations?.map((validation) => validation?.name as TTValidationName) ?? [];

          return {
            requiredDocuments: [...acc.requiredDocuments, ...productItemDocumentNames],
            validations: [...acc.validations, ...productItemValidationNames],
            registrationValidations: [
              ...acc.registrationValidations,
              ...registrationValidationNames,
            ],
          };
        },
        {
          requiredDocuments: [] as string[],
          validations: [] as TTValidationName[],
          registrationValidations: [] as TTValidationName[],
        },
      ),
    [jurisdiction.data?.jurisdiction],
  );

  const minDaysBeforeRegistrationExpires = useMemo(
    () => getMinDaysBeforeRegistrationExpires(registrationValidations),
    [registrationValidations],
  );

  return {
    conditions: variables.conditions,
    jurisdiction: jurisdiction.data?.jurisdiction ?? jurisdiction.previousData?.jurisdiction,
    jurisdictionLoading: jurisdiction.loading,
    requiredDocuments,
    validations,
    registrationValidations,
    minDaysBeforeRegistrationExpires,
  };
};
