import { useContext } from 'react';

import { HStack, IconButton, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { MdAddCircle, MdDoNotDisturbOn } from 'react-icons/md';

import { CreditAppOnDeal } from '../useCreditAppInitialValues';
import { getEmptyContact, getEmptyOriginalLessee, getEmptyPerson } from '../utils';

import { DealContext } from '../../../libs/DealContext';

type ButtonType = 'cobuyer' | 'contact' | 'secondContact' | 'originalLessee';
const labelMap: Record<ButtonType, string> = {
  cobuyer: 'Cobuyer',
  contact: 'Contact',
  secondContact: 'Second Contact',
  originalLessee: 'Original Lessee',
};

type AddDealPersonButtonProps = {
  isAdded: boolean;
  isDisabled?: boolean;
  type: ButtonType;
};

const AddDealPersonButton = ({ isAdded, type, isDisabled }: AddDealPersonButtonProps) => {
  const {
    values: {
      addCobuyer,
      addContact,
      addSecondContact,
      addOriginalLessee,
      cobuyer,
      contact,
      id,
      second_contact,
      original_lessee,
    },
    setFieldValue,
  } = useFormikContext<CreditAppOnDeal>();
  const { setUserChangedSomething } = useContext(DealContext);

  const icon = isAdded ? <MdDoNotDisturbOn /> : <MdAddCircle />;

  const buttonActions: Record<ButtonType, () => void> = {
    cobuyer: () => {
      setUserChangedSomething(true);
      // Add or remove a cobuyer
      if (!addCobuyer) {
        setFieldValue('cobuyer', cobuyer || getEmptyPerson());
      }

      setFieldValue('addCobuyer', !addCobuyer, true);
    },
    contact: () => {
      setUserChangedSomething(true);
      // Add or remove a contact
      if (!addContact) {
        setFieldValue('contact', contact || getEmptyContact(id));
        setFieldValue('addContact', true, true);
        return;
      }

      // Make the second contact the primary contact if needed
      if (addSecondContact) {
        setFieldValue('contact', second_contact);
        setFieldValue('contact.id', contact?.id);
        setFieldValue('addSecondContact', false, true);
        return;
      }

      setFieldValue('addContact', false, true);
    },
    secondContact: () => {
      setUserChangedSomething(true);
      // Add or remove the second contact
      if (!addSecondContact) {
        setFieldValue('second_contact', second_contact || getEmptyContact(id));
      }
      setFieldValue('addSecondContact', !addSecondContact, true);
    },
    originalLessee: () => {
      setUserChangedSomething(true);
      // Add or remove the original lessee
      if (!addOriginalLessee) {
        setFieldValue('original_lessee', original_lessee || getEmptyOriginalLessee());
      }
      setFieldValue('addOriginalLessee', !addOriginalLessee, true);
    },
  };

  return (
    <HStack mx={1}>
      <IconButton
        icon={icon}
        variant="icon"
        color={isDisabled ? 'gray' : isAdded ? 'errorsRed' : 'primary'}
        aria-label="add-cobuyer"
        fontSize={32}
        ml={4}
        mr={-2}
        onClick={buttonActions[type]}
        isDisabled={isDisabled}
      />
      <Text fontSize="lg">{`${isAdded ? 'Remove' : 'Add'} ${labelMap[type]}`}</Text>
    </HStack>
  );
};

export default AddDealPersonButton;
