import { useMemo, useState } from 'react';

import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { Virtuoso } from 'react-virtuoso';

import { Deal } from '../../gql/dealGql';

import DevBuildDealButton from '../DevTools/DevBuildDealButton';
import NotificationsAvatar from '../NotificationBadge/NotificationAvatar';
import Task from '../Task/Task';

import SortByMenu from './SortByMenu';
import './scrollbar.css';

import { isProd } from '../../config';
import { getDealsNotificationCountByType } from '../../constants/notifications';
import { SortByEnum, SortOpt, customSortDeals } from '../../utils/dashboard';

type DealsBucketProps = {
  title: string;
  readerDailyCount?: number;
  deals: Deal[];
  assumedId?: string;
  swimlaneCount?: number;
};
const DealsSwimlane = ({
  title,
  readerDailyCount,
  deals,
  assumedId,
  swimlaneCount,
}: DealsBucketProps) => {
  const sortOptState = useState<SortOpt>({ direction: 'desc', type: SortByEnum.Default });

  const [sortOpt] = sortOptState;
  const [sortedDeals, setSortedDeals] = useState<Deal[]>(deals);

  useMemo(() => {
    setSortedDeals(customSortDeals(deals, sortOpt));
  }, [deals, sortOpt]);

  const dealsNotificationCountByType = useMemo(
    () => getDealsNotificationCountByType(deals),
    [deals],
  );

  return (
    <VStack
      w="full"
      maxW="285px"
      minW="200px"
      bgColor="brightGray"
      h="900px"
      spacing={0}
      borderRadius={10}
    >
      <Flex
        w="full"
        bg="secondary"
        px={3}
        py={2}
        textColor="white"
        justifyContent="space-between"
        borderTopRadius={10}
      >
        <Text fontWeight={400}>{title}</Text>
        <Flex align="center">
          {!isProd && <DevBuildDealButton lane={title} />}
          <NotificationsAvatar count={dealsNotificationCountByType.unseenMessages} />
          <SortByMenu sortByState={sortOptState} />
        </Flex>
      </Flex>
      <Flex
        w="full"
        justifyContent="space-between"
        bgColor="queenBlue"
        textColor="white"
        fontSize="sm"
        px={2}
        fontWeight="light"
      >
        <Text>Today: {readerDailyCount || 0}</Text>
        <Text>Total: {swimlaneCount || 0}</Text>
      </Flex>
      <VStack w="full" py="1px" overflowX="hidden" overflowY="auto">
        <Virtuoso
          className="custom-scrollbar"
          style={{
            height: '900px',
            width: 'inherit',
          }}
          data={sortedDeals}
          itemContent={(i, deal) => (
            <Box w="full" py={1.5} px={2} key={deal.id ?? i}>
              <Task deal={deal} assumedId={assumedId} />
            </Box>
          )}
        />
      </VStack>
    </VStack>
  );
};

export default DealsSwimlane;
