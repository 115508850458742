import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  List,
  ListItem,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Form, Formik, FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';
import printJS from 'print-js';
import { FaDownload } from 'react-icons/fa';
import { MdDomainVerification } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Address } from '../../../../gql/addressGql';
import { Customer, getFullNameWithMiddle } from '../../../../gql/customerGql';
import {
  Car,
  CustomerInput,
  DealMediaInput,
  DealMediaTypeEnum,
  Maybe,
  MediaQuery,
  UpdateInsuranceInfoMutationVariables,
  useMediaDeleteMutation,
  useMediaUpdateMutation,
  useMergeFilesMutation,
  useUpdateCarMutation,
  useUpdateCustomerAndAddressMutation,
  useUpdateInsuranceInfoMutation,
} from '../../../../gql/generated/graphql';

import ImageMagnifier from '../../../shared/ImageMagnifier';
import Modal from '../../../shared/Modal';
import MediaEdit from '../MediaForm/MediaEdit';
import MediaUpload from '../MediaForm/MediaUpload';
import InsuranceForm from './components/InsuranceForm';
import LicenseForm from './components/LicenseForm';
import RegistrationForm from './components/RegistrationForm';

import {
  dateDoesNotMatchMessage,
  enterValidZipMessage,
  getValidationSchema,
  stringDoesNotMatchMessage,
} from './validations';

import { DealMediaCustomerNoPhotoTypes, MismatchSelectionEnum } from '../../../../constants/media';
import useUpdateCustomDates from '../../../../hooks/useUpdateCustomDates';
import { DealActionsEnum, DealContext } from '../../../../libs/DealContext';
import { logger } from '../../../../libs/Logger';
import { ColorsEnum } from '../../../../libs/colorLib';
import { ModalContext } from '../../../../libs/contextLib';
import { formatDateTimeISOWithUTC, getUTCDate } from '../../../../libs/utils';
import {
  DealMedia,
  DealMediaEdit,
  DriverLicenseMetadata,
  InsuranceCardMetadata,
  RegistrationMetadata,
} from '../../../../types/media';
import {
  getAddressValue,
  haveAllAddressFieldsBeenFilled,
  isSomeAddressFieldNotMatching,
} from '../../../../utils/addresses';
import { getNamesObject } from '../../../../utils/customers';
import { mapTTValidationNamesToRequiredMetadataFields } from '../../../../utils/media';
import { removeWhiteSpaces } from '../../../../utils/text';
import { CUSTOM_DATE_FIELDS } from '../../../DealInfoBuyout/constants';
import PDFViewer from '../../../PDFViewer.tsx';
import { parseFilename } from '../../utils';

export const ADD = 'Upload to Media Center';
export const EDIT = 'Edit file';
export const DELETE = 'Delete file';
export const PREVIEW = 'Preview file';
export const DOWNLOAD = 'Download file';
export const BATCH_DELETE = 'Delete selected files';
export const VERIFY = 'Verify file';
export const PRINT_FILES = 'Print Selected Documents';

export type ShowMediaModalType = {
  show: boolean;
  dealMedia?: DealMedia & DealMediaEdit;
  content?: string;
  filePath?: string;
  fileUrl?: string;
  isInternalFile?: boolean;
  customTitle?: string;
  initialMediaType?: Maybe<DealMediaTypeEnum>;
};

export type MediaModalType = Omit<ShowMediaModalType, 'show'>;

interface MediaModalProps {
  isOpen: boolean;
  showModal: MediaModalType;
  setShowModal: Dispatch<SetStateAction<MediaModalType>>;
  selectedFiles: DealMedia[];
  dealId?: number;
  onClose: () => void;
  setSelectedFiles: Dispatch<SetStateAction<DealMedia[]>>;
  refetchMedia: () => Promise<ApolloQueryResult<MediaQuery>>;
  allFiles: DealMedia[] | undefined;
}

const MediaModal = ({
  isOpen,
  showModal,
  setShowModal,
  selectedFiles,
  dealId,
  onClose,
  setSelectedFiles,
  refetchMedia,
  allFiles,
}: MediaModalProps) => {
  const {
    deal,
    documentMedia: { missingRequiredMediaList },
    dispatch: dispatchDeal,
    jurisdictionData: { jurisdictionLoading, validations, registrationValidations },
  } = useContext(DealContext);
  const { setModal } = useContext(ModalContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isExtractingData, setIsExtractingData] = useState<boolean>(false);
  const [isVerifiable, setIsVerifiable] = useState<boolean>(true);

  const [deleteMedia] = useMediaDeleteMutation();
  const [updateMedia] = useMediaUpdateMutation();
  const [mergeFilesMutation] = useMergeFilesMutation();
  const updateCustomDates = useUpdateCustomDates();

  const allFilesWithPhysicalSignatureCoverLetter = useMemo(() => {
    const hasPhysicalSignatureCoverLetter = allFiles?.some(
      (f) => f.type === DealMediaTypeEnum.PhysicalSignatureCoverLetter,
    );

    const physicalSignatureCoverLetter = {
      key: `${DealMediaTypeEnum.PhysicalSignatureCoverLetter.toUpperCase()}.pdf`,
      type: DealMediaTypeEnum.PhysicalSignatureCoverLetter,
    } as DealMedia;

    return showModal.content === PRINT_FILES && !hasPhysicalSignatureCoverLetter
      ? [...(allFiles ?? []), physicalSignatureCoverLetter]
      : allFiles;
  }, [allFiles, showModal.content]);

  const notSelectedFiles = useMemo(
    () =>
      allFilesWithPhysicalSignatureCoverLetter?.filter(
        (file) =>
          !selectedFiles?.some(
            (selectedFile) => selectedFile.id === file.id && selectedFile.key === file.key,
          ) && file.type !== DealMediaTypeEnum.R1EContract,
      ),
    [allFilesWithPhysicalSignatureCoverLetter, selectedFiles],
  );

  useEffect(() => {
    if (isOpen && showModal.content === VERIFY) {
      setModal({
        MediaModal: true,
      });
    }

    if (isOpen && showModal.content === PRINT_FILES) {
      setSelectedFiles(selectedFiles.filter((file) => file.type !== DealMediaTypeEnum.R1EContract));
    }
  }, [isOpen, showModal.content]);

  const print = async () => {
    if (!dealId) {
      return;
    }

    setLoading(true);

    try {
      const merge = await mergeFilesMutation({
        variables: {
          deal_id: dealId,
          docs: selectedFiles
            .filter((f) => !!f.key)
            .map((f) => ({
              id: f.id,
              key: f.key,
              type: f.type,
              wet_signature_required: f.wet_signature_required,
            })) as DealMediaInput[],
          missing_required_media_list: missingRequiredMediaList
            .filter((m) => DealMediaCustomerNoPhotoTypes.includes(m.type as DealMediaTypeEnum))
            .map((m) => m.type as DealMediaTypeEnum),
        },
      });
      if (merge.data) {
        printJS({
          printable: merge.data.mergeFiles?.url,
          type: 'pdf',
        });

        // Update Wet Signature Sent Date when printing the documents.
        // Force initial values recalculation.
        setModal({
          MediaModal: true,
        });
        await updateCustomDates({
          fieldName: CUSTOM_DATE_FIELDS.customWetSigSentField,
          fieldValue: new Date().toISOString(),
          componentName: 'MediaModal/index.tsx',
        });
        setModal({
          MediaModal: false,
        });
      }
      setLoading(false);
      setSelectedFiles([]);
      refetchMedia();
      onClose();
    } catch {
      setLoading(false);
      toast.error('Failed to merge files for printing.');
    }
  };

  const batchDelete = async () => {
    setLoading(true);
    try {
      await Promise.all(
        selectedFiles.map((f) =>
          f.key
            ? deleteMedia({
                variables: {
                  filePath: f.key,
                },
              })
            : Promise.resolve(),
        ),
      );

      setSelectedFiles([]);
      refetchMedia();
      toast.success('Files deleted!');
    } catch (error: unknown) {
      logger.error('MediaModal/index.tsx', 'Unable to delete files', null, error);
      toast.error('Unable to delete files');
    } finally {
      onClose();
      setLoading(false);
    }
  };

  const {
    requiredDriverLicenseMetadataFields: requiredDriverLicenseMetadataFieldsMap,
    requiredInsuranceCardMetadataFields: requiredInsuranceCardMetadataFieldsMap,
    requiredRegistrationMetadataFields: requiredRegistrationMetadataFieldsMap,
  } = useMemo(
    () =>
      mapTTValidationNamesToRequiredMetadataFields([...validations, ...registrationValidations]),
    [validations, registrationValidations],
  );
  const isLoadingVerificationForms = isExtractingData || jurisdictionLoading;

  const generateVerificationForm = (type?: Maybe<DealMediaTypeEnum>) => {
    if (
      type !== DealMediaTypeEnum.FrontOfDriversLicense &&
      type !== DealMediaTypeEnum.FrontOfInsuranceCard &&
      type !== DealMediaTypeEnum.Registration
    ) {
      setIsVerifiable(true);
    }

    const formattedRegistrationExpirationDate = deal.car.registration_expiration
      ? format(new Date(deal.car.registration_expiration), 'MMMM yyyy')
      : '';

    return (
      <>
        <LicenseForm
          setIsVerifiable={setIsVerifiable}
          showModal={showModal}
          setShowModal={setShowModal}
          isLoading={isLoadingVerificationForms}
          requiredFields={requiredDriverLicenseMetadataFieldsMap}
          applicationValues={{
            name: getFullNameWithMiddle(deal.customer),
            state: deal.customer?.address?.state,
            address: getAddressValue(deal.customer?.address),
            zip: deal.customer?.address?.zip,
          }}
        />
        <InsuranceForm
          setIsVerifiable={setIsVerifiable}
          showModal={showModal}
          setShowModal={setShowModal}
          isLoading={isLoadingVerificationForms}
          requiredFields={requiredInsuranceCardMetadataFieldsMap}
          applicationValues={{
            name: getFullNameWithMiddle(deal.customer),
            state: deal.customer?.address?.state,
            vin: deal.car.vin,
            insuranceCompany: deal.customer?.proof_of_insurance?.company_name as string,
            policyNo: deal.customer?.proof_of_insurance?.policy_number as string,
            expirationDate: deal.customer?.proof_of_insurance?.expires,
          }}
        />
        <RegistrationForm
          setIsVerifiable={setIsVerifiable}
          showModal={showModal}
          setShowModal={setShowModal}
          isLoading={isLoadingVerificationForms}
          applicationValues={{
            expirationDate: formattedRegistrationExpirationDate,
            expirationDate60Days: formattedRegistrationExpirationDate,
            expirationDate90Days: formattedRegistrationExpirationDate,
          }}
          requiredFields={requiredRegistrationMetadataFieldsMap}
        />
      </>
    );
  };

  const submitVerificationForm = async (saveData = false) => {
    try {
      const media = showModal?.dealMedia;
      if (!deal.id || !media?.key) {
        throw new Error();
      }

      let variables: {
        dealId: number;
        oldKey: string;
        metadata?: {
          [key: string]: string | boolean;
        };
        verified: boolean;
        newFileName?: string;
        type?: DealMediaTypeEnum;
      } = {
        dealId: deal.id,
        oldKey: media.key,
        metadata: media.metadata,
        verified: media.verified ? media.verified : !!saveData,
      };

      if (saveData) {
        variables = {
          ...variables,
          newFileName: media.sourceFileName,
        };

        if (media.type) {
          variables = {
            ...variables,
            type: media.type,
          };
        }
      }

      await updateMedia({
        variables,
      });
      refetchMedia();
      onClose();
    } catch {
      toast.error('Failed to submit media metadata');
    }
  };

  const [updateCustomerAndAddress] = useUpdateCustomerAndAddressMutation();
  const [updateInsuranceInfo] = useUpdateInsuranceInfoMutation();
  const [updateCarRegistrationExpiration] = useUpdateCarMutation();

  const updateApplication = async (errors: FormikErrors<DealMediaEdit>) => {
    const newCustomer: CustomerInput = {
      id: deal.customer?.id,
    };
    if (showModal.dealMedia?.type === DealMediaTypeEnum.FrontOfDriversLicense) {
      const licenseMetadata = showModal?.dealMedia?.metadata as DriverLicenseMetadata;
      const { metadata: licenseErrors } = errors as {
        metadata: DriverLicenseMetadata | undefined;
      };

      const nameIsNotMatching = licenseErrors?.name === stringDoesNotMatchMessage;
      const nameHasBeenFilled = !!removeWhiteSpaces(licenseMetadata?.name);
      const shouldUpdateApplicationName =
        nameIsNotMatching &&
        nameHasBeenFilled &&
        licenseMetadata?.nameMismatchSelection === MismatchSelectionEnum.Document;
      if (shouldUpdateApplicationName) {
        const licenseNamesObject = getNamesObject(licenseMetadata?.name);

        newCustomer.first_name = licenseNamesObject.first_name;
        newCustomer.middle_name = licenseNamesObject.middle_name;
        newCustomer.last_name = licenseNamesObject.last_name;
      }

      const stateIsValid = !licenseErrors?.state;
      const zipIsValid = licenseErrors?.zip !== enterValidZipMessage;
      const allAddressFieldsHaveBeenFilled = haveAllAddressFieldsBeenFilled(licenseMetadata);
      const someAddressFieldIsNotMatching = isSomeAddressFieldNotMatching(licenseErrors);
      const shouldUpdateApplicationAddress =
        stateIsValid &&
        zipIsValid &&
        someAddressFieldIsNotMatching &&
        allAddressFieldsHaveBeenFilled &&
        licenseMetadata?.addressMismatchSelection === MismatchSelectionEnum.Document;
      if (shouldUpdateApplicationAddress) {
        newCustomer.address = {
          id: deal.customer?.address.id,
          address_line: licenseMetadata?.address,
          address_line_2: licenseMetadata?.address2,
          city: licenseMetadata?.city,
          zip: licenseMetadata?.zip,
        };
      }
    }

    const newCustomerProofOfInsurance: UpdateInsuranceInfoMutationVariables = {
      id: deal.customer?.id as number,
    };
    if (showModal.dealMedia?.type === DealMediaTypeEnum.FrontOfInsuranceCard) {
      const insuranceMetadata = showModal?.dealMedia?.metadata as InsuranceCardMetadata;
      const { metadata: insuranceErrors } = errors as {
        metadata: InsuranceCardMetadata | undefined;
      };

      const insuranceCompanyIsNotMatching =
        insuranceErrors?.insuranceCompany === stringDoesNotMatchMessage;
      const insuranceCompanyHasBeenFilled = !!removeWhiteSpaces(
        insuranceMetadata?.insuranceCompany,
      );
      const shouldUpdateApplicationInsuranceCompany =
        insuranceCompanyIsNotMatching && insuranceCompanyHasBeenFilled;
      if (shouldUpdateApplicationInsuranceCompany) {
        newCustomerProofOfInsurance.insurance_company = insuranceMetadata?.insuranceCompany;
      }

      const policyNoIsNotMatching = insuranceErrors?.policyNo === stringDoesNotMatchMessage;
      const policyNoHasBeenFilled = !!removeWhiteSpaces(insuranceMetadata?.policyNo);
      const shouldUpdateApplicationPolicyNo = policyNoIsNotMatching && policyNoHasBeenFilled;
      if (shouldUpdateApplicationPolicyNo) {
        newCustomerProofOfInsurance.policy_no = insuranceMetadata?.policyNo;
      }

      const expirationDateIsNotMatching =
        insuranceErrors?.expirationDate === dateDoesNotMatchMessage;
      const expirationDateHasBeenFilled = !!removeWhiteSpaces(insuranceMetadata?.expirationDate);
      const shouldUpdateApplicationExpirationDate =
        expirationDateIsNotMatching && expirationDateHasBeenFilled;
      if (shouldUpdateApplicationExpirationDate) {
        newCustomerProofOfInsurance.expiration_date = insuranceMetadata?.expirationDate;
      }
    }

    const newCar: Car = {
      id: deal.car.id,
    };
    if (showModal.dealMedia?.type === DealMediaTypeEnum.Registration) {
      const registrationMetadata = showModal?.dealMedia?.metadata as RegistrationMetadata;
      const { metadata: registrationErrors } = errors as {
        metadata: RegistrationMetadata | undefined;
      };

      const expirationDateIsNotMatching =
        registrationErrors?.expirationDate === dateDoesNotMatchMessage;
      const expirationDate60DaysIsNotMatching =
        registrationErrors?.expirationDate60Days === dateDoesNotMatchMessage;
      const expirationDate90DaysIsNotMatching =
        registrationErrors?.expirationDate90Days === dateDoesNotMatchMessage;
      if (expirationDate90DaysIsNotMatching) {
        newCar.registration_expiration = registrationMetadata.expirationDate90Days;
      } else if (expirationDate60DaysIsNotMatching) {
        newCar.registration_expiration = registrationMetadata.expirationDate60Days;
      } else if (expirationDateIsNotMatching) {
        newCar.registration_expiration = registrationMetadata.expirationDate;
      }
    }
    try {
      const shouldUpdateApplication = Object.keys(newCustomer).length > 1;
      if (shouldUpdateApplication) {
        await updateCustomerAndAddress({
          variables: {
            customer: newCustomer,
          },
        });
      }

      const shouldUpdateApplicationProofOfInsurance =
        Object.keys(newCustomerProofOfInsurance).length > 1;
      if (shouldUpdateApplicationProofOfInsurance) {
        await updateInsuranceInfo({
          variables: {
            ...newCustomerProofOfInsurance,
          },
        });
      }

      const shouldUpdateApplicationCar = Object.keys(newCar).length > 1;
      if (shouldUpdateApplicationCar) {
        await updateCarRegistrationExpiration({
          variables: { car: newCar },
        });
      }

      if (
        !shouldUpdateApplication &&
        !shouldUpdateApplicationProofOfInsurance &&
        !shouldUpdateApplicationCar
      ) {
        return;
      }

      dispatchDeal({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          customer: {
            ...deal.customer,
            ...(newCustomer as unknown as Customer),
            proof_of_insurance: {
              ...deal.customer?.proof_of_insurance,
              company_name: newCustomerProofOfInsurance.insurance_company,
              policy_number: newCustomerProofOfInsurance.policy_no,
              ...(newCustomerProofOfInsurance.expiration_date
                ? {
                    expires: formatDateTimeISOWithUTC(
                      getUTCDate(newCustomerProofOfInsurance.expiration_date),
                    ),
                  }
                : {}),
            },
            address: {
              ...deal.customer?.address,
              ...((newCustomer.address as Address) ?? {}),
            },
          },
          car: {
            ...deal.car,
            registration_expiration: newCar.registration_expiration,
          },
        },
      });

      // Recompute initial values so they are not overwritten after changing something in the main form.
      setModal({
        MediaModal: false,
      });

      toast.success('Application updated');
    } catch (error) {
      toast.error('Error updating application');
    }
  };

  const modalContent = useMemo(() => {
    switch (showModal.content) {
      case ADD:
        return (
          <MediaUpload
            cancelFn={onClose}
            mediaRefetch={refetchMedia}
            showModal={showModal}
            setShowModal={setShowModal}
            initialMediaType={showModal.initialMediaType}
          />
        );
      case DELETE:
        return (
          <>
            <Text fontWeight="bold">{showModal.dealMedia?.fileName}</Text>
            <Flex direction="row" justifyContent="end" mt={5}>
              <Button
                isLoading={isLoading}
                loadingText="DELETE"
                variant="warning"
                onClick={async () => {
                  setLoading(true);
                  try {
                    if (!showModal.filePath) {
                      throw new Error('No file path');
                    }

                    await deleteMedia({ variables: { filePath: showModal.filePath } });
                    setSelectedFiles((files) => files.filter((f) => f.key !== showModal.filePath));
                    refetchMedia();
                    toast.success('File deleted!');
                  } catch (e) {
                    logger.error(
                      'MediaModal/index.tsx',
                      'Unable to delete file',
                      showModal.dealMedia?.fileName,
                      e,
                    );
                    toast.error('Unable to delete file');
                  } finally {
                    onClose();
                    setLoading(false);
                  }
                }}
              >
                DELETE
              </Button>
            </Flex>
          </>
        );
      case PREVIEW:
        return (
          <>
            <Button
              position="fixed"
              bottom="5%"
              right="5%"
              boxShadow="dark-lg"
              borderRadius="100%"
              width="100px"
              height="100px"
              backgroundColor={ColorsEnum.WHITE}
              variant="flush"
              onClick={() => {
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                window.open(showModal.fileUrl, 'PRINT');
              }}
            >
              <FaDownload size="50" color={ColorsEnum.BLACK} />
            </Button>
            <PDFViewer fileUrl={showModal.fileUrl} />
          </>
        );
      case BATCH_DELETE:
        return (
          <>
            {selectedFiles.map((f) => {
              const fileDetails = parseFilename(f.key ?? '');
              return (
                <Text fontWeight="bold" key={f.key}>
                  {fileDetails.sourceFileName}
                </Text>
              );
            })}
            <Flex direction="row" justifyContent="end" mt={5}>
              <Button
                isLoading={isLoading}
                loadingText="DELETE"
                variant="warning"
                onClick={batchDelete}
              >
                DELETE
              </Button>
            </Flex>
          </>
        );
      case VERIFY: {
        const { isPdf, sourceFileName } = parseFilename(showModal.dealMedia?.key ?? '');
        const signedUrl = showModal.dealMedia?.signed_url ?? '';

        const getInitialValues = (dealMedia: DealMediaEdit) => {
          const newDealMedia = cloneDeep(dealMedia);

          if (newDealMedia.type === DealMediaTypeEnum.FrontOfDriversLicense) {
            if (dealMedia?.verified) {
              newDealMedia.metadata = {
                ...newDealMedia?.metadata,
                nameVerified: true,
                addressVerified: true,
                address2Verified: true,
                cityVerified: true,
                stateVerified: true,
                zipVerified: true,
                expirationDateVerified: true,
              };
            }

            newDealMedia.metadata = {
              ...newDealMedia.metadata,
              name: newDealMedia.metadata?.name || getFullNameWithMiddle(deal.customer),
              address: newDealMedia.metadata?.address
                ? newDealMedia.metadata.address
                : deal.customer?.drivers_license_address?.address_line ?? '',
              address2: newDealMedia.metadata?.address2
                ? newDealMedia.metadata.address2
                : deal.customer?.drivers_license_address?.address_line_2 ?? '',
              city: newDealMedia.metadata?.city
                ? newDealMedia.metadata.city
                : deal.customer?.drivers_license_address?.city ?? '',
              state: newDealMedia.metadata?.state
                ? newDealMedia.metadata.state
                : deal.customer?.drivers_license_address?.state ?? '',
              zip: newDealMedia.metadata?.zip
                ? newDealMedia.metadata.zip
                : deal.customer?.drivers_license_address?.zip ?? '',
              expirationDate: newDealMedia.metadata?.expirationDate
                ? newDealMedia.metadata.expirationDate
                : deal.customer.dl_expiration_date ?? '',
            };
          }

          if (newDealMedia.type === DealMediaTypeEnum.FrontOfInsuranceCard) {
            if (dealMedia?.verified) {
              newDealMedia.metadata = {
                ...newDealMedia?.metadata,
                nameVerified: true,
                stateVerified: true,
                vinVerified: true,
                expirationDateVerified: true,
              };
            }

            newDealMedia.metadata = {
              ...newDealMedia.metadata,
              name: newDealMedia.metadata?.name
                ? newDealMedia.metadata?.name
                : getFullNameWithMiddle(deal.customer),
              state: newDealMedia.metadata?.state
                ? newDealMedia.metadata.state
                : deal.customer?.proof_of_insurance?.state ?? '',
              vin: newDealMedia.metadata?.vin ? newDealMedia.metadata.vin : deal.car.vin,
              expirationDate: newDealMedia.metadata?.expirationDate
                ? newDealMedia.metadata.expirationDate
                : deal.customer?.proof_of_insurance?.expires,
            };
          }

          return newDealMedia;
        };

        const validationSchema = getValidationSchema(
          deal,
          showModal.dealMedia ?? {},
          requiredDriverLicenseMetadataFieldsMap,
          requiredInsuranceCardMetadataFieldsMap,
          requiredRegistrationMetadataFieldsMap,
        );
        const initialValues = getInitialValues(showModal.dealMedia ?? {});

        const handleClick = async ({
          errors,
          saveData = false,
        }: {
          errors: FormikErrors<DealMediaEdit>;
          saveData?: boolean;
        }) => {
          await submitVerificationForm(saveData);
          await updateApplication(errors);
        };

        return (
          <Flex w="100%">
            <Flex w={showModal.isInternalFile ? '100%' : isPdf ? '70%' : '60%'} direction="column">
              <Box borderBottom="1px" borderColor={ColorsEnum.ELECTRICBLUE} pt={8} px={8}>
                <MediaEdit
                  showModal={showModal}
                  setShowModal={setShowModal}
                  cancelFn={onClose}
                  mediaRefetch={refetchMedia}
                  setIsExtractingData={setIsExtractingData}
                  isExtractingData={isExtractingData}
                  variant="reduced"
                />
              </Box>
              <Box pt={8} px={2}>
                {isPdf ? (
                  <Box w="100%" h="500px" border="1px solid black" p={1} overflowY="scroll">
                    <PDFViewer fileUrl={signedUrl} />
                  </Box>
                ) : (
                  <ImageMagnifier src={signedUrl} alt={sourceFileName} />
                )}
              </Box>
            </Flex>
            {!showModal.isInternalFile && (
              <Flex
                w={isPdf ? '30%' : '40%'}
                p={8}
                direction="column"
                borderLeft="1px"
                borderColor={ColorsEnum.ELECTRICBLUE}
              >
                <Formik
                  enableReinitialize={isLoadingVerificationForms}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnChange
                  onSubmit={() => undefined}
                >
                  {({ values, errors }) => {
                    return (
                      <Form>
                        <Flex
                          justifyContent="center"
                          marginBottom={4}
                          hidden={!isLoadingVerificationForms}
                        >
                          <Spinner size="lg" />
                        </Flex>
                        {generateVerificationForm(showModal.dealMedia?.type)}
                        <Center bottom={2}>
                          <Button variant="secondary" onClick={() => handleClick({ errors })}>
                            SAVE
                          </Button>
                          <Button
                            size="lgWithIconLeft"
                            leftIcon={<MdDomainVerification size={18} />}
                            isDisabled={!isVerifiable}
                            onClick={() => handleClick({ errors, saveData: true })}
                          >
                            {values.verified ? 'Verified' : 'Verify'}
                          </Button>
                        </Center>
                      </Form>
                    );
                  }}
                </Formik>
              </Flex>
            )}
          </Flex>
        );
      }
      case PRINT_FILES: {
        return (
          <Flex w="100%" direction="column">
            <List>
              <ListItem fontSize={24} fontWeight="bold">
                Selected Files:
              </ListItem>
              {selectedFiles.map((f) => {
                if (f.key) {
                  const fileDetails = parseFilename(f.key);
                  return (
                    <ListItem fontSize={20} key={f.key}>
                      <Checkbox
                        size="md"
                        mr={2}
                        mt={1.5}
                        cursor="pointer"
                        isChecked={selectedFiles.some((file) => file.id === f.id)}
                        onChange={() => {
                          setSelectedFiles([...selectedFiles].filter((file) => file.id !== f.id));
                        }}
                      />
                      {fileDetails.sourceFileName}
                    </ListItem>
                  );
                }
                return null;
              })}
              <ListItem mt={4} fontSize={24} fontWeight="bold">
                Unselected Files:
              </ListItem>
              {notSelectedFiles?.map((f) => {
                const fileDetails = parseFilename(f.key ?? '');
                return (
                  <ListItem fontSize={20} key={f.key}>
                    <Checkbox
                      size="md"
                      mr={2}
                      mt={1.5}
                      cursor="pointer"
                      isChecked={selectedFiles.some((sf) => sf.id === f.id && sf.key === f.key)}
                      onChange={() => {
                        setSelectedFiles([...selectedFiles, f]);
                      }}
                    />
                    {fileDetails.sourceFileName}
                  </ListItem>
                );
              })}
            </List>
            <Flex justifyContent="end" mt={5}>
              <Button variant="secondary" isLoading={isLoading} loadingText="PRINT" onClick={print}>
                PRINT
              </Button>
            </Flex>
          </Flex>
        );
      }
      default:
        return null;
    }
  }, [
    showModal,
    isLoading,
    selectedFiles,
    allFiles,
    isVerifiable,
    isLoadingVerificationForms,
    requiredDriverLicenseMetadataFieldsMap,
    requiredInsuranceCardMetadataFieldsMap,
    requiredRegistrationMetadataFieldsMap,
  ]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={showModal.customTitle || showModal.content}
      variant={[PREVIEW, VERIFY].includes(showModal.content ?? '') ? 'fullContent' : 'noPadding'}
      size={[PREVIEW, VERIFY].includes(showModal.content ?? '') ? '6xl' : 'xl'}
      closeOnOverlayClick={false}
      isCentered
    >
      {modalContent}
    </Modal>
  );
};

export default MediaModal;
