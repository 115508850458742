import { ChangeEvent, ChangeEventHandler, useContext, useEffect, useState } from 'react';

import {
  Box,
  HStack,
  InputProps,
  Stack,
  StyleProps,
  SystemStyleObject,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import Input from '../shared/Input';

import MaskedSsnButton from './MaskedSsnButton';
import { SsnMask } from './MaskedSsnCustomerInfo';

import colors from '../../chakra/foundations/colors';
import { emptySsnFullMask, ssnFullMask } from '../../constants/masks';
import { DealContext } from '../../libs/DealContext';

type MaskedSsnInputProps = {
  name: string;
  needsHidden: boolean;
  ssnDetails?: { mask: string; label: string; emptyMask: string };
  isDisabled?: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  replaceFixedCharacters?: boolean;
  width?: StyleProps['w'];
  inputProps?: InputProps;
};

const MaskedSsnInput = ({
  name,
  needsHidden,
  ssnDetails,
  isDisabled = false,
  customHandleChange,
  replaceFixedCharacters = false,
  width = '100%',
  inputProps,
}: MaskedSsnInputProps) => {
  const [isSsnHidden, setIsSsnHidden] = useState(needsHidden);
  const { deal } = useContext(DealContext);
  const { getFieldMeta, setFieldValue, handleChange: formikHandleChange } = useFormikContext();
  const { value } = getFieldMeta<string | undefined>(name);
  const handleChange = customHandleChange ?? formikHandleChange;

  const getId = (): number | null => {
    if (name.startsWith('original_lessee')) {
      return deal.original_lessee?.id ?? null;
    }
    if (name.startsWith('cobuyer')) {
      return deal.cobuyer?.id ?? null;
    }
    return deal.customer?.id ?? null;
  };

  const id = getId();
  const customerId =
    (name.startsWith('customer') || name.startsWith('cobuyer')) && id ? Number(id) : undefined;
  const originalLesseeId = name.startsWith('original_lessee') ? id : undefined;

  const inputStyles = useMultiStyleConfig('Input') as {
    field?: { _disabled?: SystemStyleObject };
  };

  useEffect(() => {
    if (!value) {
      setFieldValue(name, ssnDetails?.emptyMask ?? emptySsnFullMask, false);
    }

    if (replaceFixedCharacters) {
      const fixedCharactersRegExp = new RegExp(/x/gi);
      const maskPlaceholder = '_';

      if (value?.match(fixedCharactersRegExp)) {
        setFieldValue(name, value.replace(fixedCharactersRegExp, maskPlaceholder));
      }
    }
  }, [value]);

  const handleSsnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/[a-wyz]/g)) {
      return;
    }
    const updatedValue = e.target.value.replace(/x/g, 'X');
    handleChange(e);
    setFieldValue(name, updatedValue);
  };

  return isSsnHidden ? (
    <Stack width={width}>
      <Text fontSize="sm">{ssnDetails?.label ?? 'SSN'}</Text>
      <Box
        borderWidth="1px"
        borderColor="darkLiver"
        borderRadius="2px"
        h="32px"
        minWidth="130px"
        // eslint-disable-next-line no-underscore-dangle
        sx={isDisabled ? inputStyles.field?._disabled : undefined}
        bgColor={colors.white}
      >
        <HStack spacing="auto" width="100%" pt="2px" pl={2}>
          <SsnMask type="form" />
          <MaskedSsnButton
            type="form"
            customerId={customerId}
            originalLesseeId={originalLesseeId}
            onClick={() => setIsSsnHidden(false)}
          />
        </HStack>
      </Box>
    </Stack>
  ) : (
    <Input
      label={ssnDetails?.label ?? 'SSN'}
      name={name}
      mask={ssnDetails?.mask ?? ssnFullMask}
      onChange={handleSsnChange}
      isDisabled={isDisabled}
      viewButton={needsHidden}
      turnViewOff={needsHidden ? setIsSsnHidden : undefined}
      formControlProps={{ width }}
      inputProps={inputProps}
    />
  );
};

export default MaskedSsnInput;
