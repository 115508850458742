import { Dispatch, SetStateAction } from 'react';

import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioProps,
  Text,
  useMultiStyleConfig,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Option } from './types';

import colors from '../../chakra/foundations/colors';
import { isNullOrUndefined } from '../../utils';

type RadioCardProps = RadioProps & {
  label: string;
};

const RadioCard = ({ label, isDisabled = false, ...props }: RadioCardProps) => {
  const { getInputProps, getCheckboxProps } = useRadio({ ...props, isDisabled });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" m={0} fontSize="sm" fontWeight={700}>
      <input {...input} />
      <Box
        {...checkbox}
        opacity={isDisabled ? 0.5 : 1}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        h="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        borderColor="black"
        borderRadius="sm"
        _checked={{
          bg: colors.caribbeanGreen[500],
          color: 'white',
        }}
        _invalid={{
          bg: colors.spanishPink,
          borderColor: colors.errorsRed,
        }}
      >
        <Text minW={16} px={2} py={1} align="center">
          {label}
        </Text>
      </Box>
    </Box>
  );
};

type RadioButtonsProps = FlexProps & {
  name: string;
  options: Option[];
  label?: string;
  isDisabled?: boolean;
  setUserChangedSomething?: Dispatch<SetStateAction<boolean>>;
};

const RadioButtons = ({
  name,
  options,
  label,
  isDisabled = false,
  setUserChangedSomething,
  ...rest
}: RadioButtonsProps) => {
  const { getFieldMeta, setFieldValue, setFieldTouched } = useFormikContext();
  const { value, touched, error } = getFieldMeta(name);
  const isInvalid = touched && !!error;

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value: value as string | number | undefined,
    onChange: (newValue) => {
      setUserChangedSomething?.(true);

      setFieldValue(name, newValue);
      setTimeout(() => setFieldTouched(name, true));
    },
    isDisabled,
  });
  const rootProps = getRootProps();

  const styles = useMultiStyleConfig('FormControl');

  return (
    <Box {...rest} {...rootProps} isDisabled={isDisabled}>
      <FormControl isInvalid={isInvalid} sx={styles.control}>
        {label ? (
          <FormLabel whiteSpace="nowrap" sx={styles.label}>
            {label}
          </FormLabel>
        ) : null}
        <Flex direction="row" sx={styles.input}>
          {options.map((option) => (
            <RadioCard
              key={option.value}
              label={option.label}
              {...getRadioProps({ value: option.value })}
              isDisabled={isDisabled || (!isNullOrUndefined(option.enabled) && !option.enabled)}
            />
          ))}
        </Flex>
        <FormErrorMessage sx={styles.error}>{error}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default RadioButtons;
