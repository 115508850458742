import { useEffect, useState } from 'react';

import { Box, Flex, Stack, Text, useToast } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

import LeaseEndContainer from '../../components/Container/LEContainer';
import CardHeaderV2 from '../../components/shared/Card/components/CardHeaderV2';

import config from '../../config';
import { getSearchParams } from '../../hooks/useUrlQueryParamsWithMultipleReducers';
import { formatPhoneNumberForDisplay } from '../../libs/utils';
import { getName } from '../../utils/user';

const Transcriptions = () => {
  const [transcription, setTranscription] = useState('');
  const toast = useToast();
  const {
    call_direction: callDirection,
    phone_number: phoneNumber,
    customer_first_name: customerFirstName,
    customer_last_name: customerLastName,
    recording_start_time: recordingStartTime,
    recording_duration: recordingDuration,
  } = getSearchParams(window.location.search);
  const customerName = getName(`${customerFirstName} ${customerLastName}`, { lastInitial: true });

  let startTime = '';

  if (!recordingStartTime) {
    startTime = 'No start time found';
  } else {
    const date = new Date(recordingStartTime);
    startTime = format(date, 'MMM d, h:mm a');
  }
  const durationString = Number(recordingDuration)
    ? `${Math.floor(Number(recordingDuration) / 60)}m ${Number(recordingDuration) % 60}s`
    : '0m 0s';

  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const job = urlParams.get('job');
      const callSid = urlParams.get('call_sid');

      if (!job && !callSid) {
        toast({
          title: 'Missing Parameters',
          description: 'No job or call ID found',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        setTranscription('No transcription found - missing parameters');
        return;
      }

      if (job) {
        const url = `https://leaseend-twilio-voice-recordings.s3.us-east-2.amazonaws.com/transcriptions/redacted-${job}.json`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const text = data?.results?.transcripts[0]?.transcript ?? 'No transcription found';
            setTranscription(text);
          })
          .catch(() => {
            toast({
              title: 'Error',
              description: 'Failed to load legacy transcription',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setTranscription('No transcription found');
          });
      } else if (callSid) {
        const url = `${config.urls.apiRoot}/transcriptions/by-call/${callSid}`;
        fetch(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            try {
              setTranscription(data);
            } catch (error) {
              toast({
                title: 'Error',
                description: 'Failed to process transcription data',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              setTranscription('Error processing transcription data');
            }
          })
          .catch(() => {
            toast({
              title: 'Error',
              description: 'Failed to load transcription',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setTranscription('No transcription found');
          });
      }
    }
  }, [location.search, toast]);

  return (
    <>
      <Stack py={7} px={{ base: 2, sm: 8 }} bgColor="queenBlue" />
      <LeaseEndContainer minH="calc(100vh - 100px)">
        <Box p={4} maxW="1000px" mx="auto">
          <CardHeaderV2
            title=""
            variant="rounded"
            flexDirection="column"
            alignItems="flex-start"
            padding="10px 30px"
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              w="100%"
              fontWeight="bold"
              fontSize="18px"
            >
              <Flex justifyContent="space-between" w="100%">
                <Text>
                  {callDirection?.charAt(0).toUpperCase() + callDirection?.slice(1)} Call
                  Transcription - {customerName}. {formatPhoneNumberForDisplay(phoneNumber)}
                </Text>
                <>{startTime}</>
              </Flex>
              <Text fontSize="14px">Duration: {durationString}</Text>
            </Flex>
          </CardHeaderV2>
          <Box bgColor="white" p={4} borderRadius="0px 0px 10px 10px">
            <Text>{transcription}</Text>
          </Box>
        </Box>
      </LeaseEndContainer>
    </>
  );
};

export default Transcriptions;
