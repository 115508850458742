import { useMemo } from 'react';

import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';

import { useGetThunderConversationHistoryQuery } from '../../gql/generated/graphql';

import LeaseEndContainer from '../../components/Container/LEContainer';
import CardHeaderV2 from '../../components/shared/Card/components/CardHeaderV2';
import TextBubbleWithTimestamp from '../../components/shared/TextBubbleWithTimestamp';

import { getSearchParams } from '../../hooks/useUrlQueryParamsWithMultipleReducers';
import { formatPhoneNumberForDisplay } from '../../libs/utils';
import { isNullOrUndefined } from '../../utils';

const ThunderConversation = () => {
  const { phoneNumber } = getSearchParams(window.location.search);

  const { data } = useGetThunderConversationHistoryQuery({
    variables: { phoneNumber },
    skip: !phoneNumber,
  });
  const formattedConversation = useMemo(() => {
    const thunderMessages = data?.getThunderConversationHistoryByPhoneNumber?.messages;
    if (thunderMessages) {
      return thunderMessages
        .map((message) => ({
          timestamp: message?.timestamp ?? '',
          message: message?.text ?? '',
          direction: message?.direction,
        }))
        .filter((message) => !isNullOrUndefined(message.message))
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    }
    return [];
  }, [data]);

  return (
    <>
      <Stack py={7} px={{ base: 2, sm: 8 }} bgColor="queenBlue" />
      <LeaseEndContainer minH="calc(100vh - 100px)">
        <Box p={4} maxW="1000px" mx="auto">
          <CardHeaderV2
            title=""
            variant="rounded"
            flexDirection="column"
            alignItems="flex-start"
            padding="10px 30px"
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              w="100%"
              fontWeight="bold"
              fontSize="18px"
            >
              <Text>Thunder Conversation - {formatPhoneNumberForDisplay(phoneNumber)}</Text>
            </Flex>
          </CardHeaderV2>
          <VStack w="full" display="grid" bgColor="white" p={6}>
            {formattedConversation.map((msg, index) => {
              const isLastInGroup =
                index === formattedConversation.length - 1 ||
                (index < formattedConversation.length - 1 &&
                  formattedConversation[index + 1].direction !== msg.direction);

              return (
                <TextBubbleWithTimestamp
                  key={`${msg.timestamp}-${msg.direction}`}
                  timestamp={msg.timestamp}
                  message={msg.message}
                  isFromCustomer={msg.direction === 'INBOUND'}
                  isLastInGroup={isLastInGroup}
                />
              );
            })}
          </VStack>
        </Box>
      </LeaseEndContainer>
    </>
  );
};

export default ThunderConversation;
