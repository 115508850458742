import { useContext } from 'react';

import { Box, Text, Tooltip } from '@chakra-ui/react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum } from '../../gql/dealGql';
import { DOC_FEE } from '../../gql/financialInfoGql';
import {
  DealType,
  Maybe,
  PayoffRequestStatus,
  PayoffVerificationStatus,
  useOnPayoffRequestStatusUpdateSubscription,
  usePayoffUpdateMutation,
} from '../../gql/generated/graphql';
import { Payoff } from '../../gql/payoffGql';

import InfoCardV2 from '../InfoCard/InfoCardV2';

import PayoffInformationModal from './PayoffInformationModal';
import PayoffStatus from './PayoffStatus';

import { LienholderNameEnum } from '../../constants/lienholders';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { ModalContext } from '../../libs/contextLib';
import { formatDate, formatMoney } from '../../libs/utils';
import { cleanDealForUpdate, getFieldLabel } from '../../utils/deals';
import { getCorrectDocFee } from '../../utils/financialInfos';
import { getTotalPayoff } from '../../utils/payoffs';

const PayoffInfo = () => {
  const { deal, dispatch, isPayoffRequested, payoffRequestRefetch } = useContext(DealContext);
  const { modals, setModal } = useContext(ModalContext);

  const [updatePayoff] = usePayoffUpdateMutation();

  const notifyVehiclePayoffChanged = (newVehiclePayoff: Maybe<number>) => {
    const vehiclePayoffChanged = newVehiclePayoff !== deal?.car?.payoff?.vehicle_payoff;
    if (vehiclePayoffChanged) {
      toast.info('The Vehicle Payoff has changed. Please verify the Taxes.', {
        autoClose: false,
      });
    }
  };

  const onSubmit = async (values: Deal) => {
    try {
      const {
        car: { payoff },
      } = cleanDealForUpdate(values);

      // update Payoff
      const { data } = await updatePayoff({
        variables: {
          payoff: {
            ...payoff,
            verification_status:
              deal.car.payoff.vehicle_payoff === values.car.payoff.vehicle_payoff
                ? payoff.verification_status
                : PayoffVerificationStatus.Edited,
          },
        },
      });

      // TODO: temporary conversion. We should remove all classes and use the generated classes
      const updatedPayoff = (data?.payoffUpdate ?? {}) as Payoff;

      notifyVehiclePayoffChanged(updatedPayoff.vehicle_payoff);

      dispatch({
        type: DealActionsEnum.UpdateDeal,
        payload: {
          ...deal,
          car: {
            ...deal.car,
            payoff: {
              ...deal.car.payoff,
              ...updatedPayoff,
              // Set the frontend only fields to undefined so the initial values are recalculated in `TotalPayoff` component.
              doubleTaxApplied: undefined,
              previousTotalPayoff: undefined,
              totalPayoff: undefined,
            },
          },
          financial_info: {
            ...deal.financial_info,
            doc_fee: getCorrectDocFee(payoff.lienholder_name as LienholderNameEnum, DOC_FEE),
          },
        },
      });

      setModal({ PayoffInfo: false });
    } catch (e) {
      logger.error('LienholderInfo.tsx', '', null, e);
    }
  };

  const isRefi = deal.type === DealType.Refi;
  const goodThrough =
    deal.car.payoff.good_through_date && formatDate(deal.car.payoff.good_through_date, 'utc');
  const nextPaymentDate =
    deal.car.payoff.next_payment_date && formatDate(deal.car.payoff.next_payment_date, 'utc');
  const maturityDate =
    deal.car.payoff.maturity_date && formatDate(deal.car.payoff.maturity_date, 'utc');
  const paymentsRemaining = deal.car.payoff.remaining_payments;

  useOnPayoffRequestStatusUpdateSubscription({
    variables: { dealId: deal.id },
    onData: async ({ data: { data } }) => {
      if (!data?.onPayoffRequestStatusUpdate) {
        return;
      }

      // Not specifically a modal but a component outside of the form that needs to trigger the recalculation of the initial values.
      setModal({ UpdatePayoff: true });

      const updatedPayoff = data.onPayoffRequestStatusUpdate?.deal?.car?.payoff;
      if (
        updatedPayoff &&
        data.onPayoffRequestStatusUpdate.status !== PayoffRequestStatus.Cancelled &&
        data.onPayoffRequestStatusUpdate.status !== PayoffRequestStatus.Pending
      ) {
        // In other words, dispatch when is confirmed or failed.

        notifyVehiclePayoffChanged(updatedPayoff.vehicle_payoff);

        dispatch({
          type: DealActionsEnum.DeepUpdateDeal,
          payload: {
            car: {
              payoff: {
                // TODO: temporary conversion. We should remove all classes and use the generated classes
                ...(updatedPayoff as Deal['car']['payoff']),
              },
            },
          },
        });
      }

      // The refetch updates the `isPayoffRequested` state and the `Payoff Info` section.
      await payoffRequestRefetch();

      setModal({ UpdatePayoff: false });
    },
  });

  return (
    <>
      <InfoCardV2
        name="Payoff Info"
        values={{
          ...(isRefi
            ? { Lender: deal.car.payoff.lender_name }
            : { Lienholder: deal.car.payoff.lienholder_name }),
          'Account #': deal.car.payoff.account_number,
          'Total Payoff Amount': isPayoffRequested
            ? {
                render: (
                  <Box alignItems="center" display="inline-flex">
                    <Text as="i" color="gray">
                      Payoff Requested
                    </Text>
                    <Tooltip hasArrow label="Verified" placement="top">
                      <Box minW="16px">
                        <IoIosCheckmarkCircle color="#8CC63F" size={24} />
                      </Box>
                    </Tooltip>
                  </Box>
                ),
                value: undefined,
              }
            : {
                render: (
                  <Box display="flex" alignItems="center">
                    {formatMoney(getTotalPayoff(deal.car.payoff))}
                    <Box ml="4px" mb="2px">
                      <PayoffStatus
                        payoff={deal.car.payoff}
                        buyerNotLessee={deal.financial_info?.buyer_not_lessee}
                      />
                    </Box>
                  </Box>
                ),
                value: getTotalPayoff(deal.car.payoff).toString(),
              },
          'Vehicle Payoff': formatMoney(deal.car.payoff.vehicle_payoff),
          'Sales Tax From Payoff': formatMoney(deal.car.payoff.sales_tax_from_payoff),
          'Good Through': goodThrough,
          'Next Payment Date': nextPaymentDate,
          [getFieldLabel('oldPayment', deal.type)]: formatMoney(deal.car.payoff.old_lease_payment),
          ...(paymentsRemaining ? { 'Payments Remaining': paymentsRemaining?.toString() } : {}),
          ...(maturityDate ? { 'Maturity Date': maturityDate } : {}),
        }}
        showEditable={deal.state !== DealStateEnum.Estimate}
        editAction={() => setModal({ PayoffInfo: true })}
      />
      <PayoffInformationModal
        isOpen={modals.PayoffInfo}
        onClose={() => setModal({ PayoffInfo: false })}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default PayoffInfo;
