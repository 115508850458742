import { gql } from '@apollo/client';

export const lienholderQuery = gql`
  query lienholder($state: String!, $slug: String!) @api(name: prs) {
    lienholder(state: $state, slug: $slug) {
      id
      slug
      display_name
      phone_numbers {
        name
        phone_numbers
      }
      requirements_to_payoff_lease {
        direct_pay {
          value
        }
        include_sales_tax {
          value
        }
        payoff_through_dealer {
          value
        }
        residual_value {
          value
        }
      }
      requirements_to_get_payoff {
        id
        options
        get_payoff_methods {
          id
          call_as_tater_finance {
            value
          }
          conference_customer {
            value
          }
          request_customer_payoff {
            value
          }
          digital {
            value
          }
          auto_phone {
            value
          }
          phone {
            value
          }
        }
      }
      required_documents_to_payoff_lease {
        tater {
          value
        }
        send_payoff {
          value
        }
        titling {
          value
        }
        oem_odo {
          value
        }
        damage_disclosure {
          value
        }
      }
      double_tax
    }
  }
`;

export const lienholdersQuery = gql`
  query lienholders($state: String!, $make: String!) @api(name: prs) {
    lienholders(state: $state) {
      slug
      display_name
      probableLienholder(make: $make)
      enabled
    }
  }
`;
