import { useAuth0 } from '@auth0/auth0-react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { useUpdateUserCallStatusMutation } from '../../gql/generated/graphql';

import config from '../../config';

const UserMenu = () => {
  const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [updateStatus] = useUpdateUserCallStatusMutation();

  const handleLogout = async () => {
    // Set user inactive on log out
    await updateStatus({
      variables: {
        status: 'inactive',
      },
    });
    logout({
      logoutParams: {
        returnTo: config.auth0.logoutUri,
      },
    });
  };
  const handleClick = async () => (isAuthenticated ? handleLogout() : loginWithRedirect());

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="navBar"
        size={{ base: 'sm', sm: 'md' }}
        rightIcon={<ChevronDownIcon w="2em" h="2em" />}
      >
        {user?.name ?? 'User'}
      </MenuButton>
      <MenuList zIndex="dropdown" fontSize={{ base: 'xs', sm: 'md' }}>
        <MenuItem onClick={handleClick}>{isAuthenticated ? 'Logout' : 'Login'}</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
