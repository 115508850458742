import { object, string } from 'yup';
import { SchemaLike } from 'yup/lib/types';

import { EmploymentStatusType } from '../../gql/generated/graphql';

import { numberRequiredValidation } from './number';
import { phoneNumberValidationRequired } from './phoneNumber';
import { nameValidation, requiredStringValidation } from './strings';

export const JOB_TITLE_MAX_LENGTH = 20;

export const requirePreviousEmployment = (yearsAtJob: number | undefined | null = null) =>
  yearsAtJob !== null && yearsAtJob < 2;
export const isEmploymentInfoRequired = (employmentStatus?: EmploymentStatusType | null) =>
  !!employmentStatus && employmentStatus === EmploymentStatusType.Employed;

const prevEmploymentReq = (then: SchemaLike) =>
  object()
    .nullable()
    .when('employment.years_at_job', {
      is: (years: number) => requirePreviousEmployment(years),
      then,
    });

const employmentStrReq = (then: SchemaLike) =>
  string()
    .nullable()
    .when(['status', 'years_at_job', 'months_at_job'], {
      is: (status: EmploymentStatusType) => isEmploymentInfoRequired(status),
      then,
    });

export const employmentValidation = (person?: string) =>
  object().shape({
    name: employmentStrReq(nameValidation(person, 'Employer ')),
    status: requiredStringValidation(`${person || ''}Employment Status`).oneOf(
      Object.values(EmploymentStatusType) as string[],
      `Please select a valid ${person || ''}Employment Status value`,
    ),
    job_title: employmentStrReq(
      requiredStringValidation(`${person || ''}Job Title`).max(
        JOB_TITLE_MAX_LENGTH,
        `Please limit to ${JOB_TITLE_MAX_LENGTH} characters`,
      ),
    ),
    phone_number: employmentStrReq(phoneNumberValidationRequired(`${person || ''}Employer `)),
    years_at_job: numberRequiredValidation(`${person || ''}Years At Job`),
    months_at_job: numberRequiredValidation(`${person || ''}Months At Job`),
    gross_income: numberRequiredValidation(`${person || ''}Gross Income`),
    pay_frequency: requiredStringValidation(`${person || ''}Pay Frequency`),
  });

export const prevEmploymentValidation = (person?: string) =>
  prevEmploymentReq(
    object().shape({
      name: nameValidation(person, 'Previous Employer '),
      job_title: requiredStringValidation(`${person || ''}Previous Job Title`).max(
        JOB_TITLE_MAX_LENGTH,
        `Please limit to ${JOB_TITLE_MAX_LENGTH} characters`,
      ),
      years_at_job: numberRequiredValidation(`${person || ''}Years at previous job`),
      months_at_job: numberRequiredValidation(`${person || ''}Months at previous job`),
    }),
  );
