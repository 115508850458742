import { DealSourceEnum } from './gql/dealGql';
import { DealType } from './gql/generated/graphql';

import { CurrentView } from './pages/Dashboard';

export enum FiltersActionKind {
  SET_SELECTED_POD_ID = 'SET_SELECTED_POD_ID',
  SET_SOURCES = 'SET_SOURCES',
  SET_TYPES = 'SET_TYPES',
  SET_VIEW_AS_USER_ID = 'SET_VIEW_AS_USER_ID',
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW',
  SET_SHOW_ONLY_UNREAD = 'SET_SHOW_ONLY_UNREAD',
  SET_SHOW_ASSIGNED_TO_ME = 'SET_SHOW_ASSIGNED_TO_ME',
  SET_SHOW_FOLLOW_UPS = 'SET_SHOW_FOLLOW_UPS',
  SET_SHOW_BOOT_REQUESTED = 'SET_SHOW_BOOT_REQUESTED',
  SET_SHOW_ASSIGNED_TO_DELETED_USERS = 'SET_SHOW_ASSIGNED_TO_DELETED_USER',
  SET_SHOW_ACTIVE_TAGS = 'SET_SHOW_ACTIVE_TAGS',
  SET_SHOW_MATURE_FOLLOW_UPS = 'SET_SHOW_MATURE_FOLLOW_UPS',
  SET_LOCATION = 'SET_LOCATION',
  SET_GLOBAL_FILTERS = 'SET_GLOBAL_FILTERS',
  CLEAR_GLOBAL_FILTERS = 'CLEAR_GLOBAL_FILTERS',
}

export type FiltersStates = {
  selectedPodId?: number;
  sources: DealSourceEnum[];
  types: DealType[];
  viewAsUserId?: string;
  currentView?: CurrentView;
  showOnlyUnread: boolean;
  showAssignedToMe: boolean;
  showFollowUps: boolean;
  showBootRequested: boolean;
  showAssignedToDeletedUsers: boolean;
  showActiveTags: boolean;
  showMatureFollowUps: boolean;
  location?: string;
};

export const getFiltersStates = (searchParams?: {
  [K in keyof FiltersStates]?: string;
}): FiltersStates => ({
  selectedPodId: searchParams?.selectedPodId ? +searchParams.selectedPodId : undefined,
  sources: searchParams?.sources ? (searchParams.sources.split(',') as DealSourceEnum[]) : [],
  types: searchParams?.types
    ? (searchParams.types.split(',') as DealType[])
    : Object.values(DealType),
  viewAsUserId: searchParams?.viewAsUserId ? searchParams.viewAsUserId : undefined,
  currentView: searchParams?.currentView ? (searchParams.currentView as CurrentView) : undefined,
  showOnlyUnread: searchParams?.showOnlyUnread === 'true',
  showAssignedToMe: searchParams?.showAssignedToMe === 'true',
  showFollowUps: searchParams?.showFollowUps === 'true',
  showBootRequested: searchParams?.showBootRequested === 'true',
  showAssignedToDeletedUsers: searchParams?.showAssignedToDeletedUsers === 'true',
  showActiveTags: searchParams?.showActiveTags === 'true',
  showMatureFollowUps: searchParams?.showMatureFollowUps === 'true',
  location: searchParams?.location ? searchParams.location : undefined,
});

type StringArrayPayload = {
  type: FiltersActionKind.SET_SOURCES | FiltersActionKind.SET_TYPES;
  payload: string[];
};

type StringPayload = {
  type:
    | FiltersActionKind.SET_VIEW_AS_USER_ID
    | FiltersActionKind.SET_CURRENT_VIEW
    | FiltersActionKind.SET_LOCATION;
  payload: string | undefined;
};

type NumberPayload = {
  type: FiltersActionKind.SET_SELECTED_POD_ID;
  payload: number;
};

type BooleanPayload = {
  type:
    | FiltersActionKind.SET_SHOW_ONLY_UNREAD
    | FiltersActionKind.SET_SHOW_ASSIGNED_TO_ME
    | FiltersActionKind.SET_SHOW_FOLLOW_UPS
    | FiltersActionKind.SET_SHOW_BOOT_REQUESTED
    | FiltersActionKind.SET_SHOW_ASSIGNED_TO_DELETED_USERS
    | FiltersActionKind.SET_SHOW_ACTIVE_TAGS
    | FiltersActionKind.SET_SHOW_MATURE_FOLLOW_UPS;
  payload: boolean;
};

type SetFiltersPayload = {
  type: FiltersActionKind.SET_GLOBAL_FILTERS;
  payload: FiltersStates;
};

type ClearFiltersPayload = {
  type: FiltersActionKind.CLEAR_GLOBAL_FILTERS;
  payload: {
    allowedDealSources: DealSourceEnum[];
    types: DealType[];
  };
};

export type FiltersActions =
  | StringPayload
  | StringArrayPayload
  | BooleanPayload
  | NumberPayload
  | SetFiltersPayload
  | ClearFiltersPayload;

export const filtersReducer = (state: FiltersStates, action: FiltersActions): FiltersStates => {
  const { type, payload } = action;

  switch (type) {
    case FiltersActionKind.SET_SELECTED_POD_ID:
      return { ...state, selectedPodId: payload };
    case FiltersActionKind.SET_SOURCES:
      return { ...state, sources: payload as DealSourceEnum[] };
    case FiltersActionKind.SET_TYPES:
      return { ...state, types: payload as DealType[] };
    case FiltersActionKind.SET_VIEW_AS_USER_ID:
      return { ...state, viewAsUserId: payload };
    case FiltersActionKind.SET_CURRENT_VIEW:
      return { ...state, currentView: payload as CurrentView };
    case FiltersActionKind.SET_SHOW_ONLY_UNREAD:
      return { ...state, showOnlyUnread: payload };
    case FiltersActionKind.SET_SHOW_ASSIGNED_TO_ME:
      return { ...state, showAssignedToMe: payload };
    case FiltersActionKind.SET_SHOW_FOLLOW_UPS:
      return { ...state, showFollowUps: payload };
    case FiltersActionKind.SET_SHOW_BOOT_REQUESTED:
      return { ...state, showBootRequested: payload };
    case FiltersActionKind.SET_SHOW_ASSIGNED_TO_DELETED_USERS:
      return { ...state, showAssignedToDeletedUsers: payload };
    case FiltersActionKind.SET_SHOW_ACTIVE_TAGS:
      return { ...state, showActiveTags: payload };
    case FiltersActionKind.SET_SHOW_MATURE_FOLLOW_UPS:
      return { ...state, showMatureFollowUps: payload };
    case FiltersActionKind.SET_LOCATION:
      return { ...state, location: payload };
    case FiltersActionKind.SET_GLOBAL_FILTERS:
      return { ...state, ...payload };
    case FiltersActionKind.CLEAR_GLOBAL_FILTERS:
      return getFiltersStates({
        sources: payload.allowedDealSources.join(','),
        types: payload.types.join(','),
        currentView: state.currentView,
      });
    default:
      return state;
  }
};
