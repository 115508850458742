import { useEffect, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import {
  SwitchCallStatusDocument,
  SwitchCallStatusSubscription,
  useLoggedUserQuery,
} from '../gql/generated/graphql';
import { Pod } from '../gql/podGql';

export const useUser = () => {
  const { user: auth0User } = useAuth0();
  const { data, subscribeToMore } = useLoggedUserQuery();
  const dbUser = data?.loggedUser;

  useEffect(() => {
    // We don't need the initial data to set up the subscription.
    // Our subscription variables don't depend on the query result.
    const unsubscribe = subscribeToMore({
      document: SwitchCallStatusDocument,
      updateQuery: (prev, { subscriptionData }) => {
        // Cast `subscriptionData.data` to the correct type.
        // `subscribeToMore` returns a `LoggedUserQuery` type because expects the same return type as the query.
        const statusData = subscriptionData.data as SwitchCallStatusSubscription;
        if (!statusData || !statusData.switchCallStatus) {
          return prev;
        }

        return {
          loggedUser: {
            ...prev.loggedUser,
            call_status: statusData.switchCallStatus,
          },
        };
      },
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  const user = useMemo(
    () => ({
      ...auth0User,
      id: auth0User?.sub || '',
      email: auth0User?.email || '',
      name: auth0User?.name || '',
      nickname: auth0User?.nickname || '',

      phone_number: dbUser?.twilio_number || '',
      hours: dbUser?.hours || '',
      on_vacation: dbUser?.on_vacation || false,
      auto_assign_deals: dbUser?.auto_assign_deals || false,
      overnight_deals: dbUser?.overnight_deals || false,
      can_claim_as_closer: dbUser?.can_claim_as_closer ?? false,
      call_status: dbUser?.call_status || 'inactive',
      pods: (dbUser?.pods as unknown as Pod[]) ?? [],
    }),
    [
      auth0User,
      dbUser?.twilio_number,
      dbUser?.hours,
      dbUser?.on_vacation,
      dbUser?.auto_assign_deals,
      dbUser?.overnight_deals,
      dbUser?.can_claim_as_closer,
      dbUser?.call_status,
      dbUser?.pods,
    ],
  );

  return user;
};
