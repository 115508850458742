import { useContext } from 'react';

import { DashboardInboxContext } from '../libs/DashboardInboxContext';

export const useDashboardInboxContext = () => {
  const context = useContext(DashboardInboxContext);
  if (!context) {
    throw new Error(
      `useDashboardInboxContext returned \`undefined\`. Seems you forgot to use DashboardInboxContext.Provider`,
    );
  }

  return context;
};
