import { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { Box, Button, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import { Customer } from '../../gql/customerGql';
import { Deal } from '../../gql/dealGql';
import {
  useMessagesDbPaginatedQuery,
  useMessagesQuery,
  useOnReceivedMessageSubscription,
} from '../../gql/generated/graphql';
import { MediaListObj, Message } from '../../gql/messageGql';

import { VerticalScrollBarProps } from '../shared/CustomScrollbar';

import ContactPanel from './Components/ContactPanel';
import MessageBubble from './Components/Message';
import MessageInput from './Components/MessageInput';

import { NotesContext, TextWidgetContext } from '../../libs/contextLib';
import { formatPhone } from '../../libs/utils';

type TextModalProps = {
  deal: Deal | null;
  children: ReactNode;
};

export type ChatMessage = {
  id?: string;
  date: Date;
  fromCustomer: boolean;
  author: string;
  data: {
    text?: string;
    code?: string;
    dateCreated?: string;
    mediaListObj: MediaListObj | null;
  };
};

const TextModal = ({ deal, children }: TextModalProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const { notesRefetch } = useContext(NotesContext);
  const { activeConversation, unreadCount, setUnreadCount } = useContext(TextWidgetContext);

  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false);
  const [messageList, setMessageList] = useState<ChatMessage[]>([]);
  const [page, setPage] = useState(1);
  const [recipient, setRecipient] = useState<Customer>(deal?.customer as Customer);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dealPhoneNumbers = [
    deal?.customer.phone_number,
    deal?.customer.home_phone_number,
    deal?.cobuyer?.phone_number,
    deal?.cobuyer?.home_phone_number,
    deal?.contact?.phone_number,
    deal?.second_contact?.phone_number,
  ]
    .filter((phoneNumber) => phoneNumber)
    .map((phoneNumber) => formatPhone(phoneNumber));
  const formattedPhone = formatPhone(activeConversation.phone_number ?? '');
  const skipMessagesQueries = !formattedPhone || !dealPhoneNumbers.includes(formattedPhone);

  const addMessages = (messages: Message[]) => {
    const chatMessages: ChatMessage[] = messages.map((m) => {
      return {
        id: m.id,
        date: new Date(m?.dateCreated || new Date()),
        fromCustomer: !!m.fromCustomer,
        author: m.senderName,
        data: {
          text: m.body,
          dateCreated: new Date(m.dateCreated ?? new Date())
            .toLocaleString('en-US', {
              day: 'numeric',
              month: 'short',
              weekday: 'short',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
            .replace(/,/g, (a, b) => {
              return b > 5 ? ' at' : ',';
            }),
          mediaListObj: m.mediaListObj as MediaListObj,
        },
      };
    });
    const newMessageList = [...messageList, ...chatMessages].reduce((acc, current) => {
      const messageAlreadyInWidget = acc.some((item) => item.id === current.id);
      if (!messageAlreadyInWidget) {
        return acc.concat([current]);
      }
      return acc;
    }, [] as ChatMessage[]);

    setMessageList(newMessageList.sort((a, b) => (a.date > b.date ? 1 : -1)));
  };

  useMessagesQuery({
    variables: {
      dealPhoneNumber: formattedPhone,
    },
    skip: skipMessagesQueries,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.messages?.messages?.length) {
        const messages = data.messages.messages as unknown as Message[];
        addMessages(messages);
      } else {
        setLoading(false);
      }

      if (data?.messages?.hasErrors) {
        toast.error('Some messages failed to load.');
      }
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't fetch messages from server.");
    },
  });

  useOnReceivedMessageSubscription({
    variables: {
      dealPhoneNumbers: [formattedPhone],
    },
    onData: ({ data }) => {
      if (data?.data?.onReceivedMessage) {
        // make sure message belongs to this conversation
        if (data.data.onReceivedMessage?.message?.from === formattedPhone) {
          addMessages([data.data.onReceivedMessage?.message as unknown as Message]);
          notesRefetch();
          setUnreadCount(unreadCount + 1);
        }
      }
    },
  });

  useMessagesDbPaginatedQuery({
    variables: {
      dealPhoneNumber: formattedPhone,
      dealId: deal?.id?.toString() ?? '',
      page,
    },
    skip: skipMessagesQueries,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.messagesDbPaginated?.messages.length) {
        const messages = data.messagesDbPaginated.messages as unknown as Message[];
        const previousScrollHeight = containerRef?.current?.scrollHeight ?? 0;
        const previousScrollTop = containerRef?.current?.scrollTop ?? 0;

        addMessages(messages);
        setUnreadCount(0);
        setHasMoreMessages(data.messagesDbPaginated.hasMoreMessages);
        if (containerRef.current) {
          // Set the scroll position to stay at the current position
          setTimeout(() => {
            if (containerRef.current) {
              const newScrollHeight = containerRef.current.scrollHeight;
              containerRef.current.scrollTop =
                newScrollHeight - previousScrollHeight + previousScrollTop;
            }
          }, 0);
        }
      } else {
        setLoading(false);
        setLoadingOlderMessages(false);
      }
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't fetch messages from database.");
    },
  });

  const fetchOlderMessages = (newPage: number) => {
    setLoadingOlderMessages(true);
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    setMessageList([]);
  }, [activeConversation.phone_number]);

  useEffect(() => {
    if (messageList?.length) {
      setLoading(false);
      setLoadingOlderMessages(false);
    }
  }, [messageList]);

  useEffect(() => {
    if (!loading && messageList?.length && !loadingOlderMessages) {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading, messageList]);

  // Update recipient when deal changes
  useEffect(() => setRecipient(deal?.customer as Customer), [deal?.customer]);
  return (
    <Box
      position="fixed"
      right="25px"
      // Make it somewhat mobile friendly
      bottom={{ base: '20', md: '65px' }}
      w={{ base: '80%', md: '650px' }}
      h={{ base: '80%', md: '620px' }}
      boxShadow={{ base: 'none', md: 'xl' }}
      borderRadius="15px"
      zIndex="modal"
      fontSize={16}
    >
      <HStack
        h="60px"
        bg="oxfordBlue"
        color="white"
        justifyContent="space-between"
        borderTopRadius="15px"
        p={3}
      >
        <HStack>
          <Box
            cursor="pointer"
            fontSize={18}
            onClick={() => {
              const dealId = deal?.id?.toString();
              if (!pathname.includes(dealId ?? '')) {
                history.push(`/deals/${dealId}`);
              }
            }}
          >
            <Text textDecoration="underline">Deal {deal?.id}</Text>
          </Box>
        </HStack>
        {children}
      </HStack>
      <VStack>
        <ContactPanel deal={deal} setRecipient={setRecipient} />
        <Box boxSize="100%" bgColor="snowyWhite" borderBottomRadius="15px" overflow="hidden">
          <SimpleReactLightbox>
            <SRLWrapper>
              <VStack
                ref={containerRef}
                h="374px"
                overflowY="scroll"
                align="unset"
                w="100%"
                p={4}
                sx={VerticalScrollBarProps}
              >
                {loading ? (
                  <HStack h="65%" w="100%" align="center" justifyContent="center">
                    <Spinner />
                    <Box>Loading Messages...</Box>
                  </HStack>
                ) : messageList.length ? (
                  <>
                    {loadingOlderMessages ? (
                      <HStack h="65%" w="100%" align="center" justifyContent="center">
                        <Spinner />
                        <Box>Loading Messages...</Box>
                      </HStack>
                    ) : hasMoreMessages ? (
                      <Button
                        variant="secondary"
                        maxW="200px"
                        alignSelf="center"
                        onClick={() => fetchOlderMessages(page + 1)}
                      >
                        Load More Messages
                      </Button>
                    ) : null}
                    {messageList.map((m, index) => {
                      const isLastInGroup =
                        index === messageList.length - 1 ||
                        (index < messageList.length - 1 &&
                          messageList[index + 1].fromCustomer !== m.fromCustomer);

                      return <MessageBubble key={m.id} message={m} isLastInGroup={isLastInGroup} />;
                    })}
                    <div ref={messagesEndRef} />
                  </>
                ) : (
                  <HStack h="65%" w="100%" align="center" justifyContent="center">
                    <Box>No messages to show. Start a conversation!</Box>
                  </HStack>
                )}
              </VStack>
            </SRLWrapper>
          </SimpleReactLightbox>
          <MessageInput
            deal={deal}
            phone={formattedPhone}
            recipient={recipient}
            messageList={messageList}
            setMessageList={setMessageList}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default TextModal;
