/* eslint-disable func-names */
import * as Yup from 'yup';

import { dobValidation } from '../../libs/yup-validators/dob';
import { zipRegExp } from '../../utils/validation/address';
import { phoneRegExp } from '../../utils/validation/phoneNumber';

export const validationSchemaSoftClose = Yup.object().shape({
  financial_info_acquisition: Yup.object().shape({
    kbb_lending: Yup.number().required('KBB Lending is required').nullable(),
  }),
  condition_report: Yup.object().shape({
    accidents: Yup.string().required(),
    tires: Yup.string().required(),
    smoked_in: Yup.string().required(),
    lights_on_dash: Yup.string().required(),
    overall_condition: Yup.string().required(),
  }),
  customer: Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_number: Yup.string()
      .matches(phoneRegExp, {
        message: 'Please input a valid phone number',
        excludeEmptyString: true,
      })
      .test('phone_test', 'Phone number is required', function () {
        const { home_phone_number, phone_number } = this.options.context?.customer;

        const phoneNumberRequired = !home_phone_number;
        if (phoneNumberRequired) {
          return !!phone_number;
        }
        return true;
      }),
    home_phone_number: Yup.string()
      .matches(phoneRegExp, {
        message: 'Please input a valid phone number',
        excludeEmptyString: true,
      })
      .test('phone_test', 'Phone number is required', function () {
        const { home_phone_number, phone_number } = this.options.context?.customer;

        const homePhoneNumberRequired = !phone_number;
        if (homePhoneNumberRequired) {
          return !!home_phone_number;
        }

        return true;
      }),
    email: Yup.string().email('Please input a valid email address'),
  }),
  car: Yup.object().shape({
    vin: Yup.string().required('VIN is required'),
    mileage: Yup.string().required('Mileage is required'),
    color: Yup.string().required('Color is required'),
    mmr: Yup.number().required('MMR is required').nullable(),
  }),
});

export const validationSchemaClosing = Yup.object().shape({
  customer: Yup.object().shape({
    dob: dobValidation().nullable(),
    address: Yup.object().shape({
      address_line: Yup.string().required('Address is required'),
      address_line_2: Yup.string().optional().nullable(),
      zip: Yup.string().matches(zipRegExp, {
        message: 'Please input a valid zip code',
        excludeEmptyString: true,
      }),
    }),
  }),
  car: Yup.object().shape({
    mileage: Yup.string().required('Mileage is required'),
    payoff: Yup.object().shape({
      vehicle_payoff: Yup.number()
        .moreThan(0, 'Payoff must be greater than 0')
        .required('Payoff is required')
        .nullable(),
    }),
  }),
});

export const validationSchemaStructuring = Yup.object().shape({
  financial_info_acquisition: Yup.object().shape({
    kbb_lending: Yup.number().required('KBB Lending is required').nullable(),
  }),
  condition_report: Yup.object().shape({
    accidents: Yup.string().required(),
    tires: Yup.string().required(),
    smoked_in: Yup.string().required(),
    lights_on_dash: Yup.string().required(),
    overall_condition: Yup.string().required(),
  }),
});

export const validationSchemaAddAuction = Yup.object().shape({
  auction: Yup.object().shape({
    name: Yup.string().required('Name is required').nullable(),
    address_line: Yup.string().required('Address is required'),
    address_line_2: Yup.string().optional().nullable(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string()
      .matches(zipRegExp, {
        message: 'Please input a valid zip code',
        excludeEmptyString: true,
      })
      .required('ZIP is required'),
    phone_number: Yup.string()
      .matches(phoneRegExp, {
        message: 'Please input a valid phone number',
        excludeEmptyString: true,
      })
      .required('Phone number is required'),
  }),
});
