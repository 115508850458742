export type GotNewEstimateOption = 'yes' | 'no' | 'n/a';

export enum NewCustomerCallOutcomeEnum {
  NewEstimate = 'new_estimate',
  BankDMVProcessor = 'bank_dmv_processor',
  NewLeaseOrAcquisition = 'wants_new_lease_or_acquisition',
  CashBuyer = 'cash_buyer',
  DealershipOrLienholder = 'mistaken_for_dealership_or_lienholder',
  NoLease = 'no_lease',
  ComplaintRemoveMarketing = 'complaint_remove_marketing',
  MisdirectedCall = 'misdirected_call',
  Other = 'other',
}
