import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';

import { Box, Divider, Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';
import { CustomerInfo } from '../../gql/prs/types';

import MaskedSsnInput from '../MaskedSsn/MaskedSsnInput';
import Card from '../shared/Card';
import DatePicker from '../shared/DatePicker';
import { GridFormRow } from '../shared/GridForm';
import Input from '../shared/Input';

import RequestPayoffButton from './RequestPayoffButton';

import colors from '../../chakra/foundations/colors';
import { OtherLienholderSlug } from '../../constants/lienholders';
import { useLienholder } from '../../hooks/useLienholder';
import { useNewEstimateContext } from '../../hooks/useNewEstimateContext';
import { SsnTypeEnum, getSsnDetails, ssnDetailMap } from '../../utils/prs';
import { snakeCaseToTitleCase } from '../../utils/text';

interface LienholderDetailsProps {
  requiredCombinations: string[][];
  setRequiredCombinations: Dispatch<SetStateAction<string[][]>>;
  isPayoffRequestLoading: boolean;
  setIsPayoffRequestLoading: Dispatch<SetStateAction<boolean>>;
  isSaving: boolean;
}

export const LienholderLenderDetails = ({
  requiredCombinations,
  setRequiredCombinations,
  isPayoffRequestLoading,
  setIsPayoffRequestLoading,
  isSaving,
}: LienholderDetailsProps) => {
  const { values, handleBlur, validateForm, handleChange, setFieldValue } =
    useFormikContext<Deal>();

  // This props will be undefined when opening an existing `Estimate`. `NewEstimateProvider` only wraps `NewEstimate` in `Routes`.
  const { duplicateDealsModal, setDuplicateDeals, setShowPayoffRequestButton } =
    useNewEstimateContext();

  const {
    customer: { ssn, dob },
    car: {
      payoff: { account_number, lienholder_slug },
    },
  } = values;
  const isRefi = values.type === DealType.Refi;

  const { lienholderForm, loading: lienholderLoading } = useLienholder({
    data: values,
    skip: isRefi,
  });

  const [isLoading, setIsLoading] = useState<boolean>(lienholderLoading);

  const ALL_SSN = [CustomerInfo.SSN, CustomerInfo.SSN_LAST_4, CustomerInfo.SSN_LAST_6];
  const PRS_LIENHOLDER_FIELDS = [CustomerInfo.ACCOUNT_NUMBER, CustomerInfo.DOB, ...ALL_SSN];

  // `lienholderForm` needs the `state` to be set in order to be fetched.
  // We don't need it for refi deals.
  const isLienholderSelected = isRefi || lienholderForm !== undefined;
  const fieldLabel = 'Lienholder';

  useEffect(() => {
    if (lienholderLoading) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 700);
    }

    if (isRefi) {
      setRequiredCombinations([[CustomerInfo.ACCOUNT_NUMBER], [CustomerInfo.SSN]]);
      return;
    }

    const requirementsCombinations =
      lienholderForm?.payoff_requirements.map((requirement) =>
        requirement.filter((option) => PRS_LIENHOLDER_FIELDS.includes(option as CustomerInfo)),
      ) || [];

    setRequiredCombinations(requirementsCombinations);
  }, [lienholderForm?.payoff_requirements, lienholderLoading, isRefi]);

  useEffect(() => {
    if (requiredCombinations.length === 0) {
      return;
    }

    validateForm();
  }, [requiredCombinations]);

  useEffect(() => {
    validateForm();
  }, [ssn, account_number, dob]);

  const syncSsnValues = (name: string, value: string) => {
    if (name === 'customer.ssn') {
      const ssnLast4 = value.startsWith('___-__') ? `XXX-XX-${value.slice(-4)}` : value;
      const ssnLast6 = value.startsWith('___') ? `XXX-${value.slice(-7)}` : value;

      setFieldValue('customer.ssn_last_4', ssnLast4, false);
      setFieldValue('customer.ssn_last_6', ssnLast6, false);
    } else {
      const fixedCharactersRegExp = new RegExp(/x/gi);
      const maskPlaceholder = '_';

      const ssnFull = value?.match(fixedCharactersRegExp)
        ? value.replace(fixedCharactersRegExp, maskPlaceholder)
        : value;

      setFieldValue('customer.ssn', ssnFull, false);

      if (name === 'customer.ssn_last_4') {
        setFieldValue(
          'customer.ssn_last_6',
          `${value.slice(0, 3)}-${value.slice(-7).replace(fixedCharactersRegExp, maskPlaceholder)}`,
          false,
        );
      } else {
        setFieldValue(
          'customer.ssn_last_4',
          `${value.slice(0, 6)}-${value.slice(-4).replace(fixedCharactersRegExp, maskPlaceholder)}`,
          false,
        );
      }
    }
  };

  return (
    <Card mt={5} p={5} w="100%" variant="rounded" bgColor={colors.azureishWhite}>
      {isLoading ? (
        <Flex justifyContent="center">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Flex direction="column">
          <Box>
            {isLienholderSelected ? (
              <>
                {requiredCombinations.map((combination, index) => {
                  return (
                    <div key={combination.toString()}>
                      <GridFormRow key={combination.toString()} minChildWidth={135}>
                        {combination.map((field) => {
                          if (combination.length > 0 && field.startsWith(CustomerInfo.SSN)) {
                            return (
                              <MaskedSsnInput
                                key={field}
                                name={
                                  field === CustomerInfo.SSN
                                    ? 'customer.ssn'
                                    : field === CustomerInfo.SSN_LAST_4
                                    ? 'customer.ssn_last_4'
                                    : 'customer.ssn_last_6'
                                }
                                ssnDetails={getSsnDetails(field) ?? ssnDetailMap[SsnTypeEnum.Full]}
                                replaceFixedCharacters={field === CustomerInfo.SSN}
                                needsHidden={!!ssn}
                                inputProps={{
                                  bgColor: colors.white,
                                  _focus: {
                                    bgColor: colors.white,
                                  },
                                }}
                                customHandleChange={(e) => {
                                  handleChange(e);
                                  const { name, value } = e.target;

                                  syncSsnValues(name, value);
                                }}
                              />
                            );
                          }

                          if (combination.length > 0 && field === CustomerInfo.ACCOUNT_NUMBER) {
                            return (
                              <Input
                                key={field}
                                label="Account Number"
                                name="car.payoff.account_number"
                                value={account_number}
                                onBlur={handleBlur}
                                bgColor={colors.white}
                                _focus={{ bgColor: colors.white }}
                              />
                            );
                          }

                          if (combination.length > 0 && field === CustomerInfo.DOB) {
                            return (
                              <Box key={field}>
                                <DatePicker
                                  name="customer.dob"
                                  topLabel="Date of Birth"
                                  boxStyles={{
                                    w: '100%',
                                  }}
                                  valueFormat="dateUTC"
                                  inputProps={{
                                    bgColor: colors.white,
                                    _focus: { bgColor: colors.white },
                                  }}
                                />
                              </Box>
                            );
                          }

                          return null;
                        })}
                        {combination.length === 1 ? (
                          <>
                            <Box />
                            <Box />
                          </>
                        ) : null}
                        {combination.length === 2 ? <Box /> : null}
                      </GridFormRow>
                      {requiredCombinations.length > 1 &&
                      index < requiredCombinations.length - 1 ? (
                        <HStack my={4} mx={6}>
                          <Divider borderColor="gray" />
                          <Text color="gray">OR</Text>
                          <Divider borderColor="gray" />
                        </HStack>
                      ) : null}
                    </div>
                  );
                })}
              </>
            ) : lienholder_slug === OtherLienholderSlug ? (
              <h6>{fieldLabel} - Other</h6>
            ) : (
              <h6>Payoff Requirements - Select {fieldLabel}</h6>
            )}
          </Box>
          <Flex justifyContent="space-between" wrap="wrap" mt={4}>
            {!isRefi ? (
              <Box mt={6}>
                {lienholderForm ? (
                  <>
                    <Flex gap={4} mb={2}>
                      <Box w={`${100 / 3}%`}>
                        <Text>Contact information:</Text>
                      </Box>
                      <Box w={`${200 / 3}%`}>
                        <span className="font-weight-normal">
                          {lienholderForm.phone_numbers.join(' - ')}
                        </span>
                      </Box>
                    </Flex>
                    {lienholderForm.payoff_methods.length > 0 ? (
                      <Flex gap={4} mb={2}>
                        <Box w={`${100 / 3}%`}>
                          <Text>Available Payoff Methods: </Text>
                        </Box>
                        <Box w={`${200 / 3}%`}>
                          {lienholderForm.payoff_methods.map(({ key, value }, index) => {
                            const text =
                              value === 'TRUE'
                                ? snakeCaseToTitleCase(key)
                                : `${snakeCaseToTitleCase(key)} (${snakeCaseToTitleCase(value)})`;

                            return (
                              <Fragment key={key}>
                                <span className="font-weight-normal">{text}</span>
                                {index < lienholderForm.payoff_methods.length - 1 && <br />}
                              </Fragment>
                            );
                          })}
                        </Box>
                      </Flex>
                    ) : null}
                  </>
                ) : lienholder_slug === OtherLienholderSlug ? (
                  <p className="my-2">
                    Contact information - Work with customer to get payoff information about lien
                    holder. Reach out to Payoff Team directly, if necessary.
                  </p>
                ) : (
                  <p className="my-2">Contact information: Please select a lienholder</p>
                )}
              </Box>
            ) : (
              <Box />
            )}
            <Box mt={3} alignSelf="flex-end" ml="auto">
              <RequestPayoffButton
                isPayoffRequestLoading={isPayoffRequestLoading}
                isSaving={isSaving}
                setIsPayoffRequestLoading={setIsPayoffRequestLoading}
                setShowPayoffRequestButton={setShowPayoffRequestButton}
                setDuplicateDeals={setDuplicateDeals}
                duplicateDealsModal={duplicateDealsModal}
              />
            </Box>
          </Flex>
        </Flex>
      )}
    </Card>
  );
};
