import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Container, Heading, Text, VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

const AccessDenied = () => {
  const location = useLocation();
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const [errorInfo, setErrorInfo] = useState({
    error: '',
    description: '',
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error') || 'access_denied';
    const description =
      params.get('description') || 'You do not have permission to access this resource.';

    setErrorInfo({ error, description });
  }, [location]);

  const handleRetry = async () => {
    await loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
  };

  const handleHome = () => {
    history.push('/');
  };

  return (
    <Container maxW="container.md" mt={16}>
      <Box p={8} borderWidth="1px" borderRadius="lg" bg="white" boxShadow="lg">
        <VStack spacing={6}>
          <Heading as="h1" size="xl" color="red.500">
            Access Denied
          </Heading>

          <Text fontSize="lg">{errorInfo.description}</Text>

          {errorInfo.error && (
            <Text fontSize="sm" color="gray.500">
              Error code: {errorInfo.error}
            </Text>
          )}

          <Box pt={6}>
            <Button colorScheme="blue" mr={4} onClick={handleRetry}>
              Try Again
            </Button>
            <Button variant="outline" onClick={handleHome}>
              Return to Home
            </Button>
          </Box>
        </VStack>
      </Box>
    </Container>
  );
};

export default AccessDenied;
