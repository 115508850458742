import { Box, Button, Text } from '@chakra-ui/react';

import { Deal } from '../../gql/dealGql';

import Modal from '../shared/Modal';

type DealIssuesModalProps = {
  isOpen: boolean;
  onAcknowledge: () => void;
  onDismiss: () => void;
  deal?: Deal;
  directPayState?: boolean;
  doubleTax?: boolean;
  requiresWalkIn?: boolean;
  residualValue?: boolean;
  isLoading?: boolean;
};

const DealIssuesModal = ({
  isOpen,
  onAcknowledge,
  onDismiss,
  deal,
  directPayState,
  doubleTax,
  requiresWalkIn,
  residualValue,
  isLoading,
}: DealIssuesModalProps) => {
  const hasPossibleIssues = !!(directPayState || doubleTax || requiresWalkIn || residualValue);

  return (
    <Modal
      title="Possible Issues"
      isOpen={isOpen && hasPossibleIssues}
      onClose={onDismiss}
      isCentered
      closeOnOverlayClick={false}
      size="2xl"
      rightButtons={
        <Button onClick={onAcknowledge} isLoading={isLoading}>
          OK
        </Button>
      }
    >
      <Text mt={4} ml={4}>
        This deal may have the following issues based on the state & lienholder entered:
      </Text>
      <Box ml={16} mr={8} fontWeight="normal">
        {directPayState ? (
          <p>
            <br />
            <b>DIRECT PAY STATE:</b>
            <br />
            This driver will be required to find a dealer that will accept a check from us and go to
            the dealership to process their payoff. The buyer may also need to get their own payoff
            amount and payoff document.
          </p>
        ) : null}
        {/* ↓ This is just a warning shown based on state/lienholder combination logic from PRS. When
          sales tax is included in the payoff, AND buyer is not lessee, another modal will show informing 
          that double tax is being applied. Then the double tax badge will appear in the Payoff Info section */}
        {doubleTax ? (
          <p>
            <br />
            <b>LESSEE ONLY BUYOUT:</b>
            <br />
            {deal?.car.payoff.lienholder_name} allows only those on the lease to buy it out. If the
            buyer is different than those on the lease, the payoff amount will be higher. A copy of
            of the buyer's <b>registration</b> or <b>lease contract</b> will be required to verify
            the buyer is on the original lease.
          </p>
        ) : null}
        {requiresWalkIn ? (
          <p>
            <br />
            <b>WALK-IN STATE:</b>
            <br />
            This driver will be required to finish their title work in person. We will still process
            the paperwork, but the driver must submit it at their local DMV.
          </p>
        ) : null}
        {residualValue ? (
          <p>
            <br />
            <b>RESIDUAL VALUE PAYOFF:</b>
            <br />
            Instead of the customer payoff, this deal will need to be structured using the residual
            value. This value should be entered into the payoff field.
          </p>
        ) : null}
      </Box>
    </Modal>
  );
};

export default DealIssuesModal;
