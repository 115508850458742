import { gql } from '@apollo/client';

import { Maybe, TtDocumentName, TtNewBankEnum, TtProductType } from './generated/graphql';

import config from '../config';
import { BankEnum } from '../constants/bank';

export const getFeesQuery = gql`
  query getFees(
    $uniqueId: String
    $state: StateAbbreviation!
    $previouslyTitledState: StateAbbreviation!
    $city: String!
    $county: String!
    $zip: String!
    $vin: String!
    $make: String!
    $model: String!
    $year: Int!
    $vehicleType: TTVehicleType!
    $fuelType: TTFuelType!
    $firstName: String!
    $lastName: String!
    $retailBookValue: Float!
    $bookValue: Float!
    $docFee: Float!
    $payoff: Float!
    $estimatedPayoff: Float!
    $ssn: String!
    $warranty: Float!
    $financialInfoId: ID
    $source: String
  ) {
    getFees(
      uniqueId: $uniqueId
      state: $state
      previouslyTitledState: $previouslyTitledState
      city: $city
      county: $county
      zip: $zip
      vin: $vin
      make: $make
      model: $model
      year: $year
      vehicleType: $vehicleType
      fuelType: $fuelType
      firstName: $firstName
      lastName: $lastName
      retailBookValue: $retailBookValue
      bookValue: $bookValue
      docFee: $docFee
      payoff: $payoff
      estimatedPayoff: $estimatedPayoff
      ssn: $ssn
      warranty: $warranty
      financialInfoId: $financialInfoId
      source: $source
    ) {
      state
      totalFeeAmount
      salesTaxRate
      baseTaxAmount
      warrantyTaxAmount
      taxProcessor
      feeProcessor
      docFeeTaxAmount
      registrationTransferFee
    }
  }
`;

export const uploadDocument = gql`
  mutation uploadDocument($bytes: String!, $documentType: TTDocumentName!) {
    uploadDocument(bytes: $bytes, documentType: $documentType) {
      documentName
      key
      fields {
        confidence
        name
        value
      }
    }
  }
`;

export const uploadDriversLicense = gql`
  mutation uploadDriversLicense($bytes: String!) {
    uploadDriversLicense(bytes: $bytes) {
      firstName
      middleName
      lastName
      address
      city
      state
      zip
      expirationDate
    }
  }
`;

export const createTransaction = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input)
  }
`;

export type TTJurisdiction = {
  city: string;
  county: string;
  hasRegistrationOnly: boolean;
  hasTitleOnly: boolean;
  hasTitleAndRegistration: boolean;
  allowRegistrationTransfer: boolean;
  allowPlateTransfer: boolean;
  requireWalkIn: boolean;
  postalCode: string;
  registrationProduct?: TTProduct;
  state: StateAbbreviation;
  titleProduct?: TTProduct;
  transportationDistrict: string;
  products: TTProductConnection;
};

export type TTDocumentType = {
  name: TtDocumentName;
  parameters: TTParameter[];
};

export type TTValidationType = {
  name: TTValidationName;
  description: string;
};

export type TTProduct = {
  jurisdictionId?: string;
  type?: TtProductType;
  validations: TTValidationType[];
  documents?: TTProductDocumentConnection;
  jurisdiction?: TTJurisdiction;
};

export type TTParameter = {
  displayName: string;
  description: string;
  queries?: string[];
  name: string;
  type: TTParameterType;
};

export type TTProductDocument = {
  supportsImageExtraction: boolean;
  productId?: string;
  documentTypeId?: string;
  product?: TTProduct;
  type: TTDocumentType;
  validations: TTValidationType[];
};

// Connections
export type TTProductConnection = {
  count: number;
  items: TTProduct[];
  pageInfo: TTPageInfo;
};

export type TTProductDocumentConnection = {
  count: number;
  items: TTProductDocument[];
  pageInfo: TTPageInfo;
};

export type TTPageInfo = {
  endCursor: string;
  startCursor: string;
};

// Enums
export enum StateAbbreviation {
  AL = 'AL',
  AK = 'AK',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum TTDocumentFieldNames {
  NameOfInsured = 'nameOfInsured',
  StateName = 'stateName',
  Vin = 'vin',
  ExpirationDate = 'expirationDate',
  Company = 'company',
  PolicyNumber = 'policyNumber',
}

export enum TTValidationName {
  DigitalSignature = 'DigitalSignature',
  VerifiedDigitalSignature = 'VerifiedDigitalSignature',
  DriversLicenseNameMatch = 'DriversLicenseNameMatch',
  DriversLicenseAddressMatch = 'DriversLicenseAddressMatch',
  DriversLicenseStateMatch = 'DriversLicenseStateMatch',
  InsuranceNameMatch = 'InsuranceNameMatch',
  InsuranceStateMatch = 'InsuranceStateMatch',
  InsuranceNotExpired = 'InsuranceNotExpired',
  InsuranceVinMatch = 'InsuranceVinMatch',
  NotarizationRequired = 'NotarizationRequired',
  CobuyerOnInsurance = 'CobuyerOnInsurance',
  WetSignature = 'WetSignature',
  DigitalWetSignature = 'DigitalWetSignature',
  RegistrationPictureRequired = 'RegistrationPictureRequired',
  RegistrationNotExpired = 'RegistrationNotExpired',
  RegistrationNotExpired60Days = 'RegistrationNotExpired60Days',
  RegistrationNotExpired90Days = 'RegistrationNotExpired90Days',
  LiabilityInsuranceRequired = 'LiabilityInsuranceRequired',
}

export enum TTParameterType {
  STRING = 'string',
  ENUM = 'enum',
  DATE = 'date',
  BOOL = 'boolean',
}

export const getCreateTransactionCallbackUrl = () => `${config.urls.apiRoot}/create-transaction`;

const bankEnumToTTNewBankEnumMap: Record<BankEnum, TtNewBankEnum> = Object.entries(BankEnum).reduce(
  (acc, [key, value]) => ({ ...acc, [value]: key as TtNewBankEnum }),
  {} as Record<BankEnum, TtNewBankEnum>,
);

export const mapBankEnumToTTNewBankEnum = (bank: Maybe<BankEnum>) =>
  bank ? bankEnumToTTNewBankEnumMap[bank] : undefined;
