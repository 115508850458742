import { Fragment, useMemo } from 'react';

import { Box, Table, TableContainer, Tbody, Text } from '@chakra-ui/react';
import { format, isToday, isYesterday } from 'date-fns';

import { Call } from '../../../gql/generated/graphql';

import PhoneCallRow from './PhoneCallRow';

import { useDashboardInboxContext } from '../../../hooks/useDashboardInboxContext';
import Pagination from '../../Pagination';

const UserPhoneCallHistory = () => {
  const { userCallHistoryData, callHistoryPaginationControls } = useDashboardInboxContext();

  const groupCallsByDay = useMemo(() => {
    return (userCallHistoryData?.getUserCallHistory?.calls ?? []).reduce((acc, call) => {
      const dateKey = format(new Date(call?.created_at), 'MM/dd/yyyy');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(call as Call);
      return acc;
    }, {} as Record<string, Call[]>);
  }, [userCallHistoryData]);

  return (
    <>
      <Box borderWidth="2px" borderBottomRadius="10px" borderColor="lightGray">
        <TableContainer height="max" style={{ height: 540, overflowY: 'scroll' }}>
          <Table bgColor="white">
            <Tbody>
              {/* add a row for each different day */}
              {Object.keys(groupCallsByDay).map((dateKey) => (
                <Fragment key={dateKey}>
                  <Text pt="10px" pl="20px" pb={0} color="silverSlateGray">
                    {isToday(new Date(dateKey))
                      ? 'TODAY'
                      : isYesterday(new Date(dateKey))
                      ? 'YESTERDAY'
                      : new Date(dateKey)
                          .toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                          })
                          .toLocaleUpperCase()}
                  </Text>

                  {groupCallsByDay[dateKey].map((call: Call) => (
                    <PhoneCallRow key={call.id} call={call} />
                  ))}
                </Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          paginationControls={callHistoryPaginationControls}
          borderTop="1px solid"
          borderColor="lightGray"
        />
      </Box>
    </>
  );
};

export default UserPhoneCallHistory;
