import { useState } from 'react';

import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { HiSearch, HiX } from 'react-icons/hi';
import { MdPersonAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Maybe,
  Pod,
  SortDirection,
  User,
  useGetUsersForManagementQuery,
} from '../../gql/generated/graphql';

import LETable from '../../components/shared/Table/LETable';
import { TableColumns } from '../../components/shared/Table/types';

import CreateUserModal from './User/CreateUserModal';
import DeleteUserModal from './User/DeleteUserModal';
import EditUserModal from './User/EditUserModal';

import useDebounce from '../../hooks/useDebounce';
import usePaginationControls from '../../hooks/usePaginationControls';
import { formatDate } from '../../libs/utils';

const columns: TableColumns<User> = [
  {
    header: 'Name',
    getComponent: ({ item }) => item.name,
    sortingKey: 'name',
    styleProps: { whiteSpace: 'nowrap' },
  },
  {
    header: 'Date',
    getComponent: ({ item }) => formatDate(item.created_at?.toString()),
    sortingKey: 'created_at',
  },
  {
    header: 'Teams',
    getComponent: ({ item }) => item.pods?.map((pod: Maybe<Pod>) => pod?.name).join(', '),
  },
  {
    header: 'Location',
    getComponent: ({ item }) => {
      if (!item.location) {
        return '-';
      }
      return item.location.charAt(0).toUpperCase() + item.location.slice(1);
    },
  },
  {
    header: 'Roles',
    getComponent: ({ item }) => item.auth0_roles?.roles?.map((role) => role?.name).join(', '),
  },
  {
    header: 'Phone Number',
    getComponent: ({ item }) => item.phone_number,
  },
  {
    header: 'Twilio Number',
    getComponent: ({ item }) => item.twilio_number,
  },
  {
    header: 'Actions',
    styleProps: { textAlign: 'center', minW: '125px' },
    getComponent: ({ item, handleEdit, handleDelete }) => (
      <>
        <IconButton
          icon={<BiEdit />}
          variant="iconHover"
          size="xs"
          aria-label="Edit User"
          onClick={(e) => {
            e.stopPropagation();
            handleEdit?.(item);
          }}
        />
        <IconButton
          icon={<BiTrash />}
          variant="iconHover"
          size="xs"
          color="errorsRed"
          aria-label="Delete User"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete?.(item);
          }}
        />
      </>
    ),
  },
];

const UsersTable = () => {
  const paginationControls = usePaginationControls();

  const createModal = useDisclosure();
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();

  const [searchText, setSearchText] = useState<string>('');
  const [selectedUser, setSelected] = useState<User | null>(null);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState(SortDirection.Asc);

  const setSearchTextDebounced = useDebounce(setSearchText, 500);

  const {
    data: getUsersManagementData,
    loading,
    refetch: refetchUsers,
  } = useGetUsersForManagementQuery({
    variables: {
      currentPage: paginationControls.currentPage,
      itemsPerPage: paginationControls.itemsPerPage,
      sortColumn,
      sortDirection,
      searchText,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => paginationControls.setTotalRecords(data.getUsers?.totalRecords ?? 0),
    onError: (error) => toast.error(`Couldn't load Users. Please refresh. Error: ${error.message}`),
  });

  return (
    <>
      <LETable
        columns={columns}
        rowKeyPrefix="user"
        rowKeyProp="id"
        items={getUsersManagementData?.getUsers?.results ?? []}
        isLoading={loading}
        sortProps={{
          sortColumn,
          setSortColumn,
          sortDirection,
          setSortDirection,
        }}
        paginationControls={paginationControls}
        handleEdit={(user) => {
          setSelected(user);
          editModal.onOpen();
        }}
        handleDelete={(user) => {
          setSelected(user);
          deleteModal.onOpen();
        }}
        childrenContainerProps={{ justifyContent: 'space-between' }}
      >
        <InputGroup maxW="200px">
          <InputLeftElement pointerEvents="none" h="full">
            <HiSearch color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search Users"
            minW="170px"
            maxW="200px"
            borderRadius={3}
            bgColor="white"
            onChange={(e) => setSearchTextDebounced(e.target.value)}
          />
          {searchText.length > 0 ? (
            <InputRightElement cursor="pointer" h="full" onClick={() => setSearchTextDebounced('')}>
              <HiX color="gray.300" />
            </InputRightElement>
          ) : null}
        </InputGroup>
        <Button
          variant="secondary"
          leftIcon={<MdPersonAdd size="20px" />}
          onClick={createModal.onOpen}
        >
          Add User
        </Button>
      </LETable>

      <DeleteUserModal
        user={selectedUser}
        onClose={async () => {
          setSelected(null);
          deleteModal.onClose();
          await refetchUsers();
        }}
        isOpen={deleteModal.isOpen}
      />

      <CreateUserModal
        isOpen={createModal.isOpen}
        onClose={async () => {
          createModal.onClose();
          await refetchUsers();
        }}
      />

      <EditUserModal
        user={selectedUser}
        isOpen={editModal.isOpen}
        onClose={async () => {
          editModal.onClose();
          await refetchUsers();
        }}
      />
    </>
  );
};

export default UsersTable;
