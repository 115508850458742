import { useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Icon } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { AiFillCalculator } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { Deal, dealInfoUpsert } from '../../../../gql/dealGql';
import { LTV_THRESHOLD } from '../../../../gql/financialInfoGql';
import { useGapRatesLazyQuery } from '../../../../gql/generated/graphql';

import Tooltip from '../../../shared/Tooltip';

import { PermissionEnum } from '../../../../constants/permissions';
import { Can } from '../../../../libs/Can';
import { DealContext } from '../../../../libs/DealContext';
import { isNumber } from '../../../../libs/utils';
import { getLtvRatio } from '../../../../utils/deals';

const CalcGapButton = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Deal>();
  const { setUserChangedSomething } = useContext(DealContext);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [ltv, setLtv] = useState<number>(0);
  const [missingInfo, setMissingInfo] = useState<boolean>(true);

  const [upsertDealInfo] = useMutation(dealInfoUpsert);

  const updateField = (name: string, value: unknown) => {
    setFieldValue(name, value);
    setTimeout(() => setFieldTouched(name, true));
  };

  const [loadGap, { loading }] = useGapRatesLazyQuery({
    // Returns an empty object when using network-only.
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.gapRates?.[0]) {
        const rate = data.gapRates[0];
        updateField('financial_info.pen_gap_session_id', rate.session_id);
        updateField('financial_info.pen_gap_rate_id', rate.deductible?.rate_id);
        updateField('financial_info.pen_gap_form_id', rate.form_id);
        updateField('financial_info.gap_cost', +(rate.deductible?.dealer_cost ?? ''));
        updateField('financial_info.gap_price', +(rate.deductible?.retail_price ?? ''));

        toast.success('GAP rate retrieved and updated successfully');
      }
    },
    onError: (error) => {
      toast.error(error?.message || 'Failed to retrieve GAP rate');

      setFieldValue('financial_info.pen_gap_session_id', null);
      setFieldValue('financial_info.pen_gap_rate_id', null);
      setFieldValue('financial_info.pen_gap_form_id', null);
      setFieldValue('financial_info.gap_cost', null);
      setFieldValue('financial_info.gap_price', null);

      upsertDealInfo({
        variables: {
          financialInfo: {
            id: values.financial_info.id,
            deal_id: values.id,
            pen_gap_session_id: null,
            pen_gap_rate_id: null,
            pen_gap_form_id: null,
            gap_cost: null,
            gap_price: null,
          },
        },
        onCompleted: () => {
          toast.success('GAP rate reset successfully');
        },
      });
    },
  });

  const missingInfoErrors = [
    !values.car.year ? 'Car Year' : '',
    !values.car.vin ? 'VIN' : '',
    !values.car.mileage ? 'Mileage' : '',
    !isNumber(values.car.retail_book_value) ? 'KBB Retail' : '',
    !isNumber(values.car.payoff.vehicle_payoff) ? 'Payoff' : '',
    !isNumber(values.financial_info.sell_rate) ? 'Sell Rate' : '',
    !isNumber(values.financial_info.term) ? 'Term' : '',
    !values.customer.address?.state ? 'State' : '',
  ];

  useEffect(() => {
    setMissingInfo(missingInfoErrors.some(Boolean));
  }, [missingInfoErrors]);

  useEffect(() => {
    const af = values.financial_info.amount_financed;
    const rbv = values.car.retail_book_value;
    if (af && rbv) {
      setLtv(getLtvRatio(af, rbv));
    }
  }, [values.financial_info.amount_financed, values.car.retail_book_value]);

  useEffect(() => {
    setIsDisabled(missingInfo || ltv < LTV_THRESHOLD);
  }, [missingInfo, ltv]);

  return (
    <Can I={PermissionEnum.CalculateGap}>
      <Tooltip errors={ltv < LTV_THRESHOLD ? ['LTV Too Low'] : missingInfoErrors.filter(Boolean)}>
        <Button
          variant="secondary"
          size="sm"
          leftIcon={<Icon as={AiFillCalculator} fontSize="md" />}
          isLoading={loading}
          loadingText="CALC GAP RATE"
          isDisabled={isDisabled}
          onClick={() => {
            setUserChangedSomething(true);

            loadGap({
              variables: {
                bank: values.financial_info.bank ?? '',
                deal_id: values.id ?? 0,
                data: {
                  year: parseInt(values.car.year, 10),
                  vin: values.car.vin,
                  mileage: values.car.mileage,
                  msrp: values.car.book_value,
                  purchasePrice: values.car.payoff.vehicle_payoff,
                  apr: values.financial_info.sell_rate,
                  financedAmount: values.financial_info.amount_financed,
                  financeTerms: values.financial_info.term,
                  financeTermsMileage: 0,
                  state: values.customer.address?.state,
                },
              },
            });
          }}
        >
          CALC GAP RATE
        </Button>
      </Tooltip>
    </Can>
  );
};

export default CalcGapButton;
