import React, { MouseEvent, useCallback, useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { HStack, Td } from '@chakra-ui/react';

import {
  PayoffRequestStatus,
  useUpdatePayoffClerkMutation,
} from '../../../../gql/generated/graphql';
import { PayoffRequest } from '../../../../gql/payoffRequestGql';

import ClaimDealButton from './ClaimDealButton';
import ConfirmPayoff from './ConfirmPayoff';
import ConfirmPayoffFailure from './ConfirmPayoffFailure';
import RequestPayoffFailure from './RequestPayoffFailure';
import UploadPayoffDocs from './UploadPayoffDocs';

import { PermissionEnum } from '../../../../constants/permissions';
import { AbilityContext } from '../../../../libs/contextLib';

interface PayoffDealActionsProps {
  payoffRequest?: PayoffRequest;
  claimedBy?: string;
  dealId?: string;
  tempInfoId?: string;
}

const PayoffDealActions: React.FC<PayoffDealActionsProps> = ({
  payoffRequest,
  claimedBy,
  dealId,
  tempInfoId,
}) => {
  const { user } = useAuth0();
  const ability = useContext(AbilityContext);

  const [update] = useUpdatePayoffClerkMutation();

  const showConfirmPayoffButton =
    payoffRequest && payoffRequest?.status !== PayoffRequestStatus.Completed;
  const canConfirmPayoffFailure = ability.has(PermissionEnum.ConfirmPayoffFailure);
  const isClaimedByCurrentUser = claimedBy === user?.sub;
  const allowedToClaimAndConfirm =
    ability.has(PermissionEnum.PayoffManager) || isClaimedByCurrentUser;

  const onClaim = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      update({
        variables: {
          payoff_request_id: payoffRequest?.id,
          payoff_clerk_id: claimedBy ? null : user?.sub,
        },
      });
    },
    [claimedBy, dealId, tempInfoId, user],
  );
  return (
    <Td>
      <HStack spacing="0.5">
        <ClaimDealButton
          isClaimed={!!claimedBy}
          allowedToClaim={allowedToClaimAndConfirm}
          onClick={onClaim}
        />
        {showConfirmPayoffButton ? (
          <ConfirmPayoff payoffRequest={payoffRequest} disabled={!allowedToClaimAndConfirm} />
        ) : (
          <UploadPayoffDocs payoffRequest={payoffRequest} disabled={!allowedToClaimAndConfirm} />
        )}
        {canConfirmPayoffFailure ? (
          <ConfirmPayoffFailure payoffRequest={payoffRequest} buttonType="button" />
        ) : (
          <RequestPayoffFailure
            payoffRequest={payoffRequest}
            disabled={
              !isClaimedByCurrentUser ||
              payoffRequest?.status === PayoffRequestStatus.FailureRequested
            }
          />
        )}
      </HStack>
    </Td>
  );
};

export default PayoffDealActions;
