import { useEffect, useMemo, useState } from 'react';

import { File } from '../gql/contractGql';
import { Deal } from '../gql/dealGql';
import {
  DealMediaTypeEnum,
  DealMediaTypeEnum as GeneratedDealMediaTypeEnum,
  Maybe,
  TtDocumentName,
  TtJurisdiction,
  useUpdateDealNeedsElectronicSignatureVerificationMutation,
} from '../gql/generated/graphql';

import { parseFilename } from '../components/MediaCenter/utils';
import { DealMediaCustomerTypes, DealMediaNoSignatureUpload } from '../constants/media';
import { DealActions, DealActionsEnum } from '../libs/DealContext';
import { logger } from '../libs/Logger';
import { DealMedia } from '../types/media';
import {
  isNotaryRequired,
  isVerifiedDigitalSignatureRequired,
  isWetSignatureRequired,
  mapTTDocumentType,
} from '../utils/media';

export interface DocumentMedia {
  mediaList: DealMedia[];
  internalMediaList: DealMedia[];
  customerMediaList: DealMedia[];
  unverifiedRequiredMediaList: DealMedia[];
  missingRequiredMediaList: DealMedia[];
  signatureFileList: File[];
}

type UseDocumentMediaProps = {
  deal: Deal;
  dealDispatch: (value: DealActions) => void;
  media?: Maybe<Maybe<DealMedia>>[] | null;
  jurisdiction?: Maybe<TtJurisdiction> | null;
};

const useDocumentMedia = ({ deal, dealDispatch, media, jurisdiction }: UseDocumentMediaProps) => {
  const [mediaList, setMediaList] = useState<DealMedia[]>([]);

  const [
    updateDealNeedsElectronicSignatureVerification,
    { loading: updateDealNeedsElectronicSignatureVerificationLoading },
  ] = useUpdateDealNeedsElectronicSignatureVerificationMutation();

  const { internal: internalMediaList, customer: customerMediaList } = useMemo(
    () =>
      mediaList.reduce(
        (acc, mediaItem) => {
          if (mediaItem.type && !DealMediaCustomerTypes.includes(mediaItem.type)) {
            acc.internal.push(mediaItem);
          } else {
            acc.customer.push(mediaItem);
          }
          return acc;
        },
        { internal: [], customer: [] } as {
          internal: DealMedia[];
          customer: DealMedia[];
        },
      ),
    [mediaList],
  );

  const requiredMediaList = useMemo((): DealMedia[] => {
    const requiredTTDocuments =
      jurisdiction?.products?.items?.flatMap((p) => p?.documents?.items) ?? [];

    return requiredTTDocuments
      .filter(
        (ttDocument, index, array) =>
          index === array.findIndex((t) => t?.type?.name === ttDocument?.type?.name) &&
          Object.values(TtDocumentName).includes(ttDocument?.type?.name as TtDocumentName),
      )
      .map((doc) => ({
        type: mapTTDocumentType(doc?.type?.name),
        verified_digital_signature_required: isVerifiedDigitalSignatureRequired(doc?.validations),
        wet_signature_required: isWetSignatureRequired(doc?.validations),
        notary_required: isNotaryRequired(doc?.validations),
      }));
  }, [jurisdiction]);

  const needsElectronicSignatureVerification = useMemo(
    () =>
      requiredMediaList.some(
        (m) =>
          m.type === DealMediaTypeEnum.ElectronicSignatureAttestation ||
          m.type === DealMediaTypeEnum.ElectronicSignatureCertification,
      ),
    [requiredMediaList],
  );

  // `verified` null means the user has already uploaded the document but it has not been verified yet.
  const unverifiedRequiredMediaList = useMemo(
    () =>
      customerMediaList.filter((m) => !!m.type && (m.verified === null || m.verified === false)),
    [customerMediaList],
  );

  const missingRequiredMediaList = useMemo(
    () => customerMediaList.filter((m) => !!m.type && !m.key),
    [customerMediaList],
  );

  const signatureFileList: File[] = useMemo(
    () =>
      mediaList
        .filter(
          (mediaItem) =>
            parseFilename(mediaItem.key ?? '').isPdf &&
            mediaItem.type &&
            !DealMediaNoSignatureUpload.includes(mediaItem.type),
        )
        .map((mediaItem) => ({
          url: mediaItem.signed_url ?? '',
          filename: parseFilename(mediaItem.key ?? '').sourceFileName,
          key: mediaItem.key ?? '',
          mediaType: mediaItem.type as GeneratedDealMediaTypeEnum,
        })),
    [mediaList],
  );

  const handleUpdateDealNeedsElectronicSignatureVerification = async (
    newNeedsElectronicSignatureVerification: boolean,
  ) => {
    if (!deal?.id) {
      return;
    }

    try {
      const result = await updateDealNeedsElectronicSignatureVerification({
        variables: {
          deal_id: deal.id,
          needs_electronic_signature_verification: newNeedsElectronicSignatureVerification,
        },
      });

      if (result.data?.updateDealNeedsElectronicSignatureVerification) {
        dealDispatch({
          type: DealActionsEnum.UpdateDeal,
          payload: {
            needs_electronic_signature_verification:
              !!result.data.updateDealNeedsElectronicSignatureVerification
                .needs_electronic_signature_verification,
          },
        });
      }
    } catch (e) {
      logger.error('useDocumentMedia.ts', '', null, e);
    }
  };

  useEffect(() => {
    if (
      !updateDealNeedsElectronicSignatureVerificationLoading &&
      needsElectronicSignatureVerification !== deal.needs_electronic_signature_verification
    ) {
      handleUpdateDealNeedsElectronicSignatureVerification(needsElectronicSignatureVerification);
    }
  }, [needsElectronicSignatureVerification]);

  // TODO: Finish this refactor and try to move it to backend
  const extractDocumentMedia = async () => {
    const newMedia: DealMedia[] =
      media
        ?.filter((dm) => dm !== null && dm !== undefined)
        .map((dm) => {
          const matchedRequiredDocument = requiredMediaList.find((rm) => rm.type === dm.type);
          if (matchedRequiredDocument) {
            return {
              ...dm,
              ...matchedRequiredDocument,
            };
          }
          return dm;
        }) ?? [];

    const uploadedTypes = media?.map((m) => m?.type) ?? [];
    const notUploadedRequiredMedia = requiredMediaList
      .filter((m) => !uploadedTypes.includes(m.type))
      .map((m) => ({
        ...m,
        has_verified_digital_signature: false,
        has_wet_signature: false,
        is_notarized: false,
      }));

    setMediaList([...newMedia, ...notUploadedRequiredMedia]);
  };

  useEffect(() => {
    extractDocumentMedia();
  }, [media, requiredMediaList]);

  const documentMedia: DocumentMedia = {
    mediaList,
    internalMediaList,
    customerMediaList,
    unverifiedRequiredMediaList,
    missingRequiredMediaList,
    signatureFileList,
  };

  return { documentMedia };
};

export default useDocumentMedia;
