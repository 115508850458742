import { useContext, useEffect, useMemo, useReducer } from 'react';

import { Box, Flex, Icon, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import {
  OnDealUpdateDocument,
  OnDealUpdateSubscription,
  OnPayoffRequestUpdateDocument,
  OnPayoffRequestUpdateSubscription,
  SortDirection,
  usePayoffDashboardDealsQuery,
} from '../../gql/generated/graphql';
import { PayoffRequest } from '../../gql/payoffRequestGql';

import Loader from '../Loader';
import { SortingActionKind, isSortingAsc, sortingReducer } from '../Titling/utils';
import { payoffTableColumns } from './components/ColumnComponents';
import DealRowContainer from './components/DealRowContainer';
import TableFilters from './components/TableFilters';

import { AbilityContext, FiltersContext } from '../../libs/contextLib';
import {
  filterPayoffRequests,
  parsePayoffRequestsFromDashboard,
  shouldAddOrUpdatePayoffRequest,
  sortPayoffRequests,
} from '../../utils/payoffRequests';

const INITIAL_SORTING_STATE = {
  field: 'created_at',
  direction: SortDirection.Asc,
};

const PayoffDealsTable = () => {
  const abilities = useContext(AbilityContext);

  const [sorting, dispatchSorting] = useReducer(sortingReducer, INITIAL_SORTING_STATE);
  const { field = INITIAL_SORTING_STATE.field, direction } = sorting;

  const {
    filters: {
      payoff: payoffFilters,
      global: { sources },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const { data, loading, subscribeToMore } = usePayoffDashboardDealsQuery();
  const payoffRequests = data?.payoffDashboardDeals?.payoffRequests;
  const dealIds = useMemo(
    () =>
      payoffRequests?.reduce(
        (acc, pr) => (pr?.deal?.id ? [...acc, pr.deal.id] : acc),
        [] as number[],
      ) ?? [],
    [payoffRequests],
  );

  useEffect(() => {
    // We don't need the initial data to set up the subscription.
    // Our subscription variables don't depend on the query result.
    const unsubscribe = subscribeToMore({
      document: OnPayoffRequestUpdateDocument,
      updateQuery: (prev, { subscriptionData }) => {
        // Cast `subscriptionData.data` to the correct type.
        // `subscribeToMore` returns a `PayoffDashboardDealsQuery` type because expects the same return type as the query.
        const { onPayoffRequestUpdate: payoffRequest } =
          subscriptionData.data as OnPayoffRequestUpdateSubscription;
        if (!payoffRequest) {
          return prev;
        }

        const existingPayoffRequests = prev.payoffDashboardDeals?.payoffRequests ?? [];
        const updatedPayoffRequests = [...existingPayoffRequests];

        const payoffRequestIndex = existingPayoffRequests.findIndex(
          (pr) => pr?.id === payoffRequest.id,
        );
        const addOrUpdatePayoffRequest = shouldAddOrUpdatePayoffRequest(payoffRequest, abilities);

        if (addOrUpdatePayoffRequest && payoffRequestIndex === -1) {
          // Add new payoff request
          updatedPayoffRequests.unshift(payoffRequest);
        } else if (addOrUpdatePayoffRequest && payoffRequestIndex !== -1) {
          // Update existing payoff request
          updatedPayoffRequests[payoffRequestIndex] = payoffRequest;
        } else if (payoffRequestIndex !== -1) {
          // Remove payoff request
          updatedPayoffRequests.splice(payoffRequestIndex, 1);
        }

        return {
          ...prev,
          payoffDashboardDeals: {
            ...prev.payoffDashboardDeals,
            payoffRequests: updatedPayoffRequests,
          },
        };
      },
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    // We do need the initial data to set up the subscription.
    // Our subscription variables depend on the query result.
    if (!dealIds.length || !sources.length) {
      return undefined;
    }

    // Subscribe only to the deal updates that are in the current table.
    const unsubscribe = subscribeToMore({
      document: OnDealUpdateDocument,
      variables: { sources, dealIds },
      updateQuery: (prev, { subscriptionData }) => {
        // Cast `subscriptionData.data` to the correct type.
        // `subscribeToMore` returns a `PayoffDashboardDealsQuery` type because expects the same return type as the query.
        const { onDealUpdate: updatedDeals } = subscriptionData.data as OnDealUpdateSubscription;
        if (!updatedDeals?.length) {
          return prev;
        }

        const existingPayoffRequests = prev.payoffDashboardDeals?.payoffRequests ?? [];
        const updatedPayoffRequests = [...existingPayoffRequests];

        updatedDeals.forEach((updatedDeal) => {
          const payoffRequestIndex = updatedPayoffRequests.findIndex(
            (pr) => pr?.deal?.id && pr.deal.id === updatedDeal?.id,
          );
          if (payoffRequestIndex === -1) {
            return;
          }

          // Only update the notifications.
          updatedPayoffRequests[payoffRequestIndex] = {
            ...updatedPayoffRequests[payoffRequestIndex],
            deal: {
              ...updatedPayoffRequests[payoffRequestIndex]?.deal,
              notifications: updatedDeal?.notifications ?? [],
            },
          };
        });

        return {
          ...prev,
          payoffDashboardDeals: {
            ...prev.payoffDashboardDeals,
            payoffRequests: updatedPayoffRequests,
          },
        };
      },
    });

    return () => unsubscribe();
  }, [subscribeToMore, dealIds, sources]);

  const sortedPayoffRequests = useMemo(() => {
    // TODO: temporary conversion. We should remove all classes and use the generated classes
    const currentPayoffRequests = payoffRequests as PayoffRequest[];

    if (!currentPayoffRequests || currentPayoffRequests.length === 0) {
      return currentPayoffRequests;
    }

    const parsedPayoffRequests = parsePayoffRequestsFromDashboard(currentPayoffRequests);
    const filteredPayoffRequests = filterPayoffRequests(parsedPayoffRequests, payoffFilters);

    return sortPayoffRequests(filteredPayoffRequests, field, direction);
  }, [payoffRequests, sorting, payoffFilters]);

  const handleSorting = (sortingFieldName: string) => {
    // This will handle the attempt of sorting by the `actions` column
    const validSortingFieldName = sortingFieldName || INITIAL_SORTING_STATE.field;

    if (sorting.field !== validSortingFieldName) {
      dispatchSorting({
        type: SortingActionKind.SET_FIELD,
        payload: validSortingFieldName,
      });

      return;
    }

    dispatchSorting({
      type: SortingActionKind.SET_DIRECTION,
      payload: isSortingAsc(sorting.direction) ? SortDirection.Asc : SortDirection.Desc,
    });
  };

  const calculateLeftPx = (name: string) => {
    return `${(document.getElementsByClassName(name)[0] as HTMLElement)?.offsetWidth ?? 100}px`;
  };

  const generateRow = (payoffRequest: PayoffRequest) => (
    <DealRowContainer key={payoffRequest.id} payoffRequest={payoffRequest} />
  );

  const addSortProps = (column: string, isDisabled?: boolean) => {
    if (isDisabled) {
      return {};
    }

    return {
      onClick: () => handleSorting(column),
      cursor: 'pointer',
    };
  };

  return (
    <>
      <Loader isLoading={loading} />
      <TableFilters filters={payoffFilters} dispatch={dispatchFilters} />
      <Box width="100%" overflowX="scroll">
        <Table>
          <Thead bgColor="silverLakeBlue">
            <Tr>
              {Object.entries(payoffTableColumns).map(
                ([header, { sortingFieldName, isDisabled }]) => (
                  <Th
                    position="relative"
                    color="white"
                    key={`${header}-key`}
                    px={6}
                    whiteSpace="nowrap"
                  >
                    <Flex
                      {...addSortProps(sortingFieldName, isDisabled)}
                      alignItems="center"
                      position="relative"
                    >
                      <span className={`${header}-title`}>{header}</span>
                      {sortingFieldName === sorting.field && (
                        <Icon
                          position="absolute"
                          left={calculateLeftPx(`${header}-title`)}
                          boxSize={5}
                          as={isSortingAsc(sorting.direction) ? BiChevronUp : BiChevronDown}
                        />
                      )}
                    </Flex>
                  </Th>
                ),
              )}
            </Tr>
          </Thead>
          <Tbody>{sortedPayoffRequests?.map(generateRow)}</Tbody>
        </Table>
      </Box>
    </>
  );
};

export default PayoffDealsTable;
