import { object } from 'yup';

import { stateValidation, zipValidationOptional } from './address';
import { emailValidationOptional } from './email';
import { phoneNumberValidationOptional } from './phoneNumber';
import { optionalSsnValidation } from './ssn';
import { optionalStringValidation } from './strings';

const originalLessee = 'Original Lessee ';

// // Uncomment to use when making original lessee required
// export const originalLesseeRequiredValidation = object().shape({
//   ...personNameValidation(originalLessee),
//   ...personAddressValidation(originalLessee),
//   phone_number: phoneNumberValidationRequired(originalLessee),
//   email: emailValidationRequired(originalLessee),
//   ssn: fullSsnValidation(originalLessee),
// });

// Used so the rep doesn't have to fill out all of the original lessee fields to submit a credit application
export const originalLesseeOptionalValidation = object().shape({
  first_name: optionalStringValidation,
  last_name: optionalStringValidation,
  address_line: optionalStringValidation,
  address_line2: optionalStringValidation,
  zip: zipValidationOptional(originalLessee),
  city: optionalStringValidation,
  state: stateValidation(originalLessee, false),
  county: optionalStringValidation,
  phone_number: phoneNumberValidationOptional(originalLessee),
  email: emailValidationOptional(originalLessee),
  ssn: optionalSsnValidation,
});
