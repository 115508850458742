import { BootReasonsEnum, DealTypeLabelMap } from '../gql/dealGql';
import { DealType, LocationType } from '../gql/generated/graphql';
import { FailPayoffRequestReasonEnum } from '../gql/payoffRequestGql';

import { Option } from '../components/shared/types';
import { statesArray } from '../libs/states';

const enumToSelectOptions = <T>(enumObj: { [s: string]: T }) =>
  Object.values(enumObj).map((e) => ({ value: e, label: e }));

const BOOT_REASONS = enumToSelectOptions(BootReasonsEnum);
const FAIL_PAYOFF_REQUEST_REASONS = enumToSelectOptions(FailPayoffRequestReasonEnum);

const US_STATES = statesArray.map((stateName) => ({
  label: stateName,
  value: stateName,
}));

const DEAL_TYPES: Option[] = Object.values(DealType)
  .map((value) => ({
    label: DealTypeLabelMap[value],
    value,
  }))
  .filter(({ value }) => value !== DealType.Acquisition);

export const LocationLabelMap: Record<LocationType, string> = {
  [LocationType.Idaho]: 'Idaho',
  [LocationType.Utah]: 'Utah',
};

const LOCATIONS: Option[] = Object.values(LocationType).map((value) => ({
  label: LocationLabelMap[value],
  value,
}));

const SELECT_OPTIONS = {
  BOOT_REASONS,
  US_STATES,
  FAIL_PAYOFF_REQUEST_REASONS,
  DEAL_TYPES,
  LOCATIONS,
};

export default SELECT_OPTIONS;
