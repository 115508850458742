import { useState } from 'react';

import { Box, BoxProps, ResponsiveValue, SystemStyleObject, Text } from '@chakra-ui/react';

import colors from '../../chakra/foundations/colors';
import { getTimestamp } from '../../utils/dates';

const getMessageTail = (isFromCustomer: boolean, color: ResponsiveValue<string> | undefined) =>
  ({
    content: '""',
    position: 'absolute',
    bottom: '-12px',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 0 12px 12px',
    [isFromCustomer ? 'borderTopRightRadius' : 'borderBottomLeftRadius']: '5px',
    [isFromCustomer ? 'left' : 'right']: '10px',
    borderColor: `transparent transparent ${color} transparent`,
    transform: isFromCustomer ? 'rotate(180deg)' : 'rotate(-90deg)',
  } as SystemStyleObject);

type TextBubbleWithTimestampProps = {
  timestamp: string | Date;
  message: string;
  author?: string;
  isFromCustomer: boolean;
  isLastInGroup: boolean;
};

const TextBubbleWithTimestamp = ({
  timestamp,
  message,
  author,
  isFromCustomer,
  isLastInGroup = false,
}: TextBubbleWithTimestampProps) => {
  const [showDate, setShowDate] = useState(false);

  const messageStyle: ResponsiveValue<BoxProps> = {
    // colors needs to be imported explicitly for the _after prop
    bgColor: isFromCustomer ? colors.gray[200] : colors.azureishWhite,
    borderRadius: '10px',
    wordBreak: 'break-word',
    justifyContent: isFromCustomer ? 'left' : 'right',
    padding: 2,
  };

  return (
    <Box>
      {!isFromCustomer && author && (
        <Text fontSize="xs" color="gray.500" justifySelf={messageStyle.justifyContent} pr={1}>
          {author}
        </Text>
      )}
      <Box
        pl={isFromCustomer ? 0 : 8}
        pr={isFromCustomer ? 8 : 0}
        display="flex"
        flexDirection="column"
        alignItems={isFromCustomer ? 'flex-start' : 'flex-end'}
        justifySelf={messageStyle.justifyContent}
      >
        <Box
          {...messageStyle}
          position="relative"
          onClick={() => setShowDate((prev) => !prev)}
          _before={isLastInGroup ? getMessageTail(isFromCustomer, messageStyle.bgColor) : {}}
        >
          {message.split('\n').map((line) => (
            <Text>{line}</Text>
          ))}
        </Box>
        {showDate && (
          <Text
            fontSize="xs"
            color="gray.500"
            alignSelf={messageStyle.justifyContent}
            pl={isFromCustomer && isLastInGroup ? 6 : 0}
            pr={!isFromCustomer && !isLastInGroup ? 0 : 6}
          >
            {getTimestamp(timestamp)}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default TextBubbleWithTimestamp;
