/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';

import {
  Box,
  Flex,
  HStack,
  ListItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';

import { PrequalificationCreditReport } from '../../../../gql/generated/graphql';

import ExperianLogo from './ExperianLogo';
import { creditScoreColorMap } from './PrequalChecksRow';
import TradelinesTable from './TradelinesTable';

import { formatMoney } from '../../../../libs/utils';
import {
  PrequalCheckStatusType,
  formatExperianDate,
  getTradelineSummary,
} from '../../../../utils/prequalification';

type CreditReportProps = {
  creditReport?: PrequalificationCreditReport | null;
  creditScore?: number;
  creditStatus?: PrequalCheckStatusType;
};

const CreditReport = ({
  creditReport,
  creditScore,
  creditStatus = 'warning',
}: CreditReportProps) => {
  const revolvingSummary = useMemo(
    () => getTradelineSummary(creditReport?.tradelines?.revolving || []),
    [creditReport?.tradelines?.revolving],
  );

  const installmentSummary = useMemo(
    () => getTradelineSummary(creditReport?.tradelines?.installment || []),
    [creditReport?.tradelines?.installment],
  );

  const totalSummary = useMemo(
    () => ({
      highCredit: revolvingSummary.highCredit + installmentSummary.highCredit,
      creditLimit: revolvingSummary.creditLimit + installmentSummary.creditLimit,
      balance: revolvingSummary.balance + installmentSummary.balance,
      pastDue: revolvingSummary.pastDue + installmentSummary.pastDue,
      monthlyPayment: revolvingSummary.monthlyPayment + installmentSummary.monthlyPayment,
    }),
    [revolvingSummary, installmentSummary],
  );

  const availableCreditPercentage = (
    100 -
    (revolvingSummary.balance / revolvingSummary.creditLimit) * 100
  ).toFixed(0);

  return (
    <Box>
      <Box px={10}>
        <ExperianLogo />
        <Flex w="full" justify="space-between" gap={10} py={5}>
          <Text fontWeight="bold" fontSize="3xl">
            {creditReport?.names?.customerName?.firstName}{' '}
            {creditReport?.names?.customerName?.surname}
          </Text>
          <HStack spacing={2} alignItems="center">
            <Text fontWeight="semibold">Credit Score:</Text>
            <Text fontSize="2xl" color={creditScoreColorMap[creditStatus]}>
              {creditScore}
            </Text>
          </HStack>
        </Flex>

        <Stack>
          {/* Aliases */}
          <Stack spacing={0}>
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Aliases
            </Text>
            <VStack spacing={1}>
              {creditReport?.names?.aliases.map((alias) => (
                <Text key={`${alias?.firstName} ${alias?.middleName} ${alias?.surname}`}>
                  {alias?.surname ? `${alias.surname}, ` : ''}
                  {alias?.firstName ? `${alias.firstName} ` : ''} {alias?.middleName}
                </Text>
              ))}
            </VStack>
          </Stack>

          {/* Address */}
          <Stack pt={4}>
            <Flex w="full" justify="space-between">
              <Text decoration="underline" fontSize="xl" fontWeight="bold">
                Addresses
              </Text>
              <Text decoration="underline" fontSize="xl" fontWeight="bold">
                Date Reported
              </Text>
            </Flex>

            <VStack w="full" spacing={1} alignItems="flex-start">
              {creditReport?.addresses?.map((address) => (
                <HStack
                  key={`${address?.streetName}-${address?.firstReportedDate}`}
                  w="full"
                  justifyContent="space-between"
                >
                  <Text>
                    {address?.streetPrefix ? `${address.streetPrefix}` : ''}
                    {address?.streetName
                      ? `${address?.streetPrefix ? ' ' : ''}${address.streetName}`
                      : ''}
                    {address?.streetSuffix
                      ? `${address?.streetName ? ' ' : ''}${address.streetSuffix}`
                      : ''}
                    {address?.city ? `, ${address.city}` : ''}
                    {address?.state ? `, ${address.state}` : ''}
                    {address?.zipCode ? ` ${address.zipCode}` : ''}
                  </Text>
                  <Text>{formatExperianDate(address?.firstReportedDate)}</Text>
                </HStack>
              ))}
            </VStack>
          </Stack>

          {/* Employment */}
          <Stack pt={4} spacing={1}>
            <Flex w="full" justify="space-between">
              <Text decoration="underline" fontSize="xl" fontWeight="bold">
                Employment
              </Text>
              <Text decoration="underline" fontSize="xl" fontWeight="bold">
                Date Last Reported
              </Text>
            </Flex>
            <VStack w="full" spacing={1} alignItems="flex-start">
              {creditReport?.employments?.map((employment) => (
                <HStack
                  key={`${employment?.name}-${employment?.lastUpdatedDate}`}
                  w="full"
                  justifyContent="space-between"
                >
                  <Text>{employment?.name}</Text>
                  <Text>{formatExperianDate(employment?.lastUpdatedDate)}</Text>
                </HStack>
              ))}
            </VStack>
          </Stack>

          {/* Score Factors */}
          <Stack pt={4} spacing={0} alignItems="flex-start">
            <Flex w="full" justify="space-between">
              <Text decoration="underline" fontSize="xl" fontWeight="bold">
                Score Factors{' '}
              </Text>
              <Text>(ordered by most impactful)</Text>
            </Flex>
            <UnorderedList spacing={1} pl={6}>
              {creditReport?.scoreFactors?.map((factor) => (
                <ListItem key={factor?.code}>{factor?.description}</ListItem>
              ))}
            </UnorderedList>
          </Stack>

          {/* Messages */}
          <Stack pt={4} spacing={1} alignItems="flex-start">
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Extra Messages
            </Text>
            <UnorderedList spacing={1} pl={6}>
              {creditReport?.informationalMessages?.map((message) => (
                <ListItem key={message}>{message}</ListItem>
              ))}
            </UnorderedList>
          </Stack>

          {/* Summary */}
          <Stack pt={4} align="center">
            <Text decoration="underline" fontSize="xl" fontWeight="bold">
              Summary
            </Text>
            <VStack spacing={1}>
              <TableContainer w="full">
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>TYPE</Th>
                      <Th>HIGH BALANCE</Th>
                      <Th>CRED LIM</Th>
                      <Th>BALANCE</Th>
                      <Th>PAST DUE</Th>
                      <Th>MONTHLY PAY</Th>
                      <Th>AVAILABLE</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td fontWeight="bold">REVOLVING</Td>
                      <Td>{formatMoney(revolvingSummary.highCredit, { noDecimal: true })}</Td>
                      <Td>{formatMoney(revolvingSummary.creditLimit, { noDecimal: true })}</Td>
                      <Td>{formatMoney(revolvingSummary.balance, { noDecimal: true })}</Td>
                      <Td>{formatMoney(revolvingSummary.pastDue, { noDecimal: true })}</Td>
                      <Td>{formatMoney(revolvingSummary.monthlyPayment, { noDecimal: true })}</Td>
                      <Td>{availableCreditPercentage}%</Td>
                    </Tr>
                    <Tr>
                      <Td fontWeight="bold">INSTALLMENT</Td>
                      <Td>{formatMoney(installmentSummary.highCredit, { noDecimal: true })}</Td>
                      <Td>{formatMoney(installmentSummary.creditLimit, { noDecimal: true })}</Td>
                      <Td>{formatMoney(installmentSummary.balance, { noDecimal: true })}</Td>
                      <Td>{formatMoney(installmentSummary.pastDue, { noDecimal: true })}</Td>
                      <Td>{formatMoney(installmentSummary.monthlyPayment, { noDecimal: true })}</Td>
                    </Tr>
                    <Tr>
                      <Td fontWeight="bold">TOTAL</Td>
                      <Td>{formatMoney(totalSummary.highCredit, { noDecimal: true })}</Td>
                      <Td>{formatMoney(totalSummary.creditLimit, { noDecimal: true })}</Td>
                      <Td>{formatMoney(totalSummary.balance, { noDecimal: true })}</Td>
                      <Td>{formatMoney(totalSummary.pastDue, { noDecimal: true })}</Td>
                      <Td>{formatMoney(totalSummary.monthlyPayment, { noDecimal: true })}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          </Stack>
        </Stack>
      </Box>
      {/* Tradelines */}
      <Stack mt={8} gap={6} w="full" alignItems="center">
        {(creditReport?.tradelines?.revolving || []).length > 0 && (
          <TradelinesTable title="Revolving" tradelines={creditReport!.tradelines!.revolving} />
        )}
        {(creditReport?.tradelines?.installment || []).length > 0 && (
          <TradelinesTable title="Installment" tradelines={creditReport!.tradelines!.installment} />
        )}
        {(creditReport?.tradelines?.unknown || []).length > 0 && (
          <TradelinesTable title="Unknown" tradelines={creditReport!.tradelines!.unknown} />
        )}
      </Stack>
    </Box>
  );
};

export default CreditReport;
