import { gql } from '@apollo/client/core';

import { PayoffVerificationStatus } from './generated/graphql';

export class Payoff {
  id?: number;
  lienholder_name: string;
  lienholder_slug: string;
  lender_name?: string;
  account_number: string;
  vehicle_payoff?: number;
  good_through_date?: string;
  lease_term?: number;
  remaining_payments?: number;
  msrp?: number;
  residual_percentage?: number;
  sales_price?: number;
  cap_reduction?: number;
  money_down?: number;
  money_factor?: number;
  termination_fees?: number;
  cap_cost?: number;
  residual_amount?: number;
  estimated_payoff?: number;
  old_lease_payment?: number;
  sales_tax_from_payoff?: number;
  payoff_includes_sales_tax?: boolean;
  sales_tax_from_payoff_entered_manually?: boolean;
  verification_status?: PayoffVerificationStatus;
  next_payment_date?: string;
  user_entered_total_payoff?: number;

  maturity_date?: string;

  doubleTaxApplied?: boolean;
  previousTotalPayoff?: number;
  totalPayoff?: number;

  constructor() {
    this.lienholder_name = '';
    this.lienholder_slug = '';
    this.lender_name = '';
    this.account_number = '';
  }
}

export const PayoffFragment = gql`
  fragment PayoffFragment on payoff {
    id
    lienholder_name
    lienholder_slug
    lender_name
    account_number
    vehicle_payoff
    sales_tax_from_payoff
    good_through_date
    lease_term
    remaining_payments
    msrp
    residual_percentage
    sales_price
    cap_reduction
    money_down
    money_factor
    termination_fees
    cap_cost
    residual_amount
    estimated_payoff
    old_lease_payment
    payoff_includes_sales_tax
    sales_tax_from_payoff_entered_manually
    verification_status
    next_payment_date
    user_entered_total_payoff
    maturity_date
  }
`;

export const payoffUpdate = gql`
  mutation payoffUpdate($payoff: payoffInput!) {
    payoffUpdate(payoffInfo: $payoff) {
      ...PayoffFragment
    }
  }
  ${PayoffFragment}
`;
